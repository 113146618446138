import {getFormattedDate, getPriceInFormatted} from "../../../../utils/Common";
import {Box, Typography} from "@mui/material";
import React from "react";

export const pageConfig = {
    eventDetails: [
        {
            field: "createdAt",
            headerName: "Created On",
            headerClassName: "data-grid-header",
            sortable: false,
            flex: 1,
            valueGetter: (params: any) => getFormattedDate(params.row?.createdAt),
        },
        {
            field: "startDate",
            headerName: "Start Date",
            headerClassName: "data-grid-header",
            flex: 1,
            valueGetter: (params: any) => getFormattedDate(params.row?.startDate),
        },
        {
            field: "endDate",
            headerName: "End Date",
            headerClassName: "data-grid-header",
            flex: 1,
            valueGetter: (params: any) => getFormattedDate(params.row?.endDate),
        },
        {
            field: "totalVendors",
            headerName: "Total Vendors",
            headerClassName: "data-grid-header",
            flex: 1,
        },
        {
            field: "totalResponse",
            headerName: "Response Received",
            headerClassName: "data-grid-header",
            flex: 1
        },
        {
            field: "status",
            headerName: "Total Vendors",
            headerClassName: "data-grid-header",
            flex: 1,
        }
    ],
    packageDetails: [
        {
            field: 'id',
            headerName: 'Sr. No.',
            sortable: false,
            maxWidth: 60,
            filterable: false,
            renderCell: (params: any) =>
                params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1
        },
        {
            field: "itemCategory",
            headerName: "Item Category",
            headerClassName: "data-grid-header",
            sortable: false,
            flex: 1,
            maxWidth: 150,
            valueGetter: (params: any) => params.row?.itemDetail?.itemCategory?.name + "-" + params.row?.itemDetail?.item?.name
        },
        {
            field: "selectedItem",
            headerName: "Selected Sku",
            headerClassName: "data-grid-header",
            flex: 1,
            sortable: false,
            maxWidth: 180,
            renderCell: (params: any) => (
                <Box className={"space-between vertically-center-aligned"}>
                    {params.row?.itemDetail?.sku?.primaryImage != null && (
                        <img className={"sku-list-image"} src={params.row?.itemDetail?.sku?.primaryImage} alt={params.row?.itemDetail?.sku?.name}/>
                    )}
                    <a
                        style={{whiteSpace: 'normal'}}
                        href={`/catalogue/skus/details/${params.row?.itemDetail?.sku?.id}`}
                        rel="noreferrer"
                        target={"_blank"}
                    >
                        {params.row?.itemDetail?.sku.name}
                    </a>
                </Box>
            )
        },
        {
            field: "options",
            headerName: "Specifications",
            headerClassName: "data-grid-header",
            flex: 1,
            sortable: false,
            maxWidth: 150,
            renderCell: (params: any) => (
                <Box sx={{whiteSpace: 'normal', overflow:'auto', height:'100%'}}>
                    {params.row?.itemDetail?.sku.options && params.row?.itemDetail?.sku.options.length > 0 ? (
                        params.row?.itemDetail?.sku.options.map((option: any, idx: number) => (
                            <Typography key={idx}
                                        variant={"body2"}
                                        sx={{
                                            fontSize: "0.8rem",
                                            padding: '0.4rem'
                                        }}>
                                <strong>{option.name}:</strong> {option.value}
                            </Typography>
                        ))
                    ) : (
                        <Typography
                            sx={{fontSize: "0.8rem", padding: '0.4rem'}}>
                            NA
                        </Typography>
                    )}
                </Box>
            )
        },
        {
            field: "uom",
            headerName: "UOM",
            headerClassName: "data-grid-header",
            sortable: false,
            flex: 1,
            maxWidth: 100,
            valueGetter: (params: any) => params.row?.itemDetail?.sku?.uom,
        },
        {
            field: "totalQuantity",
            headerName: "Quantity",
            headerClassName: "data-grid-header",
            sortable: false,
            flex: 1,
            maxWidth: 100,
        },
        {
            field: "ratePerUom",
            headerName: "GSV",
            headerClassName: "data-grid-header",
            sortable: false,
            maxWidth: 120,
            flex: 1,
            valueGetter: (params: any) => getPriceInFormatted(params.row?.gsv, params.row.country),
        },
        {
            field: "targetPrice",
            // headerName: "Target Price",
            headerName: "Planned COGS",
            headerClassName: "data-grid-header",
            sortable: false,
            flex: 1,
            maxWidth: 120,
            valueGetter: (params: any) => getPriceInFormatted(params.row?.cogsPrice, params.row.country)
        },
        {
            field: "targetTotal",
            headerName: "Target Total",
            headerClassName: "data-grid-header",
            sortable: false,
            flex: 1,
            maxWidth: 150,
            valueGetter: (params: any) => getPriceInFormatted(params.row?.totalQuantity * params.row?.cogsPrice, params.row.country)
        },
        {
            field: "action",
            headerName: "Action",
            headerClassName: "data-grid-header",
            flex: 1,
            show: 'button',
            sortable: false,
            buttons: [
                {
                    type: 'revoke',
                    title: 'Revoke',
                    variant:'outlined',
                    permissions: ['edit-event'],
                    condition: (row: any) => {return row.packageStatus == 'EVENT'}
                },
                {
                    type: 'select-rc',
                    title: 'Select RC Price',
                    permissions: ['edit-event'],
                    condition: (row: any) => (row?.itemDetail?.sku?.rcVendors > 0 && row.packageStatus == 'EVENT')
                },
            ]
        }
    ],
    closedEventPackageDetails: [
        {
            field: 'id',
            headerName: 'Sr. No.',
            sortable: false,
            maxWidth: 60,
            filterable: false,
            renderCell: (params: any) =>
                params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1
        },
        {
            field: "itemCategory",
            headerName: "Item Category",
            headerClassName: "data-grid-header",
            sortable: false,
            flex: 1,
            // maxWidth: 200,
            valueGetter: (params: any) => params.row?.itemDetail?.itemCategory?.name + "-" + params.row?.itemDetail?.item?.name
        },
        {
            field: "selectedItem",
            headerName: "Selected Sku",
            headerClassName: "data-grid-header",
            flex: 1,
            sortable: false,
            // maxWidth: 150,
            renderCell: (params: any) => (
                <a
                    href={`/catalogue/skus/details/${params.row?.itemDetail?.sku?.id}`}
                    rel="noreferrer"
                    target={"_blank"}
                >
                    {params.row?.itemDetail?.sku.name}
                </a>
            )
        },
        {
            field: "uom",
            headerName: "UOM",
            headerClassName: "data-grid-header",
            sortable: false,
            flex: 1,
            maxWidth: 100,
            valueGetter: (params: any) => params.row?.itemDetail?.sku?.uom,
        },
        {
            field: "totalQuantity",
            headerName: "Quantity",
            headerClassName: "data-grid-header",
            sortable: false,
            flex: 1,
            maxWidth: 150,
        },
        {
            field: "ratePerUom",
            headerName: "Rate per UOM",
            headerClassName: "data-grid-header",
            sortable: false,
            // maxWidth: 150,
            flex: 1,
            valueGetter: (params: any) => getPriceInFormatted(params.row?.gsv, params.row.country),
        },
        {
            field: "targetPrice",
            // headerName: "Target Price",
            headerName: "Planned COGS",
            headerClassName: "data-grid-header",
            sortable: false,
            flex: 1,
            // maxWidth: 150,
            valueGetter: (params: any) => getPriceInFormatted(params.row?.cogsPrice, params.row.country)
        },
        {
            field: "targetTotal",
            headerName: "Target Total",
            headerClassName: "data-grid-header",
            sortable: false,
            flex: 1,
            // maxWidth: 150,
            valueGetter: (params: any) => getPriceInFormatted(params.row?.totalQuantity * params.row?.cogsPrice, params.row.country)
        },
    ]
};

import FormTabMain from "./FormTabMain";
import ParameterFormTab from "./ParameterFormTab";

const InfoTech = (props: any) => {
    const {formState, setFormState } = props;
    const handleNextButton = () => {
        props.handleNext(props.categoryName)
    }

    let buttons :any = [
        {
            type: 'submit',
            color: 'primary.main',
            variant: 'contained',
            title: 'Save',
            permissions: ['edit-projects', "edit-estimation"],
        },
    ];



    return (
        <ParameterFormTab project={props.project} formState={formState} setFormState={setFormState} formFields={formFields} categoryName={props.categoryName} allParameters={props.allParameters} buttons={buttons} handleNextButton={handleNextButton} updateFieldChange={props.updateFieldChange} buttonRef={props.buttonRef}/>
    );
};

const formFields = [
    {
        horizontalLabel: true,
        groupName: 'IT',
        fields: [
            {
                element: 'select',
                label: 'IT Node details',
                name: 'itNodeDetails',
                required: true,
                width: 4,
                value: 'CAT-6A 4 Node',
                options: [
                    {id: 'CAT-6 1 Node', label: 'CAT-6 1 Node'},
                    {id: 'CAT-6 2 Node', label: 'CAT-6 2 Node'},
                    {id: 'CAT-6 3 Node', label: 'CAT-6 3 Node'},
                    {id: 'CAT-6 4 Node', label: 'CAT-6 4 Node'},
                    {id: 'CAT-6A 1 Node', label: 'CAT-6A 1 Node'},
                    {id: 'CAT-6A 2 Node', label: 'CAT-6A 2 Node'},
                    {id: 'CAT-6A 3 Node', label: 'CAT-6A 3 Node'},
                    {id: 'CAT-6A 4 Node', label: 'CAT-6A 4 Node'},
                    {id: 'NONE', label: 'NONE'},
                ],
            },
            {
                element: 'select',
                label: 'Node % requirement',
                name: 'nodeRequirement',
                required: true,
                mainContainerWidth: 12,
                width: 4,
                value: '150% (Every W/S 100% + Alternate W/S 50%)',
                options: [
                    {
                        id: '150% (Every W/S 100% + Alternate W/S 50%)',
                        label: '150% (Every W/S 100% + Alternate W/S 50%)',
                    },
                    {id: '100% (Every W/S)', label: '100% (Every W/S)'},
                    {id: '050% (Every 2 W/S)', label: '050% (Every 2 W/S)'},
                    {id: '075% (Every 3 W/S)', label: '075% (Every 3 W/S)'},
                    {id: '025% (Every 4 W/S)', label: '025% (Every 4 W/S)'},
                ],
            },
            {
                element: 'select',
                label: 'Backbone cabling- Fiber',
                name: 'backboneCablingFiber',
                required: true,
                width: 4,
                mainContainerWidth: 12,
                value: 'SM 12C',
                options: [
                    {id: 'OM3 6C', label: 'OM3 6C'},
                    {id: 'OM3 12C', label: 'OM3 12C'},
                    {id: 'OM3 24C', label: 'OM3 24C'},
                    {id: 'OM3 48C', label: 'OM3 48C'},
                    {id: 'OM4 6C', label: 'OM4 6C'},
                    {id: 'OM4 12C', label: 'OM4 12C'},
                    {id: 'OM4 24C', label: 'OM4 24C'},
                    {id: 'OM4 48C', label: 'OM4 48C'},
                    {id: 'SM 6C', label: 'SM 6C'},
                    {id: 'SM 12C', label: 'SM 12C'},
                    {id: 'SM 48C', label: 'SM 48C'},
                    {id: 'SM 24C', label: 'SM 24C'},
                    {id: 'NONE', label: 'NONE'},
                ],
            },
            {
                element: 'select',
                label: 'PDU',
                name: 'pdu',
                required: true,
                width: 4,
                mainContainerWidth: 12,
                value: 'Indian 3pin',
                options: [
                    {id: 'OM3 6C', label: 'OM3 6C',},
                    {id: 'IEC', label: 'IEC'},
                    {id: 'Indian 3pin', label: 'Indian 3pin'}
                ],
            },
        ],
    },
];

export default InfoTech;

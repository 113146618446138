import {useParams} from "react-router-dom";
import {ChangeEvent, useEffect, useState} from "react";
import {api} from "../../../../utils/Axios";
import Loader from "../../../../components/Loader";
import {Box, Button, Grid, Typography} from "@mui/material";
import PageHeading from "../../../../components/page-heading/PageHeading";
import SkuOptionsTable from "../../../../components/skus/SkuOptionsTable";
import SkuPricesTable from "../../../../components/skus/SkuPricesTable";
import {hasAccess} from "../../../../utils/Access";
import ReadMore from "../../../../components/read-more/ReadMore";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {getPriceInFormatted} from "../../../../utils/Common";
import CogsForm from "./CogsForm";

const styles = {
    label: {
        fontSize: "14px", fontWeight: 600, p: 0.5
    },
    value: {
        fontSize: "14px", p: 0.5, textAlign: "start"
    },
    valueWithCopy: {
        fontSize: "14px", p: 0.5, textAlign: "start", display: 'flex', alignItems: 'center'
    }
}

const SkuDetails = () => {
    let {id} = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState<any>();
    const [primaryImage, setPrimaryImage] = useState("");
    const [open, setOpen] = useState(false);

    const handlePriceChange = () => {
        getSku();
    }

    const handleRemoveImage = () => {
        api.get(`/catalogue/sku/remove-primary-image/${id}`
        ).then((response) => {
            return response.data;
        }).then((response: any) => {
            setIsLoading(false);
            window.location.reload();
        }).catch(ex => {
            console.log(ex);
            setIsLoading(false);
        });
    }

    const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            setIsLoading(true);
            api.put(
                `/catalogue/sku/upload-images/${id}`,
                {
                    'files': event.target.files
                },
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    }
                }
            ).then((response) => {
                return response.data;
            }).then((response: any) => {
                setIsLoading(false);
                window.location.reload();
            }).catch(ex => {
                console.log(ex);
                setIsLoading(false);
            });
        }
    }

    const getSku = () => {
        setIsLoading(true);
        api.get(`/catalogue/sku/details/${id}`).then((response) => {
            return response.data;
        }).then((response: any) => {
            setData(response);
            if (response?.skuImages.length > 0) {
                setPrimaryImage(response.skuImages[0]?.url);
            }
            setIsLoading(false);
        }).catch(ex => {
            console.log(ex);
            setIsLoading(false);
        });
    }

    useEffect(() => {
        getSku();
    }, [id]);


    const handleOpenCogsModal = () => {
        setOpen(true);
    };
    const handleCloseCogsModal = (data: string) => {
        setOpen(false);
        if (data == 'save') {
            window.location.reload();
        }
    };

    if (isLoading) {
        return <Loader/>
    }
    return (
        <Box className={"common-space-container"}>
            <Box>
                <PageHeading
                    title={"SKU Detail: " + data?.name}
                    showButtons={true}
                    enableBackButton={true}
                    buttons={[{id: "cogs", label: "Update COGS"}]}
                    handleHeaderButtonClick={handleOpenCogsModal}
                />
            </Box>
            <Box className={"loto-family"}>
                <Grid container spacing={1}>
                    <Grid item xs={3}>
                        <Grid container>
                            <Grid item xs={6}>
                                <Typography sx={styles.label}>Material Category</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography sx={styles.value}>{data?.category.name}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography sx={styles.label}>Material Category Code</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    sx={styles.valueWithCopy}>
                                    {data?.category.code} &nbsp;<ContentCopyIcon
                                    fontSize={"inherit"}
                                    sx={{cursor: 'pointer'}}
                                    onClick={() => {
                                        navigator.clipboard.writeText(data?.category.code)
                                    }}
                                />
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography sx={styles.label}>
                                    Material Sub-Category
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography sx={styles.value}>
                                    {data?.subCategory.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography sx={styles.label}>
                                    Material Sub-Category Code
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    sx={styles.valueWithCopy}
                                >
                                    {data?.subCategory.code} &nbsp;
                                    <ContentCopyIcon
                                        fontSize={"inherit"}
                                        sx={{cursor: "pointer"}}
                                        onClick={() => {
                                            navigator.clipboard.writeText(data?.subCategory.code);
                                        }}
                                    />
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography sx={styles.label}>Item Category</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography sx={styles.value}>{data?.itemCategory.name}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography sx={styles.label}>Item Category Code</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    sx={styles.valueWithCopy}>
                                    {data?.itemCategory.code} &nbsp;<ContentCopyIcon
                                    fontSize={"inherit"}
                                    sx={{cursor: 'pointer'}}
                                    onClick={() => {
                                        navigator.clipboard.writeText(data?.itemCategory.code)
                                    }}
                                />
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography sx={styles.label}>Item Name</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography sx={styles.value}>{data?.item.name}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography sx={styles.label}>Item Code</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    sx={styles.valueWithCopy}>
                                    {data?.item.code} &nbsp;<ContentCopyIcon
                                    fontSize={"inherit"}
                                    sx={{cursor: 'pointer'}}
                                    onClick={() => {
                                        navigator.clipboard.writeText(data?.item.code)
                                    }}
                                />
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={3}>
                        <Grid container>
                            <Grid item xs={3}>
                                <Typography sx={styles.label}>Sku Name</Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography sx={styles.value}>{data?.name}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography sx={styles.label}>Sku Code</Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography
                                    sx={styles.valueWithCopy}>
                                    {data?.code} &nbsp;<ContentCopyIcon
                                    fontSize={"inherit"}
                                    sx={{cursor: 'pointer'}}
                                    onClick={() => {
                                        navigator.clipboard.writeText(data?.code)
                                    }}
                                />
                                </Typography>
                            </Grid>
                            {hasAccess(['super-admin']) &&
                                <>
                                    <Grid item xs={3}>
                                        <Typography sx={styles.label}>COGS</Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography sx={styles.value}>
                                            {getPriceInFormatted(data?.cogsPrice || 0, "")}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography sx={styles.label}>Basic Price</Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography sx={styles.value}>
                                            {getPriceInFormatted(data?.basicPrice || 0, "")}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography sx={styles.label}>NSV %</Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography sx={styles.value}>
                                            {data?.nsv > 0 ? data.nsv.toFixed(2) : 0}%
                                        </Typography>
                                    </Grid>
                                </>
                            }
                            <Grid item xs={3}>
                                <Typography sx={styles.label}>GSV</Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography sx={styles.value}>
                                    {getPriceInFormatted(data?.gsvPrice || 0, "")}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography sx={styles.label}>UOM</Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography sx={styles.value}>{data?.uom}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography sx={styles.label}>Make</Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography sx={styles.value}>{data?.make}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography sx={styles.label}>Model</Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography sx={styles.value}>{data?.model}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography sx={styles.label}>Status</Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography sx={styles.value}>{data?.status}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={3}>
                        <Grid container>
                            <Grid item xs={4}>
                                <Typography sx={styles.label}>Description</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Box sx={styles.value}>
                                    {data && data?.description && data?.description.length > 0 &&
                                        <ReadMore text={data?.description} maxChars={200}
                                                  classNameText={"sku-desc-readmore"}/>
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={3}>
                        <Box>
                            {primaryImage != "" &&
                                <Box className={"center-aligned"}>
                                    <img src={primaryImage} className={"sku-image"} style={{width: '180px'}}
                                         alt={data.name}/>
                                </Box>
                            }
                            {hasAccess(['edit-skus']) &&
                                <Box className={"center-aligned"}>
                                    <Button variant="outlined" component="label"
                                            sx={{
                                                mt: 1,
                                            }}>
                                        <Typography sx={{fontWeight: 300, fontSize: '0.9rem'}}>Upload Image
                                            +</Typography>
                                        <input name="design_files" onChange={handleImageUpload} hidden
                                               accept="image/*" multiple type="file"/>
                                    </Button>
                                    {primaryImage != "" &&
                                        <Button variant={"text"} sx={{ml: 1, mt: 1}}
                                                onClick={handleRemoveImage}>Remove</Button>
                                    }
                                </Box>
                            }
                        </Box>
                    </Grid>
                </Grid>
                <Box sx={{pt: 5, pb: 10}}>
                    {(hasAccess(['edit-skus', 'view-skus']) && data?.skuPrices.length > 0) &&
                        <SkuPricesTable skuPrices={data?.skuPrices} handlePriceChange={handlePriceChange}/>
                    }
                    <Grid container spacing={4}>
                        {data?.skuOptions.length > 0 &&
                            <Grid item xs={6}>
                                <SkuOptionsTable skuOptions={data?.skuOptions}/>
                            </Grid>
                        }
                    </Grid>
                </Box>
            </Box>
            {open &&
                <CogsForm skuId={data.id} openPopup={open} handleCloseCogsModal={handleCloseCogsModal}/>
            }
        </Box>
    )
}
export default SkuDetails;
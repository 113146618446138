import {getStyle} from "../../../../utils/ModalUtils";
import React, {useEffect, useState} from "react";
import Loader from "../../../Loader";
import {api} from "../../../../utils/Axios";
import {Box, Button, Checkbox, Grid, Menu, MenuItem, Modal, TextField, Typography,} from "@mui/material";
import ReadMore from "../../../read-more/ReadMore";
import CloseIcon from "@mui/icons-material/Close";
import {openSnackbar} from "../../../common/features/snackbarSlice";
import {ERROR_COLOR} from "../../../../constants";
import {useAppDispatch} from "../../../../app/hooks";
import EstimationSkuDetails from "./EstimationSkuDetails";
import SelectDeliveryArea from "./list-estimate/SelectDeliveryArea";
import SearchFilter from "../../../filter/SearchFilter";
import RemoveFilters from "../../../filter/RemoveFilters";
import {getPriceInFormatted} from "../../../../utils/Common";
import {MoreVert} from "@mui/icons-material";
import SimilarSkus from "./SimilarSkus";
import {getItem} from "../../../../utils/LocalStorage";
import CustomTooltip from "../../../common/CustomTooltip";

const styles = {
    borderGrid: {
        border: 1,
        borderColor: "divider",
        p: 1,
        borderLeft: 0,
        borderTop: 0,
    }
}

const ListEstimationSkusPopup = (props: any) => {
    let betaEnabled = getItem("beta_enabled");
    const {project, item} = props;
    const style = getStyle(96, 85, 0, 0);
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const dispatch = useAppDispatch();
    const [searchQuery, setSearchQuery] = useState("");
    const [appliedFilters, setAppliedFilters] = useState({});
    const [showDeliveryAreaDetails, setShowDeliveryAreaDetails] = useState<any>({});
    const [showViewDetails, setShowViewDetails] = useState<any>({});
    const [showSimilarSkus, setShowSimilarSkus] = useState<any>({});
    const [showBoqItems, setShowBoqItems] = useState(false);
    const [selectedSkus, setSelectedSkus] = useState<any>([]);
    const [skuDetails, setSkuDetails] = useState<any>([]);
    const [skus, setSkus] = useState([]);
    const [selectedSkuIds, setSelectedSkuIds] = useState<any>([]);
    const [showDescription, setShowDescription] = useState(true);
    const [anchorEl, setAnchorEl] = useState<any>([]);

    const handleSkuMenuClose = (index: number) => {
        let copyAnchor = [...anchorEl];
        let a = copyAnchor.filter((x) => x.rowIndex == index)[0];
        let anchorIndex = copyAnchor.indexOf(a);
        a.eventTarget = null;
        copyAnchor[anchorIndex] = a;
        setAnchorEl(copyAnchor);
    }
    const handleSkuMenu = (event: React.MouseEvent<HTMLElement>, index: number) => {
        let copyAnchor: any[] = [...anchorEl];
        let a: any = copyAnchor.filter((x) => x.rowIndex == index)[0];
        if (!a) {
            a = {rowIndex: index, eventTarget: event.currentTarget};
            copyAnchor.push(a);
            setAnchorEl(copyAnchor);
        } else {
            let anchorIndex = copyAnchor.indexOf(a);
            a.eventTarget = event.currentTarget;
            copyAnchor[anchorIndex] = a;
            setAnchorEl(copyAnchor);
        }
    }
    const handleShowBoqItems = () => {
        setShowBoqItems(!showBoqItems);
    }
    const handleToggleDescription = () => {
        setShowDescription(prev => !prev);
    };

    const handelSearchFilter = (query: any) => {
        setSearchQuery(query);
    }

    const handleFilterChange = (appliedParams: any) => {
        setAppliedFilters(appliedParams);
    }

    useEffect(() => {
        if (props.openPopup == true) {
            setShowBoqItems(props.showBoqItems)
            setOpen(true);
            setSearchQuery("");
            setAppliedFilters({});
            loadSelectedSkus();
        }
    }, [props.openPopup]);

    useEffect(() => {
        loadSkus();
    }, [searchQuery, appliedFilters]);

    const handleClose = () => {
        setOpen(false);
        props.handleSkuPopupCallBack({event: "close"});
    };

    const saveSkuSelection = () => {
        setIsLoading(true);
        let errorFlag = false;
        const formSkus: any = [];
        let errorMessage = "";
        if (selectedSkus.length > 0) {
            selectedSkus.forEach((selectedSku: any) => {
                if (!errorFlag) {
                    let totalDeliveryAreaQuantity = 0;
                    // if ((selectedSku.cogsPrice == undefined || selectedSku.cogsPrice <= 0) && selectedSku.gsvPrice == 0) {
                    if (selectedSku.targetPrice == undefined || selectedSku.targetPrice <= 0) {
                        errorFlag = true;
                        errorMessage = "Please enter valid planned COGS for selected sku(s)";
                    }
                    if (selectedSku.nsvPercentage == undefined || selectedSku.nsvPercentage <= 0) {
                        errorFlag = true;
                        errorMessage = "Please enter valid nsv percentage for selected sku(s)";
                    }
                    if (selectedSku.sellingPrice == undefined || selectedSku.sellingPrice <= 0) {
                        errorFlag = true;
                        errorMessage = "Please enter valid gsv for selected sku(s)";
                    }
                    if (selectedSku.quantity == undefined || selectedSku.quantity <= 0) {
                        errorFlag = true;
                        errorMessage = "Please select quantity for selected sku(s)";
                    }

                    // if (selectedSku.deliveryAreas == undefined || selectedSku.deliveryAreas == null || selectedSku.deliveryAreas == "") {
                    //     errorFlag = true;
                    //     errorMessage = "Please select delivery area for selected sku(s)";
                    // } else {
                    //     selectedSku.deliveryAreas.map((deliveryAreaDetails: any) => {
                    //         totalDeliveryAreaQuantity += parseInt(deliveryAreaDetails.quantity);
                    //     });
                    //     if (totalDeliveryAreaQuantity != selectedSku.quantity) {
                    //         errorFlag = true;
                    //         errorMessage = "Delivery Area Quantity mismatch for selected sku(s)";
                    //     }
                    // }

                    formSkus.push({
                        id: selectedSku.id,
                        entityType: selectedSku?.selectedEntity?.entityName,
                        entityId: selectedSku?.selectedEntity?.entityId,
                        quantity: selectedSku.quantity,
                        bufferQuantity: selectedSku?.bufferQuantity ? selectedSku?.bufferQuantity : 0,
                        sellingPrice: selectedSku?.sellingPrice,
                        targetPrice: selectedSku.targetPrice,
                        deliveryAreas: selectedSku.deliveryAreas,
                        nsvPercentage: selectedSku?.nsvPercentage,
                        manuallyEdited: selectedSku.manuallyEdited
                    });
                }
            });
        } else {
            errorFlag = true;
            errorMessage = "Please select sku from list to save";
        }

        if (!errorFlag) {
            let form = {
                subCategoryId: props.subCategoryId,
                revisionId: props.revision.id,
                itemCategoryId: item.itemCategoryId,
                itemId: item.id,
                skus: formSkus,
            };
            api.post(`/procurement/estimation/update-estimation-item`, form)
                .then((response) => {
                    return response.data;
                })
                .then((res) => {
                    setIsLoading(false);
                    setOpen(false);
                    item.totalSelectedSkus = res.totalSkus;
                    item.totalQuantity = res.totalQuantity;
                    item.total = res.total;
                    props.handleSkuPopupCallBack({
                        event: "save",
                        item: item,
                    });
                })
                .catch((ex) => {
                    console.log(ex);
                    setIsLoading(false);
                });
        } else {
            setIsLoading(false);
            dispatch(
                openSnackbar({
                    message: errorMessage,
                    backgroundColor: ERROR_COLOR,
                })
            );
        }
    };

    const handleSkuSelection = (e: any, sku: any) => {
        const selected = e.target.checked;
        if (selected) {
            setSelectedSkus((prevSelectedSkus: any) => [...prevSelectedSkus, sku]);
            setSelectedSkuIds((prevSkuId: any) => [...prevSkuId, sku.id]);
        } else {
            setSelectedSkus((prevSelectedSkus: any) =>
                prevSelectedSkus.filter((selectedSku: any) => selectedSku.id !== sku.id)
            );
            setSelectedSkuIds((prevSkuId: any) =>
                prevSkuId.filter((id: any) => id !== sku.id)
            );
        }
        const newShowDeliveryAreaDetails = {...showDeliveryAreaDetails, [sku.id]: !showDeliveryAreaDetails[sku.id]};

        setShowDeliveryAreaDetails(newShowDeliveryAreaDetails);
    };

    const handleQuantitySelection = (event: any, sku: any) => {
        sku.quantity = event.target.value;
        setSelectedSkus((prevSelectedSkus: any) =>
            prevSelectedSkus.map((selectedSku: any) => {
                if (selectedSku.id === sku.id) {
                    return {...selectedSku, quantity: event.target.value, manuallyEdited: true,};
                }
                return selectedSku;
            })
        )
    };

    const handleBufferQuantitySelection = (event: any, sku: any) => {
        sku.bufferQuantity = event.target.value;
        setSelectedSkus((prevSelectedSkus: any) =>
            prevSelectedSkus.map((selectedSku: any) => {
                if (selectedSku.id === sku.id) {
                    return {...selectedSku, bufferQuantity: event.target.value, manuallyEdited: true};
                }
                return selectedSku;
            })
        )
    };

    const handleCogsPrice = (event: any, sku: any) => {
        sku.cogsPrice = parseFloat(event.target.value);
        if (sku.cogsPrice != "" && sku.cogsPrice > 0) {
            if (sku.nsv > 0) {
                let reducedPercentage = (1 - (sku.nsv / 100));
                sku.gsvPrice = (parseFloat(sku.cogsPrice) / reducedPercentage).toFixed(2);
            } else if (sku.gsvPrice > 0) {
                sku.nsv = ((parseFloat(sku.gsvPrice) - parseFloat(sku.cogsPrice)) / parseFloat(sku.gsvPrice) * 100).toFixed(2);
            }
        }
        setSelectedSkus((prevSelectedSkus: any) =>
            prevSelectedSkus.map((selectedSku: any) => {
                if (selectedSku.id === sku.id) {
                    return {
                        ...selectedSku,
                        manuallyEdited: true,
                        nsvPercentage: sku.nsv,
                        targetPrice: sku.cogsPrice,
                        sellingPrice: sku.gsvPrice
                    };
                }
                return selectedSku;
            })
        );
    };

    const handleGsvPrice = (event: any, sku: any) => {
        sku.gsvPrice = parseFloat(event.target.value);
        if (sku.gsvPrice != "" && sku.gsvPrice > 0) {
            if (sku.nsv > 0) {
                sku.cogsPrice = (sku.gsvPrice - ((sku.gsvPrice * parseFloat(sku.nsv)) / 100)).toFixed(2);
            } else if (sku.cogsPrice > 0) {
                sku.nsv = ((sku.gsvPrice - sku.cogsPrice) / sku.gsvPrice * 100).toFixed(2);
            }
        }
        setSelectedSkus((prevSelectedSkus: any) =>
            prevSelectedSkus.map((selectedSku: any) => {
                if (selectedSku.id === sku.id) {
                    return {
                        ...selectedSku,
                        manuallyEdited: true,
                        nsvPercentage: sku.nsv,
                        targetPrice: sku.cogsPrice,
                        sellingPrice: sku.gsvPrice
                    };
                }
                return selectedSku;
            })
        );
    };

    const handleNsv = (event: any, sku: any) => {
        sku.nsv = event.target.value;
        if (sku.nsv != "" && sku.nsv > 0) {
            if (sku.gsvPrice > 0) {
                let totalPercentage = (100 + parseFloat(sku.nsv));
                sku.cogsPrice = ((sku.gsvPrice * 100) / totalPercentage).toFixed(2);
            } else if (sku.cogsPrice > 0) {
                let reducedPercentage = (1 - (sku.nsv / 100));
                sku.gsvPrice = (parseFloat(sku.cogsPrice) / reducedPercentage).toFixed(2);
            }
        }
        setSelectedSkus((prevSelectedSkus: any) =>
            prevSelectedSkus.map((selectedSku: any) => {
                if (selectedSku.id === sku.id) {
                    return {
                        ...selectedSku,
                        manuallyEdited: true,
                        nsvPercentage: sku.nsv,
                        targetPrice: sku.cogsPrice,
                        sellingPrice: sku.gsvPrice
                    };
                }
                return selectedSku;
            })
        );
    };

    const handleDeliveryArea = (sku: any, data: any) => {
        let totalQty = 0;
        data.map((deliveryArea: any) => {
            totalQty += parseInt(deliveryArea.quantity);
        });
        setSelectedSkus((prevSelectedSkus: any) =>
            prevSelectedSkus.map((selectedSku: any) => {
                if (selectedSku.id === sku.id) {
                    return {...selectedSku, deliveryAreas: data, quantity: totalQty};
                }
                return selectedSku;
            })
        );
        sku.deliveryAreas = data;
        sku.quantity = totalQty;
        const newShowDeliveryAreaDetails = {...showDeliveryAreaDetails, [sku.id]: !showDeliveryAreaDetails[sku.id]};
        setShowDeliveryAreaDetails(newShowDeliveryAreaDetails);
    };

    const handleDeliveryAreaSelection = (sku: any) => {
        const newShowDeliveryAreaDetails = {...showDeliveryAreaDetails, [sku.id]: !showDeliveryAreaDetails[sku.id]};

        setShowDeliveryAreaDetails(newShowDeliveryAreaDetails);
    }

    const loadSkus = () => {
        setSkus([]);
        setIsLoading(true);
        let url = `/catalogue/sku/get-all-for-item/${item?.id}/${project.country}/${project.location}`;

        const params = new URLSearchParams({
            searchQuery: searchQuery,
        });
        if (Object.keys(appliedFilters).length > 0) {
            Object.entries(appliedFilters).map(([name, value]: any) => {
                params.append(name, value);
            });
        }

        api.get(url, {
            params: params,
        })
            .then((response) => {
                return response.data;
            })
            .then((response: any) => {
                setSkuDetails(response);
            })
            .catch((ex) => {
                console.log(ex);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleViewDetails = async (skuId: any) => {
        const newShowViewDetails = {...showViewDetails, [skuId]: !showViewDetails[skuId]};
        setShowViewDetails(newShowViewDetails);
    };

    const handleShowSimilarSkus = async (skuId: any) => {
        const newShowSimilarSkus = {...showSimilarSkus, [skuId]: !showSimilarSkus[skuId]};
        setShowSimilarSkus(newShowSimilarSkus);
    }

    const loadSelectedSkus = () => {
        setSelectedSkus([]);
        let url = `/procurement/estimation/get-item-details/${props.revision.id}/${props.subCategoryId}/${item.id}`;
        setIsLoading(true);
        api
            .get(url)
            .then((response) => {
                return response.data;
            })
            .then((response: any) => {
                setIsLoading(false);
                const estimationSkus: any = [];
                if (Array.isArray(response) && response.length > 0) {
                    const skuIds: any[] = [];
                    response.forEach((sku: any) => {
                        let cogsPrice = sku.targetPrice;
                        let nsv = sku.nsvPercentage;

                        if (!sku.manuallyEdited && props.showUpdatedCharges) {
                            if (props.category.code.toLowerCase() == "ff") {
                                cogsPrice = cogsPrice + ((cogsPrice * 2) / 100);
                            } else {
                                cogsPrice = cogsPrice + ((cogsPrice * 6) / 100);
                            }
                            nsv = (sku.sellingPrice - cogsPrice) / sku.sellingPrice * 100;
                        }
                        let selectedSku = {...sku, id: sku.skuId, targetPrice: cogsPrice, nsvPercentage: nsv};
                        estimationSkus.push(selectedSku);
                        skuIds.push(sku.skuId);
                    });
                    setSelectedSkuIds(skuIds);

                    setSelectedSkus(estimationSkus);
                }
            })
            .catch((ex) => {
                console.log(ex);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        if (Array.isArray(selectedSkus) && selectedSkus.length > 0) {
            const SkuIds = selectedSkus.map((selectedSku: any) => selectedSku.id);

        }
        setSkus([]);

        skuDetails.forEach((sku: any) => {
            sku.targetPrice = sku.cogsPrice != null ? sku.cogsPrice : "";
            sku.sellingPrice = sku.gsvPrice != null ? sku.gsvPrice : "";
            sku.gsvPrice = sku.gsvPrice != null ? sku.gsvPrice : "";
            sku.nsvPercentage = sku.nsv != null ? sku.nsv : "";
            sku.quantity = "";
            sku.bufferQuantity = "";
            sku.deliveryAreas = [];
            sku.isSelected = false;

            if (Array.isArray(selectedSkus) && selectedSkus.length > 0) {
                selectedSkus.forEach((selectedSku: any) => {
                    if (selectedSku.id === sku.id) {
                        sku.isSelected = true;
                        sku.cogsPrice = selectedSku.targetPrice;
                        sku.quantity = selectedSku.quantity;
                        sku.bufferQuantity = selectedSku.bufferQuantity;
                        sku.deliveryAreas = selectedSku.deliveryAreas;
                        sku.gsvPrice = selectedSku.sellingPrice;
                        sku.nsv = selectedSku.nsvPercentage;
                    }
                });
            }
        });
        if (skuDetails.length > 0) {
            const order: any = {true: 1, false: 2, null: 3};
            skuDetails.sort((a:any, b:any) => order[a.isSelected] - order[b.isSelected]);
        }

        setSkus(skuDetails);
    }, [selectedSkus, skuDetails]);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <Box sx={style.main}>
                <Box sx={{position: "relative", height: "100%"}}>
                    <Box sx={style.header}>
                        <Box sx={style.headerContainer}>
                            <Box>
                                <Typography variant={"h5"} sx={{fontWeight: 700}}>
                                    {item?.name}
                                </Typography>
                            </Box>
                            <Box sx={{ml: "auto"}}>
                                <CloseIcon sx={{cursor: "pointer"}} onClick={handleClose}/>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={"modal-body"} sx={style.body}>
                        <Box sx={style.bodyContainer}>
                            {isLoading &&
                                <Loader/>
                            }
                            <>
                                <Box>
                                    {skus.length > 0 && !isLoading && (
                                        <Box className={"skus"}>
                                            <Box className={"sku-listing"}></Box>
                                            <Box>
                                                <Box sx={{pb: 2, alignItems: 'center'}}
                                                     className={'right-align-content'}>
                                                    <SearchFilter
                                                        handleFilterChange={handleFilterChange}
                                                        filters={filters}
                                                        appliedFilters={appliedFilters}
                                                        showFilters={true}
                                                        showSearch={true}
                                                        query={searchQuery}
                                                        handleSearchFilter={handelSearchFilter}
                                                    />
                                                </Box>
                                                <Box>
                                                    <RemoveFilters handleFilterChange={handleFilterChange}
                                                                   filters={filters}
                                                                   appliedFilters={appliedFilters}/>
                                                </Box>
                                                <Grid container alignItems={"stretch"}
                                                      sx={{py: 2, backgroundColor: "info.light"}}>
                                                    <Grid item xs={0.5} sx={{textAlign: "center"}}>
                                                        <Box className={"space-between"}>
                                                            <Checkbox
                                                                color={"info"}
                                                                checked={showBoqItems}
                                                                onChange={handleShowBoqItems}
                                                            />
                                                            <CustomTooltip info={"Click to view selected SKUs only"}/>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={2.5} sx={{textAlign: "center"}}>Item Sku Name</Grid>
                                                    <Grid item xs={props.showUpdatedCharges ? 3 : 2.2}
                                                          sx={{
                                                              px: 1,
                                                              display: 'flex',
                                                              alignItems: 'center',
                                                              justifyContent: 'space-between'
                                                          }}>
                                                        <Typography variant="body1">Description/Specs</Typography>
                                                        <Button variant={"text"} onClick={handleToggleDescription}
                                                                sx={{fontSize: "0.7rem", p: 0, m: 0}}>
                                                            {showDescription ? "Show Specs" : "Show Description"}
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={1.25} sx={{textAlign: "center", px: 1}}>GSV</Grid>
                                                    <Grid item xs={props.showUpdatedCharges ? 1 : 0.75} sx={{textAlign: "center"}}>NSV</Grid>
                                                    {!props.showUpdatedCharges && (
                                                        <Grid item xs={1.20} sx={{textAlign: "center"}}>
                                                            Planned COGS
                                                        </Grid>
                                                    )}
                                                    <Grid item sx={{textAlign: "center"}} xs={props.showUpdatedCharges ? 1.15 : 1.05}>Quantity</Grid>
                                                    <Grid item sx={{textAlign: "center"}} xs={1.05}>
                                                        Buffer Quantity
                                                    </Grid>
                                                    <Grid item xs={1} sx={{textAlign: "center"}}>
                                                        Delivery Area
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                            {skus.map((sku: any, index: number) => {
                                                let anchor = anchorEl.filter((x: any) => x.rowIndex == index)[0];
                                                if (showBoqItems && !sku.isSelected) {
                                                    return;
                                                }
                                                let totalEntities = sku?.entityPrices ? sku.entityPrices.length : 0;
                                                return (
                                                    <Box key={"sku-index-" + index}>
                                                        <Grid
                                                            container
                                                            alignItems={"stretch"}
                                                            key={`sku-estimation-${sku.id}-${index}`}
                                                        >
                                                            <Grid
                                                                item
                                                                xs={0.5}
                                                                sx={{
                                                                    border: 1,
                                                                    borderColor: "divider",
                                                                    pt: 2,
                                                                    borderTop: 0,
                                                                    textAlign: "center",
                                                                }}
                                                            >
                                                                <Checkbox
                                                                    color={"info"}
                                                                    checked={selectedSkuIds.includes(sku.id)}
                                                                    onChange={(e: any) => handleSkuSelection(e, sku)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={2.5}  sx={styles.borderGrid}>
                                                                <Grid container>
                                                                    <Grid item xs={4} sx={{pr: 1}}>
                                                                        {sku.primaryImage != null && (
                                                                            <Box>
                                                                                <img
                                                                                    src={sku?.primaryImage}
                                                                                    className={"sku-image"}
                                                                                    style={{width: "100%"}}
                                                                                    alt={sku.name}
                                                                                />
                                                                            </Box>
                                                                        )}
                                                                        {sku.primaryImage == null && (
                                                                            <Box
                                                                                sx={{
                                                                                    width: "100%",
                                                                                    height: "80px",
                                                                                    backgroundColor: "secondary.light",
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </Grid>
                                                                    <Grid item xs={8}>
                                                                        <Box>
                                                                            <Typography
                                                                                variant={"body1"}
                                                                                sx={{fontSize: "0.9rem"}}
                                                                            >
                                                                                {sku.name}
                                                                                {totalEntities > 0 && <span className={"anchor-color"} style={{fontSize:'9px'}}> (Total RC Available: {totalEntities})</span>}
                                                                            </Typography>
                                                                            <Box sx={{pt: 3}}>
                                                                                <Typography
                                                                                    variant={"body2"}
                                                                                    sx={{fontSize: "0.8rem"}}
                                                                                >
                                                                                    Label - {sku.code}
                                                                                </Typography>
                                                                            </Box>
                                                                        </Box>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={props.showUpdatedCharges ? 3 : 2.2} sx={styles.borderGrid}>
                                                                {showDescription ? (
                                                                    <ReadMore text={sku.description} maxChars={220}
                                                                              className={"sku-desc"}/>
                                                                ) : (
                                                                    <Box>
                                                                        {sku.options && sku.options.length > 0 ? (
                                                                            sku.options.map((option: any, idx: number) => (
                                                                                <Typography key={idx}
                                                                                            variant={"body2"}
                                                                                            sx={{
                                                                                                fontSize: "0.8rem",
                                                                                                padding: '0.4rem'
                                                                                            }}>
                                                                                    <strong>{option.name}:</strong> {option.value}
                                                                                </Typography>
                                                                            ))
                                                                        ) : (
                                                                            <Typography
                                                                                sx={{fontSize: "0.8rem", padding: '0.4rem'}}>
                                                                                NA
                                                                            </Typography>
                                                                        )}
                                                                    </Box>
                                                                )}
                                                            </Grid>

                                                            <Grid
                                                                className={"gsv-input"}
                                                                item
                                                                xs={1.25}
                                                                sx={{
                                                                    border: 1,
                                                                    borderColor: "divider",
                                                                    p: 1,
                                                                    borderLeft: 0,
                                                                    borderTop: 0,
                                                                    textAlign: "right",
                                                                }}
                                                            >
                                                                <Box
                                                                    className={"center-aligned"}
                                                                    sx={{height: "100%"}}
                                                                >
                                                                    {selectedSkuIds.includes(sku.id) ? (
                                                                        <TextField
                                                                            fullWidth
                                                                            size={"small"}
                                                                            type={"number"}
                                                                            variant={"outlined"}
                                                                            className={"inputBox"}
                                                                            value={sku?.gsvPrice}
                                                                            onChange={(e) => handleGsvPrice(e, sku)}
                                                                            inputProps={{
                                                                                type: "number"
                                                                            }}
                                                                            InputProps={{
                                                                                style: {
                                                                                    borderRadius: "5px",
                                                                                    borderColor: "primary.light",
                                                                                    fontSize: "0.8rem"
                                                                                },
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <Typography>
                                                                            {sku?.gsvPrice ? getPriceInFormatted(sku.gsvPrice, project.country) : ""}
                                                                        </Typography>
                                                                    )}
                                                                </Box>
                                                            </Grid>
                                                            <Grid className={"nsv-input right-align-text"} item xs={props.showUpdatedCharges ? 1 : 0.75}
                                                                  sx={styles.borderGrid}>
                                                                <Box className={"center-aligned"}
                                                                     sx={{height: "100%"}}>
                                                                    {selectedSkuIds.includes(sku.id) ? (
                                                                        <TextField
                                                                            fullWidth
                                                                            size={"small"}
                                                                            type={"number"}
                                                                            variant={"outlined"}
                                                                            className={"inputBox"}
                                                                            value={sku?.nsv ? sku.nsv : ""}
                                                                            onChange={(e) => handleNsv(e, sku)}
                                                                            inputProps={{
                                                                                type: "number",
                                                                            }}
                                                                            InputProps={{
                                                                                style: {
                                                                                    borderRadius: "5px",
                                                                                    borderColor: "primary.light",
                                                                                    fontSize: "0.8rem"
                                                                                },
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <Typography>
                                                                            {sku?.nsv ? sku.nsv.toFixed(2) : ""}
                                                                        </Typography>
                                                                    )}
                                                                </Box>
                                                            </Grid>
                                                            {!props.showUpdatedCharges && (
                                                                <Grid className={"cogs-input"} item xs={1.2} sx={styles.borderGrid}>
                                                                    <Box className={"center-aligned"}
                                                                         sx={{height: "100%"}}>
                                                                        {selectedSkuIds.includes(sku.id) ? (
                                                                            <TextField
                                                                                fullWidth
                                                                                size={"small"}
                                                                                type={"number"}
                                                                                variant={"outlined"}
                                                                                className={"inputBox"}
                                                                                value={sku?.cogsPrice ? sku.cogsPrice : ""}
                                                                                onChange={(e) => handleCogsPrice(e, sku)}
                                                                                inputProps={{
                                                                                    type: "number"
                                                                                }}
                                                                                InputProps={{
                                                                                    style: {
                                                                                        borderRadius: "5px",
                                                                                        borderColor: "primary.light",
                                                                                        fontSize: "0.8rem"
                                                                                    },
                                                                                }}
                                                                            />
                                                                        ) : (
                                                                            <Typography>
                                                                                {sku?.cogsPrice ? getPriceInFormatted(sku.cogsPrice, project.country) : ""}
                                                                            </Typography>
                                                                        )}
                                                                    </Box>
                                                                </Grid>
                                                            )}
                                                            <Grid item xs={props.showUpdatedCharges ? 1.15 : 1.05} sx={styles.borderGrid}>
                                                                <Box className={"center-aligned"}
                                                                     sx={{height: "100%"}}>
                                                                    {selectedSkuIds.includes(sku.id) ? (
                                                                        <TextField
                                                                            fullWidth
                                                                            disabled={!selectedSkuIds.includes(sku.id)}
                                                                            size={"small"}
                                                                            type={"number"}
                                                                            variant={"outlined"}
                                                                            className={"inputBox"}
                                                                            value={sku?.quantity}
                                                                            onChange={(e) => handleQuantitySelection(e, sku)}
                                                                            InputProps={{
                                                                                style: {
                                                                                    borderRadius: "5px",
                                                                                    borderColor: "primary.light",
                                                                                    fontSize: "0.8rem",
                                                                                },
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <Typography>
                                                                            {sku?.quantity ? sku.quantity : ""}
                                                                        </Typography>
                                                                    )}
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={1.05} sx={styles.borderGrid}>
                                                                <Box className={"center-aligned"}
                                                                     sx={{height: "100%"}}>
                                                                    {selectedSkuIds.includes(sku.id) ? (
                                                                        <TextField
                                                                            fullWidth
                                                                            size={"small"}
                                                                            type={"number"}
                                                                            variant={"outlined"}
                                                                            className={"inputBox"}
                                                                            value={sku?.bufferQuantity}
                                                                            onChange={(e) => handleBufferQuantitySelection(e, sku)}
                                                                            InputProps={{
                                                                                style: {
                                                                                    borderRadius: "5px",
                                                                                    borderColor: "primary.light",
                                                                                    fontSize: "0.8rem",
                                                                                },
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <Typography>
                                                                            {sku?.bufferQuantity ? sku.bufferQuantity : ""}
                                                                        </Typography>
                                                                    )}
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={1} sx={styles.borderGrid}>
                                                                {(selectedSkuIds.includes(sku.id)) &&
                                                                    <Box className={"center-aligned"}
                                                                         sx={{
                                                                             height: "100%",
                                                                             flexDirection: 'column'
                                                                         }}>
                                                                        <Typography sx={{fontSize: '0.7rem'}}>
                                                                            {sku.deliveryAreas.length > 0 ? "Selected Delivery Areas" : "NA"}
                                                                        </Typography>
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: '0.7rem',
                                                                                cursor: 'pointer',
                                                                                color: 'primary.main'
                                                                            }}
                                                                            onClick={() => handleDeliveryAreaSelection(sku)}>
                                                                            {!showDeliveryAreaDetails[sku.id] ? "Show" : "Hide"}
                                                                        </Typography>
                                                                    </Box>
                                                                }
                                                            </Grid>
                                                            <Grid item xs={0.5} sx={styles.borderGrid}>
                                                                <Box className={"center-aligned"}
                                                                     sx={{height: "100%"}}>
                                                                    <Button onClick={(e) => handleSkuMenu(e, index)}
                                                                            variant={"text"}> <MoreVert/></Button>
                                                                    <Menu
                                                                        anchorEl={anchor ? anchor.eventTarget : null}
                                                                        id="account-menu"
                                                                        open={Boolean(anchor ? anchor.eventTarget : null)}
                                                                        onClose={() => handleSkuMenuClose(index)}
                                                                        onClick={() => handleSkuMenuClose(index)}
                                                                        PaperProps={{
                                                                            elevation: 0,
                                                                            sx: {
                                                                                overflow: 'visible',
                                                                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                                                                mt: 1.5,
                                                                                '& .MuiAvatar-root': {
                                                                                    width: 32,
                                                                                    height: 32,
                                                                                    ml: -0.5,
                                                                                    mr: 1,
                                                                                },
                                                                                '&::before': {
                                                                                    content: '""',
                                                                                    display: 'block',
                                                                                    position: 'absolute',
                                                                                    top: 0,
                                                                                    right: 14,
                                                                                    width: 10,
                                                                                    height: 10,
                                                                                    bgcolor: 'background.paper',
                                                                                    transform: 'translateY(-50%) rotate(45deg)',
                                                                                    zIndex: 0,
                                                                                },
                                                                            },
                                                                        }}
                                                                        transformOrigin={{
                                                                            horizontal: 'right',
                                                                            vertical: 'top'
                                                                        }}
                                                                        anchorOrigin={{
                                                                            horizontal: 'right',
                                                                            vertical: 'bottom'
                                                                        }}
                                                                    >
                                                                        <MenuItem
                                                                            onClick={() => handleViewDetails(sku.id)}>
                                                                            {showViewDetails[sku.id] ? "Hide Details" : "View Details"}
                                                                        </MenuItem>
                                                                        {(betaEnabled && sku.options && sku.options.length > 0) &&
                                                                            <MenuItem
                                                                                onClick={() => handleShowSimilarSkus(sku.id)}>
                                                                                {showSimilarSkus[sku.id] ? "Hide Similar Skus" : "Show Similar Skus"} (&beta;)
                                                                            </MenuItem>
                                                                        }
                                                                    </Menu>
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                        {showViewDetails[sku.id] && (
                                                            <EstimationSkuDetails sku={sku} category={props.category} subCategory={props.subCategory}/>
                                                        )}
                                                        {showSimilarSkus[sku.id] && (
                                                            <Box sx={{position: 'relative'}}>
                                                                <SimilarSkus project={props.project}
                                                                             showDescription={showDescription}
                                                                             handleSimilarSkuClose={handleShowSimilarSkus}
                                                                             revision={props.revision}
                                                                             subCategoryId={props.subCategoryId}
                                                                             skuId={sku.id}/>
                                                            </Box>
                                                        )}
                                                        {(showDeliveryAreaDetails[sku.id]) &&
                                                            <SelectDeliveryArea
                                                                sku={sku}
                                                                handleDeliveryArea={handleDeliveryArea}
                                                            />
                                                        }
                                                    </Box>
                                                )
                                            })}
                                        </Box>
                                    )}
                                </Box>
                            </>
                            {/*)}*/}
                        </Box>
                    </Box>
                    <Box sx={style.footer}>
                        {skus.length > 0 && !isLoading && (
                            <Grid container sx={{px: 2, height: "100%"}} className={"center-aligned"}>
                                <Grid item xs/>
                                <Grid item>
                                    <Box>
                                        <Button variant={"text"} sx={{mr: 2, fontSize: "0.8rem"}} onClick={handleClose}>
                                            Cancel
                                        </Button>
                                        <Button variant={"contained"} sx={{fontSize: "0.8rem"}}
                                                onClick={saveSkuSelection}>
                                            Save
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        )}
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};


const filters = {
    'name': {
        element: 'input',
        type: 'text',
        placeholder: 'Search By Sku Name',
        label: 'Sku Name',
        name: 'name'
    },
    'code': {
        element: 'input',
        type: 'text',
        placeholder: 'Search By Sku code',
        label: 'Sku Code',
        name: 'code'
    },
    'uom': {
        element: 'input',
        type: 'text',
        placeholder: 'Search By UOM',
        label: 'Sku UOM',
        name: 'uom'
    },
    'RC_price': {
        element: 'radio',
        label: 'RC Price',
        name: 'RC_price',
        options: [
            {id: 'yes', label: 'Yes'},
            {id: 'no', label: 'No'},
        ],
    },
    'price': {
        element: 'slider',
        placeholder: 'Search Here',
        label: 'Price Range',
        name: 'price',
        info: '1 = 1000',
        range: [0, 1000000]
    }
};

export default ListEstimationSkusPopup;
import {Box, Grid} from "@mui/material";
import LabelDetail from "./LabelDetail";
import InputBox from "./InputBox";
import SelectBox from "./SelectBox";
import CheckboxOptions from "./CheckboxOptions";
import RadioOptions from "./RadioOptions";

const FormFields = (props: any) => {
    return (
        <>
            <Grid container spacing={2}>
                {props.fields.map((formField: any, formIndex: number) => {
                    let width = formField.width != undefined ? formField.width : 6;
                    if (formField.fullWidth != undefined && formField.fullWidth) {
                        width = 12;
                    }
                    let mainContainerWidth = width;
                    if (formField.mainContainerWidth != undefined && formField.mainContainerWidth) {
                        mainContainerWidth = formField.mainContainerWidth;
                    } else {
                        width = 12;
                    }
                    return (
                        <Grid item xs={mainContainerWidth} key={"build-group-form-" + formIndex}>
                            <LabelDetail formField={formField}/>
                            <Grid container>
                                <Grid item xs={width}>
                                    <Box sx={{pt: 1}}>
                                        {formField.element == 'input' &&
                                            <InputBox formField={formField}
                                                      onChangeHandler={props.onChangeHandler}
                                                      disableFields={props.disableFields}
                                                      valueFields={props.valueFields}/>
                                        }

                                        {formField.element == 'select' &&
                                            <SelectBox formField={formField}
                                                       onChangeHandler={props.onChangeHandler}
                                                       disableFields={props.disableFields}
                                                       valueFields={props.valueFields}/>
                                        }

                                        {formField.element == 'checkbox' &&
                                            <CheckboxOptions formField={formField}
                                                             onChangeHandlerMultiple={props.onChangeHandlerMultiple}
                                                             disableFields={props.disableFields}
                                                             valueFields={props.valueFields}/>
                                        }

                                        {formField.element == 'radio' &&
                                            <RadioOptions formField={formField}
                                                          onChangeHandler={props.onChangeHandler}
                                                          disableFields={props.disableFields}
                                                          valueFields={props.valueFields}/>
                                        }
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                })}
            </Grid>
        </>
    )
}
export default FormFields;
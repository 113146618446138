import {Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {getPriceInFormatted} from "../../../utils/Common";
import {interpolateColor} from "./utils";
import {useAppDispatch} from "../../../app/hooks";
import {getAcceptBid} from "./features/actions";
import {openSnackbar} from "../../../components/common/features/snackbarSlice";
import {ACCEPT_BID_FAILED, ACCEPT_BID_SUCCESS, ERROR_COLOR, SUCCESS_COLOR} from "../../../constants";
import {hasAccess} from "../../../utils/Access";

const BreakBidsContent = (props: any) => {
    let {eventVendorsList, eventPackageDetails, eventStatus} = props;
    const [eventPackages, setEventPackages] = useState<any>([]);
    const [bidVendors, setBidVendors] = useState<any>([]);
    const [acceptBidCTA, setAcceptBidCTA] = useState<any>(true);
    const dispatch = useAppDispatch();

    const handleAcceptBid = async (
        vendorDetails: any
    ) => {
        try {
            const response = await dispatch(getAcceptBid(vendorDetails.id)).unwrap();
            if (response) {
                dispatch(
                    openSnackbar({
                        message: ACCEPT_BID_SUCCESS,
                        backgroundColor: SUCCESS_COLOR,
                    })
                );
                setAcceptBidCTA(false);
                props.acceptBid();
            }
        } catch (err: any) {
            console.log(err);
            dispatch(
                openSnackbar({
                    message: ACCEPT_BID_FAILED,
                    backgroundColor: ERROR_COLOR,
                })
            );
        }
    };

    useEffect(() => {
        if (eventVendorsList.length > 0) {
            let priceAvailableVendors: any[] = [];
            const updatedEventPackageDetails = eventPackageDetails.map((packageDetails: any) => {
                const items = packageDetails.items.map((item: any) => {
                    let vendorList: any[] = [];
                    let prices: any[] = [];
                    eventVendorsList.map((eventDetails: any) => {
                        eventDetails.eventVendorDetails.map((vendorDetails: any) => {
                            if (vendorDetails.packageItemId == item.id) {
                                let vendorPrice = vendorDetails.price + vendorDetails.domainCharges.total;
                                vendorList.push({...vendorDetails, eventVendorId: eventDetails.id, price: vendorPrice});
                                if (!priceAvailableVendors.includes(eventDetails.vendor.id)) {
                                    priceAvailableVendors.push(eventDetails.vendor.id);
                                }
                                prices.push((vendorPrice));
                            }
                        });
                    });
                    let max, min = 0;
                    if (vendorList.length > 0) {
                        max = Math.max(...prices);
                        min = Math.min(...prices);
                    }
                    return {
                        ...item, eventVendors: vendorList, min, max
                    }
                })
                return {
                    ...packageDetails, items: items
                }
            });
            setBidVendors(priceAvailableVendors);
            setEventPackages(updatedEventPackageDetails);
        }
    }, [eventVendorsList]);

    return (
        <TableContainer
            component={"div"}
            sx={{
                border: "1px solid",
                borderColor: "divider",
                borderBottom: 0,
                maxHeight: "500px",
                overflowX: "auto",
                overflowY: "auto",
            }}
        >
            <Table sx={{minWidth: 650}} aria-label="simple table">
                <TableHead sx={{
                    backgroundColor: "info.contrastText",
                    position: 'sticky',
                    top: 0,
                    zIndex: 2
                }}>
                    <TableRow>
                        <TableCell width={"6%"} align={"left"}
                                   sx={{fontWeight: 500, minWidth: '100px'}}>Sr. No.</TableCell>
                        <TableCell align={"left"} sx={{fontWeight: 500, minWidth: '150px'}}>SKU
                            Name</TableCell>
                        <TableCell width={"10%"} align={"left"}
                                   sx={{fontWeight: 500, minWidth: '100px'}}>Quantity</TableCell>
                        {eventVendorsList.map((vendorDetails: any, idx: number) => {
                            if (bidVendors.includes(vendorDetails.vendor.id)) {
                                return (
                                    <TableCell key={'bid-vendor-names-' + idx} align={"left"}
                                               sx={{fontWeight: 500, minWidth: '150px'}}>
                                        {vendorDetails?.vendor?.name || "Unknown Vendor"}
                                    </TableCell>
                                )
                            }
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {eventPackages.map((eventPackage: any, packageIndex: number) => (
                        eventPackage.status == "EVENT" && eventPackage.items.map((item: any, itemIndex: number) => {
                            let currentIndex = (packageIndex * eventPackage.items.length) + itemIndex;
                            return (
                                <TableRow key={"package-" + packageIndex + "-" + itemIndex}>
                                    <TableCell width={"6%"} align={"left"} sx={{fontWeight: 500, minWidth: '100px'}}>
                                        {currentIndex + 1}
                                    </TableCell>
                                    <TableCell align="left" sx={{minWidth: '150px'}}>
                                        <Typography sx={{fontSize: '11px', fontWeight: 400}}>
                                            {item.itemDetail?.itemCategory?.name}
                                        </Typography>
                                        <Typography sx={{fontSize: '13px', fontWeight: 500}}>
                                            {item.itemDetail?.sku?.name}
                                        </Typography>
                                    </TableCell>
                                    <TableCell width={"10%"} align="left"
                                               sx={{minWidth: '100px'}}>
                                        <Typography sx={{
                                            fontSize: '11px',
                                            fontWeight: 400
                                        }}>{item.totalQuantity}</Typography>
                                    </TableCell>

                                    {item.eventVendors.map((vendor: any, vendorIndex: number) => {
                                        const color = interpolateColor(item.min, item.max, vendor.price);
                                        return (
                                            <TableCell>
                                                <Typography
                                                    sx={{color: vendor.price ? color : 'darkgray', fontWeight: 900}}>
                                                    {vendor.price ? getPriceInFormatted(vendor.price, '') : "NA"}
                                                </Typography>
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            )
                        })
                    ))}
                </TableBody>

                {eventStatus != "CLOSED" && (
                    <TableFooter>
                        <TableRow
                            sx={{backgroundColor: "info.contrastText", position: 'sticky', bottom: -1, zIndex: 1}}>
                            <TableCell/>
                            <TableCell/>
                            <TableCell align="left"></TableCell>
                            {eventVendorsList.map((vendorDetails: any, idx: number) => {
                                if (bidVendors.includes(vendorDetails.vendor.id)) {
                                    let showAcceptBid = true;
                                    vendorDetails.eventVendorDetails.forEach((eventVendorDetails: any) => {
                                        if(eventVendorDetails.price == null) {
                                            showAcceptBid = false;
                                        }
                                    });
                                    return (
                                        <TableCell
                                            key={"accept-bid-" + idx}
                                            align="left"
                                            sx={{
                                                color: "#ccc",
                                                fontSize: "12px",
                                                fontWeight: 700,
                                                lineHeight: "14.4px",
                                                minWidth: '150px',
                                                position: 'sticky',
                                                bottom: 0,
                                                backgroundColor: "info.contrastText"
                                            }}
                                        >
                                            {showAcceptBid && acceptBidCTA && hasAccess(["edit-event"]) ? (
                                                <Typography
                                                    onClick={() =>
                                                        handleAcceptBid(vendorDetails)
                                                    }
                                                    sx={{
                                                        fontSize: "12px",
                                                        cursor: "pointer",
                                                        color: "#3854E4",
                                                    }}
                                                >
                                                    Accept Bid
                                                </Typography>
                                            ) : (
                                                <Typography sx={{fontSize: "12px", color: "darkgray"}}>
                                                    NA
                                                </Typography>
                                            )}
                                        </TableCell>
                                    )
                                }
                            })}
                        </TableRow>
                    </TableFooter>
                )}
            </Table>
        </TableContainer>
    )
}

export default BreakBidsContent;
import { Box, Typography} from "@mui/material";
import "../estimation/EstimationLoader.css";
import eCatalogLoader from '../../assets/eCatalogLoader.png';
import LinearLoader from "../estimation/LinearLoader";
import ECatalogTextSwiper from "./ECatalogTextSwiper";

export default function ECatalogLoader(props:any) {
    return (
        <Box className="estimation-loader" sx={{marginTop: props?.margin ? props.margin: '0'}}>
            <Box component="img" src={eCatalogLoader} sx={{width:"230px", margin:"20px 0 0px"}}></Box>
            {!props.eCatalogError && <ECatalogTextSwiper />}
            {!props.eCatalogError && <LinearLoader />}
            {props.eCatalogError && 
            <Box>
                <Typography variant="body2" color="text.secondary" sx={{color: '#5e1baf', fontSize: '1rem'}}>
                    {"Something went wrong. It's taking too long to load e-catalog."}
                </Typography>
            </Box>}
        </Box>
    );
}
import { Box, Button, FormControl, Grid, InputLabel, Select, TextField, Typography, Popper, Paper, ClickAwayListener, MenuItem, MenuList, Grow, Checkbox } from "@mui/material";
import { useAppDispatch } from "../../app/hooks";
import { openSnackbar } from "../common/features/snackbarSlice";
import { ERROR_COLOR, SUCCESS_COLOR } from "../../constants";
import { api } from "../../utils/Axios";
import Loader from "../Loader";
import { useEffect, useState, useRef, forwardRef, useImperativeHandle, SyntheticEvent } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { spaceDetailsConfig } from "./RoomDataConfig";
import Menu from '@mui/material/Menu';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';


// Define the ref type
export type ContinueRef = {
    saveHeadCount: (e:SyntheticEvent) => void;
};

const RoomDataItems = forwardRef((props:any, ref) => {
    const [items, setItems] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [itemCategoryResponse, setItemCategoryResponse] = useState<any>({});
    const [expandedSpaceDetails, setExpandedSpaceDetails] = useState<{ [key: string]: boolean }>({});
    const [inputValues, setInputValues] = useState<{ [key: string]: any }>({});
    const [anchorEl, setAnchorEl] = useState<any>(null);
    const dispatch = useAppDispatch();
    const currentIndex = props.currentIndex;
    const [saveEnabled, setSaveEnabled] = useState(true);
    const [anchorElCategory, setAnchorElCategory] = useState<null | HTMLElement>(null);
    const openCategory = Boolean(anchorElCategory);
    const [categoryOptions, setCategoryOptions] = useState<any>([]);
    const [currentSelected, setCurrentSelected] = useState<any>("");
    const [showImpact, setShowImpact] = useState(false);

    var gotoNextTab = false;
    const ITEM_HEIGHT = 48;

    // const camelCase = (str: string) => {
    //     return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => 
    //         index === 0 ? match.toLowerCase() : match.toUpperCase()
    //     ).replace(/\s+/g, '');
    // };
    const camelCase = (str: string) => {
        return str.replace(/[^a-zA-Z0-9]+/g, '')
          .replace(/(?:^\w|[A-Z]|\b\w)/g, (match, index) => 
            index === 0 ? match.toLowerCase() : match.toUpperCase()
          );
      };

    const handleExpandSpaceDetails = (skuId: any, event: any) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
        setExpandedSpaceDetails((prev) => ({
            ...prev,
            [skuId]: !prev[skuId],
        }));
    };

    const handleCategoryClick = (mapItem:any, skuId:any, event: React.MouseEvent<HTMLElement>) => {
        setCategoryOptions(mapItem.items);
        if(anchorElCategory == event.currentTarget || mapItem.items.length == 0) {
            setCurrentSelected("");
            setAnchorElCategory(null);
        } else {
            setAnchorElCategory(event.currentTarget);
            setCurrentSelected(skuId+"-"+mapItem.category);
        }
    };
    const handleCategoryClose = (event:any) => {
        //setAnchorElCategory(null);
    };

    

    const handleImpactChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShowImpact(event.target.checked);
        if(!event.target.checked) {
            setCurrentSelected("");
            setAnchorElCategory(null);
        }
    };


    const handleInputChange = (skuId: any, fieldName: string, event: any) => {
        const { value } = event.target;
        setInputValues((prev) => ({
            ...prev,
            [skuId]: {
                ...prev[skuId],
                [camelCase(fieldName)]: value,
            },
        }));
        setSaveEnabled(true);
        props.updateFieldChange(true);
    };

    useEffect(() => {
        const initializeItems = () => {
            const initialItems = props.items.map((item: any) => ({
                ...item,
                skus: item.skus.map((sku: any) => ({
                    ...sku,
                    roomType: sku.roomType || "none",
                    quantity: sku.quantity || 0,
                    roomCount: sku.roomCount || 0,
                })),
            }));
            setItems(initialItems);
        };
        initializeItems();
    }, [props.items]);

    const handleChange = (itemIndex: number, skuIndex: number, event: any) => {
        const { name, value } = event.target;
        setItems((prevItems) => {
            const newItems = [...prevItems];
            newItems[itemIndex].skus[skuIndex][name] = value;
            return newItems;
        });
        setSaveEnabled(true);
        props.updateFieldChange(true);
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        let errorFlag = false;
        let message = "";
        items.forEach((item: any) => {
            item.skus.forEach((sku: any) => {
                if (errorFlag) {
                    dispatch(
                        openSnackbar({
                            message: message,
                            backgroundColor: ERROR_COLOR,
                        })
                    );
                }
            });
        });
        if (!errorFlag) {
            const selectedItems = items.map((item: any) => ({
                id: item.id,
                skus: item.skus.map((sku: any) => {
                    const spaceDetails = inputValues[sku.id]
                        ? Object.entries(inputValues[sku.id]).reduce((acc: any, [key, value]) => {
                            acc[camelCase(key)] = value;
                            return acc;
                        }, {})
                        : {};
                    return {
                        id: sku.id,
                        quantity: sku.quantity,
                        roomType: sku.roomType,
                        roomCount: sku.roomCount,
                        spaceDetails: spaceDetails,
                    };
                }),
            }));
    
            const form = {
                itemCategoryId: props.itemCategoryId,
                items: selectedItems,
            };
    
            setIsLoading(true);
            api.post(`/procurement/project/update-room-count-details/${props.project.id}?revisionId=${props?.revision?.id}`, form)
                .then((response) => {
                    return response.data;
                })
                .then((res) => {
                    dispatch(
                        openSnackbar({
                            message: "Room count details updated successfully",
                            backgroundColor: SUCCESS_COLOR,
                        })
                    );
                    setSaveEnabled(false);
                    props.updateFieldChange(false);

                    if(gotoNextTab) {
                        console.log("Continue with next tab");
                        props.handleContinue();
                    } else {
                        console.log("Continue with next category");
                        props.goToNextItemCategory(currentIndex);
                    }
                    gotoNextTab = false;
                    setIsLoading(false);
                })
                .catch((ex) => {
                    dispatch(
                        openSnackbar({
                            message: "Oops. Something went wrong",
                            backgroundColor: ERROR_COLOR,
                        })
                    );
                    console.log(ex);
                    setIsLoading(false);
                });
        }
    };
    

    const loadRoomDetails = () => {
        setIsLoading(true);
        api.get(`/procurement/project/get-room-count-details/${props.project.id}/${props.itemCategoryId}?revisionId=${props?.revision?.id}`)
            .then((response) => (response.status === 200 ? response.data : {}))
            .then((res) => {
                var saveEnable = true;
                if(res?.details?.length && res?.details?.length > 0) {
                    saveEnable = false;
                }
                setSaveEnabled(saveEnable);
                setItemCategoryResponse(res);
                setIsLoading(false);
            })
            .catch((ex) => {
                console.log(ex);
                setIsLoading(false);
            });
    };

    const handleContinue = (event:any) => {
        //console.log("handleContinue ", saveEnabled);
        if(saveEnabled) {
            //console.log("Submiting form");
            handleSubmit(event);
            gotoNextTab = true;
        } else {
            //console.log("Not Submiting form");
            props.handleContinue();
        }
    };

    useImperativeHandle(ref, () => ({
        saveHeadCount: (e:any) => {
            handleContinue(e);
        }
    }));

    useEffect(() => {
        loadRoomDetails();
    }, [props.itemCategoryId]);

    useEffect(() => {
        if (Object.keys(itemCategoryResponse).length > 0) {
            const updatedItems = items.map((item: any) => {
                const updatedSkus = item.skus.map((sku: any) => {
                    let overrideCount = 0;
                    let override = false;
                    if(sku.id == 'main_rooms_30') {

                        override = true;
                        let toiletCountStr = props.ciParameters["total-number-of-toilet-blocks"];
                        console.log("Toilets",toiletCountStr);
                        if(toiletCountStr) {
                            overrideCount = parseInt(toiletCountStr);
                        }
                    }

                    const roomDetail = itemCategoryResponse.details.find(
                        (rd: any) => rd.skuId === sku.id
                    );
                    return roomDetail
                        ? {
                              ...sku,
                              quantity: (sku.qtyHide && sku.qtyHide) ? 0 : roomDetail.quantity,
                              roomCount: (sku.countHide && sku.countHide) ? 0 : (override ? overrideCount : roomDetail.roomCount),
                              roomType: (sku.typeHide && sku.typeHide) ? '' : roomDetail.roomType,
                          }
                        : sku;
                });
                return { ...item, skus: updatedSkus };
            });

            const updatedInputValues = itemCategoryResponse.details.reduce((acc: any, detail: any) => {
                let overrideCount = 0;
                let override = false;
                if(detail.skuId == 'main_rooms_30') {
                    let toiletCountStr = props.ciParameters["total-toilet-area-in-sqm"];
                    if(toiletCountStr) {
                        overrideCount = parseInt(toiletCountStr);
                    }
                    detail.spaceDetails.closedAreaSqm = overrideCount;
                }

                acc[detail.skuId] = detail.spaceDetails; // Directly assign spaceDetails
                return acc;
            }, {});
    
            console.log("updatedItems", updatedItems);
            console.log("updatedInputValues", updatedInputValues);

            setItems(updatedItems);
            setInputValues(updatedInputValues);
        } else {
            let overrideCount = 0;
            let toiletCountStr = props.ciParameters["total-toilet-area-in-sqm"];
            if(toiletCountStr) {
                overrideCount = parseInt(toiletCountStr);
            }
            const updatedInputValues = {"main_rooms_30": {"closedAreaSqm":  overrideCount}}
            setInputValues(updatedInputValues);
        }
    }, [itemCategoryResponse]);


    const handleClickAway = () => {
        setAnchorEl(null);
        setExpandedSpaceDetails({});
    };

    if (isLoading) {
        return <Loader />;
    }

    return (
        <Box component={"form"} onSubmit={handleSubmit}>
            <Box sx={{display:'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', marginBottom: '10px'}}>
                <Checkbox
                  checked={showImpact}
                  onChange={handleImpactChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                  sx={{fontSize: '1rem'}}
                  size={'small'}
                /> 
                <Typography sx={{fontSize: '0.8rem'}}>Show Impact</Typography>
            </Box>
            {items.map((item: any, index: number) => (
                <Box key={`room-count-item-${index}`}>
                    {items.length > 1 && (
                        <Box sx={{ backgroundColor: "#F9FAFF", fontWeight: 700, p: 2 }}>
                            <Typography>{item.name}</Typography>
                        </Box>
                    )}
                    <Box sx={{ pt: 4 }}>
                        {item.skus.map((sku: any, skuIndex: number) => (
                            <Box sx={{ pb: 4 }} key={`room-count-item-${index}-sku-${skuIndex}`}>
                                <Grid container sx={{ border: 1, borderColor: 'divider', p: 2 }} alignItems={"stretch"}>
                                    <Grid item xs={5} sx={{ backgroundColor: '#F9FAFF', p: 2, border: 1, borderColor: 'divider', maxWidth: '60%!important' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                            <Box>
                                                <Box component={"img"} src={`${process.env.REACT_APP_CONTENT_URL}/${sku.image}`} alt={sku.name} sx={{ width: '200px' }} />
                                            </Box>
                                            <Box sx={{ pl: 2 }}>
                                                <Box sx={{display: 'flex', minHeight: '100px', alignItems: 'center'}}>
                                                    <Typography sx={{ fontSize: '0.9rem', fontWeight: 600 }}>
                                                        {sku.name}
                                                    </Typography>
                                                </Box>
                                                {sku?.showSpace && 
                                                    <Box sx={{display: 'flex', justifyContent: 'flex-start', alignItems:'center', cursor: 'pointer'}} onClick={(event) => handleExpandSpaceDetails(sku.id, event)}>
                                                        <ErrorOutlineIcon sx={{color: 'primary.main', transform: 'rotate(180deg)', fontSize: '1.1rem', marginRight: '5px'}}/>
                                                        <Typography sx={{ fontSize: "14px", color: "primary.main" }}>Details</Typography>
                                                    </Box>}

                                            </Box>
                                        </Box>
                                    </Grid>

                                    <Box sx={{display:'flex', flexDirection:'column', flexBasis: '58%',  justifyContent: 'flex-start'}}>
                                        <Box sx={{display:'flex', flexDirection:'row'}}>
                                            {!(sku.typeHide) && 
                                            <Grid item xs={4.3} sx={{minWidth: "184px", flexBasis: '30%'}}>
                                                <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', p: 2,  width:  "100%" }}>
                                                    <Box sx={{ width: '100%' }}>
                                                        <Box>
                                                            <InputLabel id="demo-simple-select-helper-label" required={true} sx={{ pb: 1 }}>
                                                                Worktop Finish
                                                            </InputLabel>
                                                        </Box>
                                                        <Box>
                                                            <FormControl fullWidth>
                                                                <Select
                                                                    size={"small"}
                                                                    id={`demo-simple-select${sku.id}`}
                                                                    labelId={`demo-simple-select-label${sku.id}`}
                                                                    name={"roomType"}
                                                                    value={sku.roomType}
                                                                    sx={{ fontSize: "0.8rem" }}
                                                                    onChange={(e) => handleChange(index, skuIndex, e)}
                                                                >
                                                                    <MenuItem value={"none"} sx={{ fontSize: "0.8rem" }}>None</MenuItem>
                                                                    <MenuItem value={"laminate-finish"} sx={{ fontSize: "0.8rem" }}>Laminate Finish</MenuItem>
                                                                    <MenuItem value={"veneer-finish"} sx={{ fontSize: "0.8rem" }}>Veneer Finish</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            }
                                            {!(sku.qtyHide) &&
                                            <Grid item xs={3} sx={{minWidth: "144px", flexBasis: '30%'}}>
                                                <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', p: 2 }}>
                                                    <Box sx={{ width: '100%' }}>
                                                        <Box>
                                                            <InputLabel id="demo-simple-select-helper-label" required={true} sx={{ pb: 1 }}>
                                                                {props.itemCategoryId == 'work_cafe_break_area' ? "No. of People": "Total Qty."}
                                                            </InputLabel>
                                                        </Box>
                                                        <Box>
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    fullWidth
                                                                    size={"small"}
                                                                    type={"number"}
                                                                    variant={"outlined"}
                                                                    className={"inputBox"}
                                                                    onWheel={(e: any) =>  e.target.blur()}
                                                                    name="quantity"
                                                                    value={sku.quantity}
                                                                    onChange={(e) => handleChange(index, skuIndex, e)}
                                                                    inputProps={{
                                                                        pattern: "^[0-9,]*$"
                                                                    }}
                                                                    InputProps={{
                                                                        style: {
                                                                            borderRadius: "5px",
                                                                            borderColor: "primary.light",
                                                                            fontSize: "0.8rem!important",
                                                                        },
                                                                        inputProps: { min: 0 }
                                                                    }}
                                                                />
                                                            </FormControl>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            }
                                            {!(sku.countHide) &&
                                            <Grid item xs={2.7} sx={{minWidth: "144px", flexBasis: '30%'}}>
                                                <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', p: 2 }}>
                                                    <Box sx={{ width: '100%' }}>
                                                        <Box>
                                                            <InputLabel id="demo-simple-select-helper-label" required={true} sx={{ pb: 1 }}>
                                                                {props.itemCategoryId == 'open_collaboration_space' ? "Count": "Room Count"}
                                                            </InputLabel>
                                                        </Box>
                                                        <Box>
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    fullWidth
                                                                    size={"small"}
                                                                    type={"number"}
                                                                    variant={"outlined"}
                                                                    className={"inputBox"}
                                                                    name="roomCount"
                                                                    value={sku.roomCount}
                                                                    disabled={sku?.disableOn ? true : false}
                                                                    onChange={(e) => handleChange(index, skuIndex, e)}
                                                                    InputProps={{
                                                                        style: {
                                                                            borderRadius: "5px",
                                                                            borderColor: "primary.light",
                                                                            fontSize: "0.8rem",
                                                                        },
                                                                        inputProps: { min: 0 }
                                                                    }}
                                                                    onWheel={(e: any) =>  e.target.blur()}
                                                                />
                                                            </FormControl>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            }
                                        </Box>
                                        {showImpact && <Box sx={{display:'flex', flexDirection:'row', justifyContent: 'end', height: '50%'}}>
                                            {(sku.categoryMap) &&
                                            <Grid item xs={10}>
                                                <Box sx={{ height: '100%', display: 'flex', padding: '0 16px', alignItems: 'flex-end' }}>
                                                    <Box sx={{ width: '100%' }}>
                                                        <Box>
                                                            <InputLabel id="demo-simple-select-helper-label" 
                                                                    sx={{ 
                                                                        pb: 1, 
                                                                        padding: '7px 0 0', 
                                                                        color: '#000', 
                                                                        fontWeight: '500', 
                                                                        fontSize:'0.75rem', 
                                                                        textAlign:  'center',
                                                                        minWidth: '90px',
                                                                    }}
                                                                    >
                                                                {'View Impact: '}
                                                            </InputLabel>
                                                        </Box>
                                                    </Box>
                                                {sku.categoryMap.map((mapItem: any, mapIndex: number) => (
                                                    <Box sx={{ width: '100%' }}>
                                                        <Box>
                                                            <InputLabel id={`${sku.id+"-"+mapItem.category}`} 
                                                                    sx={{ 
                                                                        pb: 1, 
                                                                        padding: '7px 10px 0', 
                                                                        borderRadius:'7px 7px 0 0', 
                                                                        backgroundColor: currentSelected == sku.id+"-"+mapItem.category ? '#f3f5fd' : '#FFF', 
                                                                        color: mapItem.items.length > 0 ? '#3854E4': '#c3cbf7', 
                                                                        fontWeight: '500', 
                                                                        fontSize:'0.75rem', 
                                                                        textAlign:  'center',
                                                                        minWidth: '90px',
                                                                        cursor: mapItem.items.length > 0 ? 'pointer' : 'default' 
                                                                    }}
                                                                    onClick={(event) => handleCategoryClick(mapItem, sku.id,  event)}
                                                                    >
                                                                {mapItem.category+' ('+ mapItem.items.length+')'}
                                                            </InputLabel>
                                                        </Box>
                                                    </Box>
                                                ))}
                                                </Box>
                                            </Grid>
                                            }
                                        </Box>}

                                        {showImpact && categoryOptions.length > 0 && <Popper
                                            open={openCategory}
                                            anchorEl={anchorElCategory}
                                            placement="bottom-start"
                                            disablePortal={true}
                                            modifiers={[
                                                {
                                                    name: 'offset',
                                                    options: {
                                                        offset: [0, 1],
                                                    },
                                                },
                                            ]}
                                            sx={{zIndex: "1", width: '20ch'}}
                                        >
                                            <ClickAwayListener onClickAway={handleCategoryClose}>
                                                <Paper sx={{ paddingTop: '5px', paddingBottom: '5px', border: "1px solid #d5d5d5", overflowY: 'auto', boxShadow: 'none' }}>
                                                    {categoryOptions.map((option:any) => (
                                                          <MenuItem key={option} selected={option === 'Pyxis'} sx={{fontSize: '0.7rem'}}>
                                                            {option}
                                                          </MenuItem>
                                                        ))}
                                                </Paper>
                                            </ClickAwayListener>
                                        </Popper>}
                                    </Box>

                                    <Grid container>
                                    </Grid>
                                    <Popper
                                        open={expandedSpaceDetails[sku.id]}
                                        anchorEl={anchorEl}
                                            placement="bottom-start"
                                            disablePortal={true}
                                            modifiers={[
                                                {
                                                    name: 'offset',
                                                    options: {
                                                        offset: [0, 10],
                                                    },
                                                },
                                            ]}
                                            sx={{zIndex: "1"}}
                                        >
                                        <ClickAwayListener onClickAway={handleClickAway}>
                                            <Paper sx={{ p: 2, width: 300, height: "auto", maxHeight: 300, border: "1px solid #d5d5d5", overflowY: 'auto' }}>
                                                {spaceDetailsConfig.map((field) => {
                                                    return (
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={5} key={field} sx={{ mb: 2, display: 'flex' }}>
                                                                <Typography sx={{ marginBottom: 1, fontSize: '12px', fontWeight: 600 }}>{field == "Closed Area (Sqm.)" ? "Area (Sqm.)" : field }</Typography>
                                                                
                                                            </Grid>
                                                            <Grid item xs={7}>
                                                                <TextField
                                                                        fullWidth
                                                                        size={"small"}
                                                                        type={"number"}
                                                                        variant={"outlined"}
                                                                        name={field}
                                                                        onWheel={(e: any) =>  e.target.blur()}
                                                                        value={inputValues[sku.id]?.[camelCase(field)] || ""}
                                                                        disabled={sku?.disableOn ? true : false}
                                                                        onChange={(e) => handleInputChange(sku.id, field, e)}
                                                                        InputProps={{
                                                                            style: {
                                                                                fontSize: "0.8rem",
                                                                                borderRadius: "5px",
                                                                                height: '24px',
                                                                                borderColor: "primary.light",
                                                                            },
                                                                        }}
                                                                    />
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                })}
                                            </Paper>
                                        </ClickAwayListener>
                                    </Popper>
                                </Grid>
                            </Box>
                        ))}
                    </Box>
                </Box>
            ))}
            <Box sx={{ display: 'flex', justifyContent: 'right', py: 2 }}>
                <Button variant={"contained"} sx={{ mr: 2 }} disabled={(saveEnabled) ? false : true} type="submit">Save</Button>
                <Button variant={"outlined"} disabled={((props.remainingIndexes.length > 0)) ? true : false} onClick={handleContinue}>Submit & Continue</Button>
            </Box>
        </Box>
    );
});

export default RoomDataItems;

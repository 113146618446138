import {Box, Button, Modal, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {getStyle} from "../../../utils/ModalUtils";
import PageHeading from "../../page-heading/PageHeading";
import CloseIcon from "@mui/icons-material/Close";
import Loader from "../../Loader";
import {loadEventDetails} from "../../../modules/project/event/features/actions";
import {api} from "../../../utils/Axios";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import ListPage from "../../list-page/ListPage";
import {pageConfig} from "../../../modules/project/event/config/eventDetails";
import BreakBidsContent from "../../../modules/project/event/BreakBidsContent";
import EventBreakBidsPopup from "../../../modules/project/event/EventBreakBidsPopup";
import SelectEventRcVendor from "../../event/SelectEventRcVendor";
import ConfirmPopupAction from "../../common/ConfirmPopupAction";
import ProjectDetailsRow from "../../ProjectDetailsRow";
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import CreateEventPopup from "./CreateEventPopup";
import EventDetailsRow from "./EventDetailsRow";
import EventVendorDetails from "./EventVendorDetails";
import {hasAccess} from "../../../utils/Access";
import EditEventDatePopup from "./EditEventDatePopup";

const EventDetailsPopup = (props: any) => {
    const style = getStyle(100, 85, 0, 0);
    const [openPopup, setOpenPopup] = useState(false);
    const [activePackage, setActivePackage] = useState<any>({});
    const [isLoading, setIsLoading] = useState(false);
    const [color, setColor] = useState("");
    const [openRevokeConfirm, setOpenRevokeConfirm] = useState(false);

    const rowsData: any = useAppSelector((state) => state.events.eventDetails) || {};

    const [selectedItem, setSelectedItem] = useState({});

    const [showRCVendorPopup, setShowRCVendorPopup] = useState(false);
    const [showBidsPopup, setShowBidsPopup] = useState(false);

    const [size, setSize] = useState(10);
    const [totalElements, setTotalElements] = useState(1);
    const [page, pageChange] = useState(0);
    const [sortColumn, setSortColumn] = useState("id");
    const [sortDirection, setSortDirection] = useState<any>("desc");
    const [headerButtons, setHeaderButtons] = useState<any>([]);
    const [openPublishConfirmation, setOpenPublishConfirmation] = useState(false);
    const [openEditEventDate, setOpenEditEventDate] = useState(false);
    const [openEditEvent, setOpenEditEvent] = useState(false);

    const dispatch = useAppDispatch();

    const handleClose = () => {
        setOpenPopup(false);
        props.handleCallBack({event: 'close'});
    }

    const handlePagination = (newPagination: any) => {
        setSize(newPagination.pageSize);
        pageChange(newPagination.page);
    }

    const handlePopupCallBack = (data: any) => {
        setShowBidsPopup(false);
        if (data.event == 'save') {
            acceptBid();
        }
    }

    const handleSort = (sortModel: any) => {
        if (sortModel && sortModel.field) {
            setSortColumn(sortModel.field);
            setSortDirection(sortModel.sort);
            pageChange(0);
        }
    };

    const handleRCVendorPopupCallBack = (eventData: any) => {
        if (eventData.event == "save") {
            refreshPage();
        }
        setShowRCVendorPopup(false);
    }

    const handleCallback = (selectedRow: any, buttonData: any) => {
        setSelectedItem(selectedRow);
        if (buttonData.type == 'select-rc') {
            setShowRCVendorPopup(true);
        }
        if (buttonData.type == 'revoke') {
            setOpenRevokeConfirm(true);
        }
    }

    const handleConfirmPublish = (flag: any) => {
        if (flag == 1) {
            publishEvent();
        }
        setOpenPublishConfirmation(false);
    }

    const handleConfirmModal = (flag: any) => {
        if (flag == 1) {
            revokePackageItem(selectedItem);
        }
        setOpenRevokeConfirm(false);
    }

    const handleEditEventCallback = (data: any) => {
        if (data.hasOwnProperty("prPackage")) {
            refreshPage();
        }
        setOpenEditEvent(false);
    }

    const handleEditEventDateCallback = (data: any) => {
        if(data.event == "update") {
            refreshPage();
        }
        setOpenEditEventDate(false);
    }

    const publishEvent = () => {
        setIsLoading(true);
        api.get(
            `/procurement/event/publish/${rowsData.id}`)
            .then((response) => {
                return response.data;
            })
            .then((res) => {
                if (res) {
                    refreshPage();
                    props.refreshPage();
                }
            })
            .catch((ex) => {
                console.log(ex);
            }).finally(() => {
            setIsLoading(false);
        });
    }

    const revokePackageItem = (item: any) => {
        setIsLoading(true);
        api.get(
            `/procurement/package/revoke-item/${item.id}`)
            .then((response) => {
                return response.data;
            })
            .then((res) => {
                refreshPage();
                props.refreshPage();
            })
            .catch((ex) => {
                console.log(ex);
            }).finally(() => {
            setIsLoading(false);
        });
    }

    const acceptBid = () => {
        refreshPage();
        props.acceptBid();
    }

    const refreshPage = () => {
        dispatch(loadEventDetails(props.event.id)).then((_response: any) => {
            setTotalElements(10);
        });
    }

    const handleHeaderButtonClick = (data: any) => {
        if (data === 'edit') {
            setOpenEditEvent(true);
        }

        if (data == 'publish') {
            setOpenPublishConfirmation(true);
        }

        if (data == 'edit-event-date') {
            setOpenEditEventDate(true);
        }
    }

    const eventPackageDetails =
        rowsData?.eventPackages?.map((item: any, index: number) => ({
            ...item,
            id: item.id, packageStatus: item?.status || index,
        })) || [];

    const eventVendorsList =
        rowsData?.eventVendors?.map((item: any, index: number) => ({
            ...item,
            id: item.id || index,
        })) || [];

    useEffect(() => {
        const buttons: any[] = [];
        if (Object.keys(rowsData).length > 0) {
            let colorCode = '253, 182, 122';
            let name = rowsData.status;

            if (name == 'CREATED') {
                rowsData.eventPackages.map((packageObj: any) => {
                    if (packageObj.status == "EVENT") {
                        setActivePackage(packageObj);
                    }
                });
                colorCode = '223, 149, 113';
                buttons.push({
                    id: 'edit', label: 'Edit', startIcon: <ModeEditOutlineOutlinedIcon/>, permissions: ["edit-event"]
                });
                buttons.push({
                    id: 'publish', label: 'Publish Event', variant: 'contained', permissions: ["edit-event"]
                });
            }

            if(name == "PUBLISHED") {
                let today = new Date();
                let endDate = new Date(rowsData.endDate);
                if(endDate > today) {
                    buttons.push({
                        id: 'edit-event-date', label: 'Update Event Date', variant: 'contained', permissions: ["edit-event"]
                    });
                }
            }

            if (name == 'PR') {
                colorCode = '223, 112, 113';
            }

            if (name == 'EVENT') {
                colorCode = '223, 149, 149';
            }

            if (name == 'CLOSED') {
                colorCode = '55, 214, 112';
            }
            setColor(colorCode);
        }
        setHeaderButtons(buttons);
    }, [rowsData]);

    useEffect(() => {
        if (props.openPopup) {
            refreshPage();
            setOpenPopup(true);
        }
    }, [props.openPopup]);

    return (
        <>
            <Modal
                disableScrollLock={false}
                open={openPopup}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                sx={{"& > .MuiBackdrop-root": {backdropFilter: "blur(0px)", backgroundColor: "transparent"}}}
            >
                <Box sx={{
                    position: "absolute" as "absolute",
                    bottom: 0,
                    width: "100%",
                    height: '90%',
                    border: 0,
                    backgroundColor: "background.paper",
                    boxShadow: 1,
                    '&:focus-visible': {outline: 'none'}
                }}>
                    <Box sx={{position: "relative", height: "100%"}}>
                        <Box sx={style.header}>
                            <Box sx={style.headerContainer}>
                                <Box sx={{width: '95%'}}>
                                    <PageHeading
                                        title={`Event Details: ${rowsData.name}`}
                                        showButtons={true}
                                        buttons={headerButtons}
                                        handleHeaderButtonClick={handleHeaderButtonClick}
                                    />
                                </Box>
                                <Box onClick={handleClose}
                                     sx={{display: 'flex', justifyContent: 'right', width: '5%', cursor: 'pointer'}}>
                                    <CloseIcon/>
                                </Box>
                            </Box>
                        </Box>
                        <Box className={"modal-body"} sx={style.modalFullBody}>
                            <Box sx={style.bodyContainer}>
                                {isLoading &&
                                    <Loader/>
                                }
                                <Box mb={4}>
                                    <ProjectDetailsRow color={color} project={props.project}/>
                                </Box>
                                <EventDetailsRow eventDetails={rowsData} color={color}/>

                                <EventVendorDetails eventDetails={rowsData} eventVendors={eventVendorsList}/>

                                {eventPackageDetails.map(
                                    (eventPackage: any, eventPackageIndex: number) => {
                                        const items = eventPackage.items.map((item: any) => {
                                            let cogsPrice = item.cogsPrice + item?.domainCogsCharges?.total;
                                            let gsv = item.gsv + item?.domainGsvCharges?.total;

                                            const currentItem = {
                                                ...item,
                                                country: rowsData.project.country,
                                                gsv: gsv,
                                                cogsPrice: cogsPrice
                                            };
                                            currentItem.packageStatus = eventPackage?.packageStatus;
                                            return currentItem;
                                        });

                                        return (
                                            <Box key={"event-package-" + eventPackageIndex}>
                                                <Box sx={{mt: 4}}>
                                                    <Typography variant={"h4"}
                                                                sx={{pb: 1}}>{"Package: " + eventPackage?.name}</Typography>
                                                    <ListPage
                                                        rows={items}
                                                        columns={rowsData.status != "CLOSED" ? pageConfig.packageDetails : pageConfig.closedEventPackageDetails}
                                                        page={page}
                                                        size={size}
                                                        totalElements={totalElements}
                                                        sortColumn={sortColumn}
                                                        sortDirection={sortDirection}
                                                        handlePagination={handlePagination}
                                                        handleSort={handleSort}
                                                        handleCallBack={handleCallback}
                                                        hideFooter
                                                        rowHeight={100}
                                                    />
                                                </Box>
                                            </Box>
                                        );
                                    }
                                )}

                                {(rowsData.sealedBid && rowsData.status == "BID_CLOSED") || !rowsData.sealedBid && (
                                    <Box my={4}>
                                        <Box
                                            display={"flex"}
                                            justifyContent={"space-between"}
                                            alignItems={"center"}
                                            sx={{pb: 1}}
                                        >
                                            <Typography variant={"h4"} sx={{pb: 1}}>Break Bids</Typography>
                                            {rowsData &&
                                                rowsData.eventVendors.length > 1 &&
                                                rowsData.status != "CLOSED" &&
                                                hasAccess(["edit-event"]) &&
                                                Number(rowsData?.totalResponse) > 0 && (
                                                    <Button
                                                        variant={"outlined"}
                                                        onClick={() => setShowBidsPopup(true)}
                                                    >
                                                        Break Bids
                                                    </Button>
                                                )}
                                        </Box>
                                        <BreakBidsContent
                                            showUpdatedCharges={props.showUpdatedCharges}
                                            eventStatus={rowsData.status}
                                            eventVendorsList={eventVendorsList}
                                            eventPackageDetails={eventPackageDetails}
                                            categories={props.categories}
                                            refreshPage={refreshPage}
                                            acceptBid={acceptBid}
                                        />
                                        {showBidsPopup && (
                                            <EventBreakBidsPopup
                                                categories={props.categories}
                                                showUpdatedCharges={props.showUpdatedCharges}
                                                showBidsPopup={showBidsPopup}
                                                handlePopupCallBack={handlePopupCallBack}
                                                eventVendorsList={eventVendorsList}
                                                eventPackageDetails={eventPackageDetails}
                                                event={rowsData}
                                            />
                                        )}
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Modal>
            {showRCVendorPopup &&
                <SelectEventRcVendor
                    project={props.project}
                    selectedItem={selectedItem}
                    openPopup={showRCVendorPopup}
                    handleRCVendorPopupCallBack={handleRCVendorPopupCallBack}
                />
            }

            {openRevokeConfirm &&
                <ConfirmPopupAction openConfirmBox={openRevokeConfirm} handleButtonClick={handleConfirmModal}
                                    confirmText={"Do you want to revoke item from event?"}/>
            }

            {openPublishConfirmation &&
                <ConfirmPopupAction openConfirmBox={openPublishConfirmation} handleButtonClick={handleConfirmPublish}
                                    confirmText={"Are you sure you want to publish the changes?"}/>
            }

            {openEditEventDate &&
                <EditEventDatePopup eventDetails={rowsData} openPopup={openEditEventDate} handlePopupCallback={handleEditEventDateCallback}/>
            }

            {openEditEvent && (
                <CreateEventPopup
                    handlePopupCallBack={handleEditEventCallback}
                    openPopup={openEditEvent}
                    package={activePackage}
                    project={props.project}
                    event={rowsData}
                />
            )}
        </>
    )
}
export default EventDetailsPopup;
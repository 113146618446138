import {Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import React from "react";

const ProjectDetailsRow = (props: any) => {
    return (
        <Box>
            <Typography variant={"h4"} sx={{pb: 1}}>Project Details</Typography>
            <TableContainer
                component={"div"}
                sx={{
                    border: "1px solid",
                    borderColor: "divider",
                    borderBottom: 0,
                }}
            >
                <Table
                    sx={{minWidth: 650}}
                    aria-label="project details table"
                >
                    <TableHead sx={{backgroundColor: "info.contrastText"}}>
                        <TableRow>
                            <TableCell sx={{fontWeight: 600}}>Project Name</TableCell>
                            <TableCell sx={{fontWeight: 600}}>Project Studio</TableCell>
                            <TableCell sx={{fontWeight: 600}}>Location</TableCell>
                            <TableCell sx={{fontWeight: 600}}>Carpet Area</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell sx={{textTransform: "capitalize"}}>
                                {props?.project?.name || "NA"}
                            </TableCell>
                            <TableCell sx={{textTransform: "capitalize"}}>
                                {props?.project?.projectStudio || "NA"}
                            </TableCell>
                            <TableCell sx={{textTransform: "capitalize"}}>
                                {props?.project?.location || "NA"}
                            </TableCell>
                            <TableCell sx={{textTransform: "capitalize"}}>
                                {props?.project?.carpetArea + " Sqft" || "NA"}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

export default ProjectDetailsRow;
import {getStyleWithTopModified} from "../../../utils/ModalUtils";
import React, {useEffect, useState} from "react";
import {Box, Button, Grid, InputLabel, Modal, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomDateTimePicker from "../../common/CustomDateTimePicker";
import dayjs, {Dayjs} from "dayjs";
import {openSnackbar} from "../../common/features/snackbarSlice";
import {ERROR_COLOR, SUCCESS_COLOR} from "../../../constants";
import {useAppDispatch} from "../../../app/hooks";
import {api} from "../../../utils/Axios";

const EditEventDatePopup = (props: any) => {
    const style = getStyleWithTopModified(40, 30, 15, 15, 40);
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [endDateValue, setEndDateValue] = useState<any>(dayjs(props.eventDetails.endDate));
    const startDateValue = new Date(props.eventDetails.startDate);
    const dispatch = useAppDispatch();

    const handleClose = () => {
        setOpen(false);
        props.handlePopupCallback({event: 'close'});
    }

    const saveForm = (event: any) => {
        event.preventDefault();
        if (endDateValue && startDateValue && endDateValue.isBefore(startDateValue, "day")) {
            dispatch(
                openSnackbar({
                    message: "End date cannot be before start date",
                    backgroundColor: ERROR_COLOR,
                })
            );
            return;
        }
        setIsLoading(true);
        api.get(`/procurement/event/update-event-date/${props.eventDetails.id}/${endDateValue}`)
            .then((response) => {
                return response.data;
            })
            .then((res) => {
                if(res) {
                    dispatch(
                        openSnackbar({
                            message: "Event details updated successfully",
                            backgroundColor: SUCCESS_COLOR,
                        })
                    );
                    props.handlePopupCallback({event: 'save', data: "update"});
                }
            })
            .catch((ex) => {
                console.log(ex);
            }).finally(() => {
            setIsLoading(false);
        });
    }

    const handleEndDateChange = (date: Dayjs | null) => {
        if (date && startDateValue && date.isBefore(startDateValue, "day")) {
            dispatch(
                openSnackbar({
                    message: "End date cannot be before start date",
                    backgroundColor: ERROR_COLOR,
                })
            );
            return;
        }
        setEndDateValue(date);
    }

    useEffect(() => {
        if (props.openPopup === true) {
            setOpen(true);
        }
    }, [props.openPopup]);
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <Box sx={style.main}>
                <Box sx={{position: "relative", height: "100%"}} component={"form"} onSubmit={saveForm}>
                    <Box sx={style.header}>
                        <Box sx={style.headerContainer}>
                            <Box>
                                <Typography variant={"h5"} sx={{fontWeight: 700}}>
                                    Edit Event Date
                                </Typography>
                            </Box>
                            <Box sx={{ml: "auto"}}>
                                <CloseIcon sx={{cursor: "pointer"}} onClick={handleClose}/>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={"modal-body"} sx={style.body}>
                        <Box sx={style.bodyContainer}>
                            <Box sx={{width: '70%', py: 1}}>
                                <InputLabel required={true} sx={{pb: 0.5, fontSize: "0.8rem"}}>
                                    Event End Date
                                </InputLabel>
                                <Box>
                                    <CustomDateTimePicker minDate={startDateValue} label="" value={endDateValue} onChange={handleEndDateChange}/>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={style.footer}>
                        <Grid container sx={{px: 2, height: "100%"}} className={"center-aligned"}>
                            <Grid item xs/>
                            <Grid item>
                                <Box>
                                    <Button variant={"text"} sx={{mr: 2, fontSize: "0.8rem"}} onClick={handleClose}>
                                        Cancel
                                    </Button>
                                    <Button variant={"contained"} sx={{fontSize: "0.8rem"}} type={"submit"}>
                                        Save
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}

export default EditEventDatePopup;
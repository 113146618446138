import React, {SyntheticEvent, useEffect, useState} from "react";
import Loader from "../Loader";
import {Box, Button, Typography} from "@mui/material";
import {CustomTabPanel} from "../../utils/TabsUtils";
import SelectItemCategory from "./SelectItemCategory";
import CategoryTab from "../tabs/CategoryTab";
import SubCategoryTab from "../tabs/SubCategoryTab";
import CreatePackageDrawer from "./CreatePackageDrawer";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {loadPackageItems} from "./features/actions";
import {resetPackageItems} from "./features/packageSlice";
import SelectAllPackageItems from "./SelectAllPackageItems";
import ConfirmPopupAction from "../common/ConfirmPopupAction";
import {hasAccess} from "../../utils/Access";

const SelectPackage = (props: any) => {
    const {boq, selectedPackage} = props;
    const dispatch = useAppDispatch();
    const isLoading = useAppSelector((state: any) => state.package.loading);
    const [selectedCategory, setSelectedCategory] = useState<any>({});

    const [hideCreatedSkus, setHideCreatedSkus] = useState(false);
    const [categoryTabValue, setCategoryTabValue] = useState('');
    const [subCategoryTabValue, setSubCategoryTabValue] = useState('');

    const [selectedRows, setSelectedRows] = useState<any>({});
    const [selectedRowsDrawer, setSelectedRowsDrawer] = useState<any>({});
    const [packageCategories, setPackageCategories] = useState<any>([]);

    const packageItems = useAppSelector((state: any) => state.package.packageItems);
    const [categoryCode, setCategoryCode] = useState<string>("");
    const [assignVendorConfirmationPopup, setAssignVendorConfirmationPopup] = useState(false);

    const toggleSkuVisibility = () => {
        setHideCreatedSkus(!hideCreatedSkus);
    }
    const handleAssignVendor = () => {
        setAssignVendorConfirmationPopup(true);
    }

    const handleConfirmModal = (flag: any) => {
        if (flag == 1) {
            props.assignVendorPackage();
        }
        setAssignVendorConfirmationPopup(false);
    }

    const handleDrawerCallback = () => {
        props.handlePendingCallback();
    }

    const handleAllTabRowSelection = (rows: any) => {
        let allRows: any = {};
        rows.map((row: any) => {
            let data: any[] = [];

            if (Object.keys(allRows).length > 0 && allRows.hasOwnProperty(row.materialSubCategoryId)) {
                data = allRows[row.materialSubCategoryId];
            }
            data.push(row.id);
            allRows[row.materialSubCategoryId] = data;
        });
        setSelectedRows(allRows);
    }

    const handleRowSelection = (rows: any, subCategoryId: string) => {
        const selectedIds = rows.map((row: any) => row.id);

        setSelectedRows((prev: any) => {
            const updated = {...prev};
            if (rows.length === 0) {
                delete updated[subCategoryId];
            } else {
                updated[subCategoryId] = selectedIds;
            }
            return updated;
        });
    }

    const handleCategoryTabChange = (event: SyntheticEvent, newValue: string) => {
        setCategoryTabValue(newValue);
    };

    const handleSubCategoryTabChange = (event: SyntheticEvent, newValue: string) => {
        setSubCategoryTabValue(newValue);
    };

    useEffect(() => {
        let allRows: any = {};
        if (Object.keys(selectedRows).length > 0) {
            for (let key in selectedRows) {
                const rows = selectedRows[key];
                const selectedIdsForDrawer = rows.map((row: any) => {
                    let data = {id: row, quantity: "", bufferQuantity: ""};
                    if (packageItems != null) {
                        packageItems.map((selectedPackageItem: any) => {
                            if (row == selectedPackageItem.itemDetail.id) {
                                data = {
                                    id: row,
                                    quantity: selectedPackageItem.quantity,
                                    bufferQuantity: selectedPackageItem.bufferQuantity
                                };
                            }
                        });
                    }
                    return data;
                });
                allRows[key] = selectedIdsForDrawer;
            }
        }
        setSelectedRowsDrawer(allRows);
    }, [selectedRows, packageItems]);

    useEffect(() => {
        if (boq.hasOwnProperty("id") && boq?.id != null) {
            const updatedCategories = props.categories.map((category: any) => {
                const subCategories = category.subCategories.filter((subCategory: any) => {
                    return boq?.boqItems.some((boqItem: any) => boqItem.materialSubCategoryId === subCategory.id);
                });
                return {...category, subCategories};
            });

            setPackageCategories(updatedCategories);
        }
        setCategoryTabValue("summary");
    }, [boq]);

    useEffect(() => {
        dispatch(resetPackageItems());
        if (Object.keys(selectedPackage).length > 0) {
            dispatch(loadPackageItems(selectedPackage.id));
        }
    }, [selectedPackage]);

    useEffect(() => {
        setSelectedRows({});
        if (packageItems != null) {
            const updatedRows: any[] = [];

            props.boq.boqItems.map((boqItem: any) => {
                boqItem.boqSkus.map((boqSku: any) => {
                    packageItems.map((selectedPackageItem: any) => {
                        if (boqSku.id == selectedPackageItem.itemDetail.id) {
                            let selectedIds: any[] = [];
                            if (updatedRows[boqItem.materialSubCategoryId] != undefined && updatedRows[boqItem.materialSubCategoryId].length > 0) {
                                selectedIds = updatedRows[boqItem.materialSubCategoryId];
                            }

                            if (!selectedIds.includes(boqSku.id)) {
                                selectedIds.push(boqSku.id);
                            }

                            updatedRows[boqItem.materialSubCategoryId] = selectedIds;
                        }
                    });
                });
            });

            setSelectedRows(updatedRows);
            // setSelectedRowsDrawer(updatedRowsDrawer);
        }
    }, [packageItems]);

    useEffect(() => {
        if (categoryTabValue != "") {
            if (categoryTabValue != "summary") {
                packageCategories.map((category: any) => {
                    if (category.id == categoryTabValue) {
                        setSelectedCategory(category);
                        setCategoryCode(category.code);
                        if (category.subCategories.length > 0) {
                            setSubCategoryTabValue(category.subCategories[0].id);
                        }
                    }
                });
            } else {
                setSubCategoryTabValue("");
            }
        }
    }, [categoryTabValue]);

    useEffect(() => {

    }, [props.revision]);

    if (isLoading) {
        return <Loader/>
    }

    return (
        <>
            {props.vendorSelectionInProgress ? (
                <Box>
                    <Box sx={{position: "relative", height: "100%"}}>
                        <Box sx={{minHeight: '200px', width: '100%'}}
                             className={"center-aligned vertically-center-aligned"}>
                            <Box sx={{width: 'inherit', textAlign: 'center'}}>
                                <Box sx={{p: 1}}>
                                    <Typography sx={{color: '#A29F9F', fontSize: '14px'}}>Vendor Selection is in
                                        progress</Typography>
                                </Box>
                                <Box sx={{p: 1}}>
                                    <Typography sx={{color: '#333333', fontSize: '14px', pb: 2}}>
                                        Please wait or
                                    </Typography>
                                    <Button variant={"contained"} onClick={props.handleRefresh}>Refresh</Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            ) : (
                <>
                    <Box sx={{position: 'relative'}}>
                        {categoryTabValue != "" &&
                            <CategoryTab
                                includeSummaryTab={true}
                                showSummaryIcon={false}
                                summaryTabName={"All"}
                                categories={packageCategories}
                                categoryTabValue={categoryTabValue}
                                handleCategoryTabChange={handleCategoryTabChange}
                            />
                        }
                        <Box sx={{position: 'absolute', top: '2px', zIndex: 99, right: '0px'}}>
                            <Button variant={"text"} sx={{mr: 2}}
                                    onClick={toggleSkuVisibility}>{hideCreatedSkus ? "Show All Skus" : "Hide Package Created Skus"}</Button>
                            {hasAccess(["edit-package"]) && props.revision.vendorAssigned && !boq.vendorRuleExecuted && (
                                <Button variant={"contained"} onClick={handleAssignVendor}>Execute Vendor Rule</Button>
                            )}
                        </Box>

                        {(selectedCategory.hasOwnProperty("id") && selectedCategory.subCategories.length > 0 && subCategoryTabValue != '') &&
                            <SubCategoryTab
                                subCategoryTabValue={subCategoryTabValue}
                                subCategories={selectedCategory?.subCategories}
                                categoryTabValue={categoryTabValue}
                                handleSubCategoryTabChange={handleSubCategoryTabChange}
                            />
                        }

                        {subCategoryTabValue !== "" && categoryTabValue !== "summary" && (
                            <CustomTabPanel value={subCategoryTabValue} index={subCategoryTabValue}>
                                <Box sx={{pt: 2, pb: 5}}>
                                    <SelectItemCategory
                                        hideCreatedSkus={hideCreatedSkus}
                                        showUpdatedCharges={props.showUpdatedCharges}
                                        categoryCode={categoryCode}
                                        selectedRows={selectedRows[subCategoryTabValue] || []}
                                        subCategoryId={subCategoryTabValue}
                                        boq={boq}
                                        project={props.project}
                                        handleRowSelection={(rows: any) => handleRowSelection(rows, subCategoryTabValue)}
                                    />
                                </Box>
                            </CustomTabPanel>
                        )}

                        {categoryTabValue === "summary" && (
                            <SelectAllPackageItems
                                hideCreatedSkus={hideCreatedSkus}
                                categories={packageCategories}
                                boq={boq}
                                project={props.project}
                                showUpdatedCharges={props.showUpdatedCharges}
                                selectedRows={selectedRows}
                                handleAllTabRowSelection={handleAllTabRowSelection}
                            />
                        )}

                        {Object.keys(selectedRowsDrawer).length > 0 &&
                            <CreatePackageDrawer
                                handleDrawerCallback={handleDrawerCallback}
                                boq={props.boq}
                                selectedPackage={selectedPackage}
                                packageItems={selectedRowsDrawer}
                                onPackageCreated={props.handlePackageCreated} // Add this prop
                            />
                        }
                    </Box>
                    {assignVendorConfirmationPopup &&
                        <ConfirmPopupAction openConfirmBox={assignVendorConfirmationPopup}
                                            handleButtonClick={handleConfirmModal}
                                            confirmText={"Do you want to assign vendor rule to applicable package items?"}/>
                    }
                </>
            )}
        </>
    )
}

export default SelectPackage;

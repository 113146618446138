import {Box, Button, Grid, Typography} from "@mui/material";
import {hasAccess} from "../../utils/Access";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {useNavigate} from "react-router-dom";

const PageHeading = (props: any) => {
    const navigate = useNavigate();

    const handleClick = (data: any) => {
        props.handleHeaderButtonClick(data);
    }
    const goBack = () => {
        navigate(-1);
    }
    return (
        <Box className={props.customClass ? props.customClass : "page-heading"}>
            <Grid container sx={{py: 4}} className={"center-aligned"}>
                <Grid item xs>
                    <Box className={"vertically-center-aligned"}>
                        {props.enableBackButton && (
                            <Box component={"span"} sx={{pr: 1}}>
                                <ArrowBackIcon sx={{color: "common.black", cursor: 'pointer'}} onClick={goBack}/>
                            </Box>

                        )}
                        <Typography variant={"h4"} sx={{textTransform: 'capitalize', display: 'inline'}}>
                            {props.title}
                        </Typography>
                    </Box>
                </Grid>
                {props.showButtons &&
                    <Grid item>
                        {props.buttons.map((buttonData: any, index: number) => {
                            let flag = true;
                            if (buttonData.hasOwnProperty("permissions") && !hasAccess(buttonData.permissions)) {
                                flag = false;
                            }
                            if (flag) {
                                return (
                                    <Box key={"page-header-buttons-" + index} sx={{
                                        display: 'inline',
                                        mr: 2,
                                        '&:last-child': {
                                            mr: 0
                                        }
                                    }}>
                                        <Button
                                            color={buttonData?.color ? buttonData.color : 'primary'}
                                            variant={buttonData?.variant ? buttonData.variant : "outlined"}
                                            onClick={() => handleClick(buttonData.id)}
                                            startIcon={buttonData.startIcon ? buttonData.startIcon : ""}
                                            endIcon={buttonData.endIcon ? buttonData.endIcon : ""}
                                        >
                                            {buttonData.label}
                                        </Button>
                                    </Box>
                                )
                            }
                        })}
                    </Grid>
                }
            </Grid>
        </Box>
    )
}
export default PageHeading;
import {useNavigate, useSearchParams} from "react-router-dom";
import {useEffect} from "react";
import {useAppDispatch} from "../../app/hooks";
import {setAuth} from "../../features/authSlice";

function Oauth2Redirect() {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch()

    useEffect(() => {
        const token = searchParams.get('token');

        if (token) {
            dispatch(setAuth({token}));
            navigate('/');
        } else {
            navigate('/login');
        }
    }, [searchParams, dispatch, navigate]);

    return (<></>);
}

export default Oauth2Redirect;

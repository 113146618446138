import PageHeading from "../page-heading/PageHeading";
import {Box} from "@mui/material";
import BuildForm from "../input-form/BuildForm";

const CreateUserComponent = (props: any) => {
    const onSubmitHandler = (form: any, callback: any) => {
        props.onSubmitHandler(form, callback);
    };

    const updatedFormFields = formFields.map((field) => {
        if (field.name === 'roles') {
            return {
                ...field,
                options:
                    props.roles &&
                    props.roles?.map((role: any) => {
                        return {
                            id: role.id,
                            label: role.name,
                        };
                    }),
            };
        }
        return field;
    });

    return (
        <Box className={'common-space-container'}>
            <PageHeading title={props.pageName} enableBackButton={true}/>
            <Box>
                <Box className={'center-aligned'}>
                    <Box sx={{width: '50%'}}>
                        <BuildForm
                            buttons={[
                                {
                                    type: 'submit',
                                    title: 'Save',
                                    permissions: ['edit-users'],
                                },
                            ]}
                            formArr={updatedFormFields}
                            onSubmit={onSubmitHandler}
                            handleFormChange={(form: any) => ""}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

const formFields = [
    {
        element: 'input',
        label: 'Name',
        placeholder: 'Name',
        name: 'name',
        type: 'text',
        required: true,
    },
    {
        element: 'input',
        label: 'Username',
        placeholder: 'Username',
        name: 'username',
        type: 'text',
        required: true,
    },
    {
        element: 'input',
        label: 'Email',
        placeholder: 'Email',
        name: 'email',
        type: 'text',
        required: true,
    },
    {
        element: 'input',
        label: 'Password',
        placeholder: 'Password',
        name: 'password',
        type: 'password',
        required: true,
    },
    {
        element: 'checkbox',
        label: 'Roles',
        placeholder: 'Select Roles',
        name: 'roles',
        fullWidth: true,
        options: [],
    },
];

export default CreateUserComponent;
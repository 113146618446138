import {InputAdornment} from "@mui/material";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";

const InputBox = (props: any) => {
    const {formField} = props;
    const [fieldValue, setFieldValue] = useState<any>("");
    const handleKeyDown = (e: any, field: any) => {
        if(field.type == "number") {
            if(e.key === "ArrowUp" || e.key === "ArrowDown") {
                e.preventDefault();
            }
        }
        if (field?.format != undefined) {
            if (field.format == 'no-space' && e.keyCode == 32) {
                e.preventDefault();
            }
        }
    }

    const onChange = (e: any) => {
        setFieldValue(e.target.value);
        props.onChangeHandler(e);
    }

    useEffect(() => {
        let val = (props?.valueFields?.hasOwnProperty(formField.name)) ? props.valueFields[formField.name] :  (formField.value != undefined ? formField.value : "");
        setFieldValue(val);
    }, []);
    useEffect(() => {
        if(props?.valueFields?.hasOwnProperty(formField.name) && props?.valueFields[formField.name] != formField) {
            setFieldValue(props?.valueFields[formField.name]);
        }
        //console.log(props.valueFields);
    }, [props.valueFields, props.disableFields])

    return (
        <TextField
            fullWidth
            size={"small"}
            variant={"outlined"}
            className={"inputBox"}
            type={formField?.type}
            id={formField.name}
            onWheel={(e: any) => formField.type == 'number' ? e.target.blur() : ''}
            name={formField.name}
            disabled={(formField?.readonly || (formField?.disableOn && props?.disableFields?.hasOwnProperty(formField.name))) ? true : false}
            placeholder={formField?.placeholder ? formField.placeholder : formField.label}
            required={formField?.required ? formField.required : false}
            //defaultValue={formField.value != undefined ? formField.value : ""}
            value={fieldValue}
            onKeyDown={(e) => handleKeyDown(e, formField)}
            //onChange={(e) => props.onChangeHandler(e)}
            onChange={(e) => onChange(e)}
            inputProps={{
                pattern: formField?.pattern
            }}
            InputProps={{
                style: {
                    borderRadius: "5px",
                    borderColor:
                        'primary.light'
                },
                startAdornment: formField.prefix ?
                    <InputAdornment position="start">{formField.prefix}</InputAdornment> : "",
                inputProps: { min: 0 }
            }}
        />
    )
}

export default InputBox;
import {config} from "./RoomDataConfig";
import {Box, Tab, Tabs, Typography, Button} from "@mui/material";
import {a11yProps, CustomTabPanel} from "../../utils/TabsUtils";
import {SyntheticEvent, useEffect, useState, useRef} from "react";
import RoomDataItems, { ContinueRef } from "./RoomDataItems";
import {api} from "../../utils/Axios";
import ECatalogDialog from "../../components/ecatalog/ECatalogDialog";
import CheckIcon from '@mui/icons-material/Check';

const HeadCountRoomData = (props: any) => {
    const roomCountConfig = config;
    const [itemCategoryTabValue, setItemCategoryTabValue] = useState(roomCountConfig[0].id);
    const [currentRoomConfigIndex, setCurrentRoomConfigIndex] = useState(0);
    const [items, setItems] = useState([]);
    const roomItemCategegories:any[] = props.roomItemCategegories;
    const [open, setOpen] = useState(false);
    const [nextTabValue, setNextTabValue] = useState("");
    const [fieldUpdated, setFieldUpdated] = useState(false);
    const ref = useRef<ContinueRef>(null);

    const [remainingIndexes, setRemainingIndexes] = useState<number[]>(roomCountConfig.map((rData: any, ix: number) => {
                                                                                                return ix;
                                                                                            }));

    const handleItemCategoryTabChange = (
        event: SyntheticEvent,
        newValue: string
    ) => {
        setItemCategoryTabValue(newValue);
    };

    const goToNextItemCategory = (curIndex: any) => {
        const filtered = remainingIndexes.filter(a =>
                  a !== curIndex
                );
        
        if(filtered.length) {
            let nextIndex = filtered[0];
            setCurrentRoomConfigIndex(nextIndex);
            setItemCategoryTabValue(roomCountConfig[nextIndex].id);
        }
        //Added to disable next tabs till estimation
        props.handleDisableNextTabs('head-count');
        setRemainingIndexes(filtered);

        //console.log(filtered);
        //console.log(remainingIndexes);

        roomItemCategegories.push(roomCountConfig[curIndex].id);

    }

    const handleItemCatTabChange = (itemCategoryIndex: any) => {
        //setCurrentRoomConfigIndex(itemCategoryIndex);
        //setItemCategoryTabValue(roomCountConfig[itemCategoryIndex].id);

        if(fieldUpdated) {
            setNextTabValue(itemCategoryIndex.toString());
            setOpen(true);
        } else {
            setCurrentRoomConfigIndex(itemCategoryIndex);
            setItemCategoryTabValue(roomCountConfig[itemCategoryIndex].id);
        }
    }
    const updateFieldChange = (value:boolean) => {
        setFieldUpdated(value);
    }

    const handleClose = (value:any, tabValue:any) => {
        setOpen(false);
        if(value === "proceed") {
            setFieldUpdated(false);
            if(tabValue === "proceedToNextTab") {
                goToNextTab();
            } else {
                let itemCategoryIndex = parseInt(tabValue);
                setCurrentRoomConfigIndex(itemCategoryIndex);
                setItemCategoryTabValue(roomCountConfig[itemCategoryIndex].id);
            }
        }
    };

    const goToNextTab = () => {
        if(remainingIndexes.length > 0) {
            alert("Please fill all the data before proceeding to next view");
            return;
        }
        api.post(`/procurement/project/sync-head-count/${props.project.id}?revisionId=${props?.revision?.id}`).then((response) => {
            if (response.status === 200)
                return response.data;
            return {};
        }).then(res => {
            props.proceedTabChange('e-catalog');
            //props.proceedTabChange('estimation');
        }).catch(ex => {
            console.log(ex);
        });
    }
    
    const checkSaveAndCountinue = (event:SyntheticEvent) => {
        //console.log("checkSaveAndCountinue");
        ref.current?.saveHeadCount(event);
    }

    const handleContinue = () => {
        if(fieldUpdated && false) {
            console.log("Field state change found ..");
            setNextTabValue("proceedToNextTab");
            setOpen(true);
        } else {
            console.log("Will continbue. to E-Catalog tab");
            goToNextTab();
        }
    }

    useEffect(() => {
        let roomIndexes:number[] = [];
        let nextRoomId = "";
        //console.log(roomItemCategegories);
        roomCountConfig.map((roomData: any, index: number) => {
            //console.log(roomData.id);
            if (!props.roomItemCategegories.includes(roomData.id)) {
                roomIndexes.push(index);
                if(nextRoomId === "") {
                    nextRoomId = roomData.id;
                    setCurrentRoomConfigIndex(index);
                }
            }
        });
        //console.log("Final remaining indexes on load  ==");
        //console.log(roomIndexes);
        setRemainingIndexes(roomIndexes);
        if(nextRoomId !== "") {
            setItemCategoryTabValue(nextRoomId);
        }

    }, [props.roomItemCategegories]);

    useEffect(() => {
        console.log("Project Data:: ", props.project)
        let builtUpArea = props.project?.projectDetails?.builtUpArea;
        let headCount = props.project?.projectDetails?.totalHeadCount;
        let headCountFromClient = props.project?.projectDetails?.headCountFromClient;
        let carpetArea = props.project?.carpetArea;
        if(headCountFromClient == "NO" || headCount == 0) {
            headCount = carpetArea/100;
        }

        console.log("builtUpArea --", builtUpArea);

        setItems([]);
        roomCountConfig.map((roomData: any, index: number) => {
            if (roomData.id === itemCategoryTabValue) {
                if(roomData.id == 'main_rooms' || roomData.id == 'meeting_rooms_in_laminate_finish') {
                    roomData.items.map((item: any, index: number) => {
                        item.skus.map((sku: any, skuIndex: number) => {
                            if(sku.id == 'meeting_rooms_in_laminate_finish_4') {//8 pax Rm
                                roomData.items[index].skus[skuIndex].roomCount  =  Math.floor(headCount/75);
                            }
                            if(sku.id == 'main_rooms_7') {//BMS Room
                                roomData.items[index].skus[skuIndex].roomCount = (builtUpArea > 100000) ? 1 : 0;
                            }
                            if(sku.id == 'main_rooms_8') {//Security Room
                                roomData.items[index].skus[skuIndex].roomCount = (builtUpArea > 200000) ? 1 : 0;
                            }
                            if(sku.id == 'main_rooms_9') {//Mail Room
                                roomData.items[index].skus[skuIndex].roomCount = (builtUpArea > 60000) ? 1 : 0;
                            }
                            if(sku.id == 'main_rooms_13') {//Transport Room
                                roomData.items[index].skus[skuIndex].roomCount = (builtUpArea > 200000) ? 1 : 0;
                            }
                            if(sku.id == 'main_rooms_14') {//Wellness Room
                                roomData.items[index].skus[skuIndex].roomCount = (builtUpArea > 200000) ? 1 : 0;
                            }
                            if(sku.id == 'main_rooms_22') {//Repro
                                let reproCount = 0;
                                if(builtUpArea > 1 &&  builtUpArea <= 25000) {
                                    reproCount = 1;
                                } else if(builtUpArea > 25000 &&  builtUpArea <= 65000) {
                                    reproCount = 2;
                                } else if(builtUpArea > 65000 &&  builtUpArea <= 80000) {
                                    reproCount = 3;
                                } else if(builtUpArea > 80000 &&  builtUpArea <= 125000) {
                                    reproCount = 4;
                                }
                                roomData.items[index].skus[skuIndex].roomCount = reproCount;
                            }
                            if(sku.id == 'main_rooms_30') {//Toilets
                                console.log("toiletCountStr =====");
                                let toiletCount = 0;
                                let toiletCountStr = props.ciParameters["total-number-of-toilet-blocks"];
                                if(toiletCountStr) {
                                    toiletCount = parseInt(toiletCountStr);
                                }

                                let toiletArea  = 0;
                                let toiletAreaStr = props.ciParameters["total-toilet-area-in-sqm"];
                                if(toiletAreaStr) {
                                    toiletArea = parseInt(toiletAreaStr);
                                }

                                roomData.items[index].skus[skuIndex].roomCount = toiletCount;
                                roomData.items[index].skus[skuIndex].closedAreaSqm = toiletArea;
                            }
                        })
                    })
                    console.log("props.ciParameters", props.ciParameters);
                    console.log("roomData.items =====");
                    console.log(roomData.items);
                }

                setItems(roomData.items);
            }
        });
    }, [itemCategoryTabValue]);

    return (
        <Box sx={{pt: 4}}>
            <Box sx={{backgroundColor: "#F9FAFF", fontWeight: 700, p: 2, position: 'relative'}}>
                <Typography>Room Data</Typography>
                {((remainingIndexes.length < 1))  && 
                <Button className={"head-count-next"} variant={"outlined"} sx={{mr: 2, position: 'absolute', top: '6px', right: '5px'}} 
                    onClick={(e) => checkSaveAndCountinue(e)}>
                    Submit & Continue
                </Button>}
            </Box>
            <ECatalogDialog
                            open={open}
                            onClose={handleClose}
                            confirmText={"You have unsaved changes. Are you sure you want to continue without saving?"}
                            nextTabValue={nextTabValue}
                          />

            <Box sx={{display: 'flex', pt: 4}}>
                <Box sx={{pr: 2, borderRight: 1, borderColor: 'divider', width: '35%'}}>
                    <Typography sx={{py: 3, px: 2, fontWeight: 700}}>
                        Item Category
                    </Typography>
                    <Tabs
                        orientation={'vertical'}
                        variant={'scrollable'}
                        value={itemCategoryTabValue}
                        onChange={handleItemCategoryTabChange}
                        aria-label="Item Category Tabs"
                        className="tab-sidebar"
                    >
                        {roomCountConfig && roomCountConfig.map((roomData: any, itemCategoryIndex: number) => {
                                return (
                                    <Box sx={{display:'flex', alignItems: 'center', position: 'relative', width: '100%', maxWidth: '360px'}}
                                        className={(itemCategoryTabValue === roomData.id) ? "tab-sidebar-outer tab-sidebar-outer-selected" : "tab-sidebar-outer"}>
                                    <Tab
                                        sx={{
                                            alignItems: 'flex-start',
                                            minHeight: '35px',
                                            borderRadius: 1,
                                            width: '100%'
                                        }}
                                        key={'item-category-tab-' + itemCategoryIndex}
                                        value={roomData.id}
                                        {...a11yProps(roomData.id, false)}
                                        label={
                                            <Typography className={(itemCategoryTabValue === roomData.id) ? "tab-sidebar-label tab-sidebar-label-selected" : "tab-sidebar-label"}>
                                                {roomData.name}
                                            </Typography>
                                        }
                                        disabled={roomData.disabled}
                                        wrapped
                                        className="tab-sidebar-item"
                                        onClick={() => handleItemCatTabChange(itemCategoryIndex)}
                                    />
                                    {roomItemCategegories.includes(roomData.id) && <CheckIcon sx={{position: "absolute", right:"11px", padding: "3px", marginTop: "2px", border: "1px solid #00ab41", borderRadius: "14px", height: "20px", width: "20px", background: "#00ab41", color: "#fff"}} />}
                                    {!roomItemCategegories.includes(roomData.id) && <CheckIcon sx={{position: "absolute", right:"11px", padding: "3px", marginTop: "2px", border: "1px solid #d5d5d5", borderRadius: "14px", height: "20px", width: "20px", background: "#d5d5d5", color: "#fff"}} />}
                                    </Box>
                                );
                            }
                        )}
                    </Tabs>
                </Box>
                {(itemCategoryTabValue !== "" && items.length > 0) &&
                    <CustomTabPanel
                        width={'65%'}
                        value={itemCategoryTabValue}
                        index={itemCategoryTabValue}
                    >
                        <Box sx={{p: 2}} id={"room-data-items"}>
                            <RoomDataItems
                                project={props.project}
                                revision={props.revision} 
                                items={items}
                                itemCategoryId={itemCategoryTabValue}
                                handleContinue={handleContinue}
                                goToNextItemCategory={goToNextItemCategory}
                                remainingIndexes={remainingIndexes}
                                currentIndex={currentRoomConfigIndex}
                                updateFieldChange={updateFieldChange} 
                                ref={ref}
                                ciParameters={props.ciParameters}                  
                            />
                        </Box>
                    </CustomTabPanel>
                }

            </Box>
        </Box>
    )
}
export default HeadCountRoomData;
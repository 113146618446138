import {useNavigate, useParams} from 'react-router-dom';
import { useEffect, useState } from 'react';
import Loader from '../../../../components/Loader';
import { api } from '../../../../utils/Axios';
import PageHeading from '../../../../components/page-heading/PageHeading';
import { Box } from '@mui/material';
import BuildForm from '../../../../components/input-form/BuildForm';
import { useCheckboxValues } from '../../../../hooks/useCheckboxValues';

const EditRole = () => {
	const { id } = useParams();
	const [isLoading, setIsLoading] = useState(false);
	const [role, setRole] = useState<any>({});
	const [permissions, setPermissions] = useState<any>([]);
	const { setSelectedCheckboxValue } = useCheckboxValues();
	const navigate = useNavigate();

	const loadRole = () => {
		setIsLoading(true);
		api
			.get(`/authentication/role/${id}`)
			.then((response) => {
				return response.data;
			})
			.then((res) => {
				formFields.map((formField: any) => {
					if (formField.name == 'name') {
						formField.value = res.name;
					}
					if (formField.name == 'slug') {
						formField.value = res.slug;
					}
					if (formField.name == 'entityName') {
						formField.value = res.entityName;
					}
					if (formField.name == 'permissions') {
						let options: any[] = [];
						permissions.forEach((item: any) => {
							options.push({
								id: item,
								label: item,
							});
						});
						formField.options = options;
						const selectedPermissions = res.permissions.map(
							(permission: any) => permission.name
						);
						setSelectedCheckboxValue(selectedPermissions);
						formField.value = selectedPermissions;
					}
				});
				setRole(res);
				setIsLoading(false);
			})
			.catch((ex) => {
				console.log(ex);
				setIsLoading(false);
			});
	};
	const getAllPermissions = () => {
		setIsLoading(true);
		api
			.get('/authentication/role/list-permissions')
			.then((response) => {
				return response.data;
			})
			.then((response: any) => {
				const options: any[] = [];
				response.forEach((item: any) => options.push(item.name));
				setPermissions(options);
				setIsLoading(false);
			})
			.catch((ex) => {
				console.log(ex);
				setIsLoading(false);
			});
	};

	const onSubmitHandler = (form: any, callback: any) => {
		console.log(form);
		setIsLoading(true);
		api.put(`/authentication/role/update/${id}`, form).then((response) => {
			setIsLoading(false);
			navigate('/users/roles');
		}).catch(ex => {
			console.log(ex);
			setIsLoading(false);
		});
		callback();
	};

	useEffect(() => {
		if (permissions.length > 0) loadRole();
	}, [id, permissions]);

	useEffect(() => {
		getAllPermissions();
	}, []);

	if (isLoading) {
		return <Loader />;
	}

	return (
		<Box className={'common-space-container'}>
			<PageHeading title={`Edit Role ${role?.name}`} enableBackButton={true}/>
			<Box>
				<Box className={'center-aligned'}>
					<Box sx={{ width: '50%' }}>
						<BuildForm
							buttons={[
								{
									type: 'submit',
									title: 'Save',
									permissions: ['edit-users'],
								},
							]}
							formArr={formFields}
							onSubmit={onSubmitHandler}
							handleFormChange={(form: any) => console.log(form)}
						/>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

const formFields = [
	{
		element: 'input',
		label: 'Name',
		placeholder: 'Name',
		name: 'name',
		type: 'text',
		required: true,
	},
	{
		element: 'input',
		label: 'Slug',
		placeholder: 'Role Slug',
		name: 'slug',
		type: 'text',
		format: 'no-space',
	},
	{
		element: 'select',
		label: "Role For",
		placeholder: 'Role For',
		name: "entityName",
		options: [
			{id: 'vendor', label: 'Vendor'}
		]
	},
	{
		element: 'checkbox',
		label: 'Permissions',
		placeholder: 'Select Permissions',
		name: 'permissions',
		fullWidth: true,
		options: [],
	},
];

export default EditRole;
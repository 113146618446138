export const getStyle = (width: number, height: number, customHeaderHeight: number, customFooterHeight: number) => {
    return getStyleWithTopModified(width, height, customHeaderHeight, customFooterHeight, 50);
}

export const getStyleWithTopModified = (width: number, height: number, customHeaderHeight: number, customFooterHeight: number, top: number) => {
    let headerHeight = 8;
    let footerHeight = 8;
    if (customHeaderHeight != undefined && customHeaderHeight > 0) {
        headerHeight = customHeaderHeight;
    }
    if (customFooterHeight != undefined && customFooterHeight > 0) {
        footerHeight = customFooterHeight;
    }
    let modalBodyHeight = 100 - (headerHeight + footerHeight);
    const style = {
        main: {
            position: "absolute" as "absolute",
            top: `${top}%`,
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: width + "%",
            height: height + "%",
            minHeight: "300px",
            border: 0,
            backgroundColor: "background.paper",
            boxShadow: 1,
            '&:focus-visible': {
                outline: 'none'
            }
        },
        header: {
            position: "sticky",
            borderBottom: 1,
            borderColor: "divider",
            px: 2,
            height: `${headerHeight}%`,
        },
        headerContainer: {
            height: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'stretch'
        },
        body: {position: "relative", height: `${modalBodyHeight}%`, pb: 1},
        modalFullBody: {position: "relative", height: "92%", pb: 1},
        bodyContainer: {p: 2, overflow: "auto", height: '100%', position: "relative"},
        footer: {
            position: "fixed",
            bottom: 0,
            width: "100%",
            height: `${footerHeight}%`,
            borderTop: 1,
            borderColor: 'divider'
        }
    };
    return style;
}
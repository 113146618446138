import React, {ChangeEvent, useEffect, useMemo, useState} from "react";
import dayjs, {Dayjs} from "dayjs";
import Loader from "../../Loader";
import {
    Autocomplete,
    Box,
    Button,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import {api} from "../../../utils/Axios";
import {debounce} from "@mui/material/utils";
import CloseIcon from "@mui/icons-material/Close";
import ResponsiveDatePickers from "../../common/DateRangePicker";
import {useAppDispatch} from "../../../app/hooks";
import {openSnackbar} from "../../common/features/snackbarSlice";
import {ERROR_COLOR, SUCCESS_COLOR} from "../../../constants";
import {getStyle} from "../../../utils/ModalUtils";
import PageHeading from "../../page-heading/PageHeading";
import AddIcon from '@mui/icons-material/Add';
import QuestionnairePopup from "./QuestionnairePopup";
import AddLink from "./AddLink";
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import AddComment from "./AddComment";
import ReadMore from "../../read-more/ReadMore";
import ProjectDetailsRow from "../../ProjectDetailsRow";
import CustomDateTimePicker from "../../common/CustomDateTimePicker";
import {Link} from "react-router-dom";

interface Vendor {
    id: string;
    name: string;
    city: string;
}

const CreateEventPopup = (props: any) => {
    const style = getStyle(100, 90, 0, 0);
    const today = dayjs();
    const nextWeek = today.add(7, 'day')

    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [vendors, setVendors] = useState<any>([]);
    const [selectedVendors, setSelectedVendors] = useState(
        vendors ? [vendors] : []
    );
    const [isDateValid, setIsDateValid] = useState(true);
    const [startDateValue, setStartDateValue] = useState<any>();
    const [endDateValue, setEndDateValue] = useState<any>();
    const [inputValue, setInputValue] = useState("");
    const [options, setOptions] = useState<readonly Vendor[]>([]);
    const [value, setValue] = useState<Vendor | null>(null);
    const [openQuestionPopup, setOpenQuestionPopup] = useState(false);
    const [questions, setQuestions] = useState<any>([]);
    const [selectedQuestionIndex, setSelectedQuestionIndex] = useState<any>(null);
    const [selectedDocumentIndex, setSelectedDocumentIndex] = useState<any>(null);
    const [selectedLinkIndex, setSelectedLinkIndex] = useState<any>(null);
    const [selectedExistingDocumentIndex, setSelectedExistingDocumentIndex] = useState<any>(null);
    const [openAddLinkPopup, setOpenAddLinkPopup] = useState(false);
    const [openAddCommentPopup, setOpenAddCommentPopup] = useState(false);
    const [commentType, setCommentType] = useState("");
    const [openAddCommentToExistingPopup, setOpenAddCommentToExistingPopup] = useState(false);
    const [links, setLinks] = useState<any>([]);
    const [documents, setDocuments] = useState<any>([]);
    const [existingDocuments, setExistingDocuments] = useState<any>([]);

    const [editEnabled, setEditEnabled] = useState(false);

    const handleDocumentUpload = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const files: any[] = [];
            Array.from(event.target.files).map((uploadedFile: any) => {
                files.push({document: uploadedFile, comment: ""});
            });
            if (documents.length > 0) {
                setDocuments([...documents, ...files]);
            } else {
                setDocuments(files);
            }
        }
    }

    const removeDocument = (index: number) => {
        let newDocuments: any = [...documents];
        newDocuments.splice(index, 1);
        setDocuments(newDocuments);
    }

    const removeFromExistingDocument = (index: number) => {
        let newDocuments: any = [...existingDocuments];
        newDocuments.splice(index, 1);
        setExistingDocuments(newDocuments);
    }

    const removeLink = (index: number) => {
        let newLinks: any = [...links];
        newLinks.splice(index, 1);
        setLinks(newLinks);
    }

    const handleAddCommentToExisting = (index: number) => {
        setSelectedExistingDocumentIndex(index);
        setOpenAddCommentToExistingPopup(true);
    }

    const handleAddComment = (index: number) => {
        setSelectedDocumentIndex(index);
        setCommentType("document");
        setOpenAddCommentPopup(true);
    }

    const handleAddCommentToLink = (index: number) => {
        setSelectedLinkIndex(index);
        setCommentType("link");
        setOpenAddCommentPopup(true);
    }

    const handleAddLink = () => {
        setOpenAddLinkPopup(true);
    }

    const handleAddLinkPopupCallback = (callBack: any) => {
        if (callBack.event == "add") {
            setLinks((prevLink: any) => [...prevLink, {url: callBack.data, comment: ""}]);
        }
        setOpenAddLinkPopup(false);
    }

    const handleAddCommentPopupCallback = (callBack: any) => {
        if (callBack.event == "add") {
            if (commentType == "link") {
                const allLinks = [...links];
                allLinks[selectedLinkIndex].comment = callBack.data;
                setLinks(allLinks);
                setSelectedLinkIndex(null);
            } else {
                const allDocuments = [...documents];
                allDocuments[selectedDocumentIndex].comment = callBack.data;
                setDocuments(allDocuments);
                setSelectedDocumentIndex(null);
            }
        }
        setCommentType("");
        setOpenAddCommentPopup(false);
    }

    const handleAddCommentToExistingPopupCallback = (callBack: any) => {
        if (callBack.event == "add") {
            let allDocuments: any[] = [...existingDocuments];
            let currentDocument: any = {...allDocuments[selectedExistingDocumentIndex]};
            currentDocument['comment'] = callBack.data;
            allDocuments[selectedExistingDocumentIndex] = currentDocument;
            setExistingDocuments(allDocuments);
        }
        setSelectedExistingDocumentIndex(null);
        setOpenAddCommentToExistingPopup(false);
    }

    const handleAddQuestion = () => {
        setOpenQuestionPopup(true);
    }

    const handleQuestionEdit = (index: number) => {
        setSelectedQuestionIndex(index);
        setOpenQuestionPopup(true);
    }

    const handleQuestionPopupCallback = (callBack: any) => {
        if (callBack.event == 'add') {
            setQuestions((prevQuestion: any) => [...prevQuestion, callBack.data]);
        }
        if (callBack.event == "edit") {
            let newQuestions: any = [...questions];
            newQuestions[callBack.index] = callBack.data;
            setQuestions(newQuestions);
        }
        setSelectedQuestionIndex(null);
        setOpenQuestionPopup(false);
    }

    const handleEndDateChange = (date: Dayjs | null) => {
        setIsDateValid(true);
        if (date && startDateValue && date.isBefore(startDateValue, "day")) {
            dispatch(
                openSnackbar({
                    message: "End date cannot be before start date",
                    backgroundColor: ERROR_COLOR,
                })
            );
            setIsDateValid(false);
            return;
        }
        setEndDateValue(date);
    }

    const fetchVendors = useMemo(
        () =>
            debounce(
                (query: string, callback: (results?: readonly Vendor[]) => void) => {
                    api
                        .get(`/procurement/vendor/search?query=${query}`)
                        .then((response) => response.data)
                        .then((data) => callback(data))
                        .catch(() => callback([]));
                },
                400
            ),
        []
    );

    useEffect(() => {
        let active = true;

        if (inputValue === "") {
            setOptions(value ? [value] : []);
            return undefined;
        }
        if (inputValue.length >= 3) {
            fetchVendors(inputValue, (results?: readonly Vendor[]) => {
                if (active) {
                    let newOptions: readonly Vendor[] = [];

                    if (value) {
                        newOptions = [value];
                    }

                    if (results) {
                        newOptions = [...newOptions, ...results];
                    }

                    setOptions(newOptions);
                }
            });
        }

        return () => {
            active = false;
        };
    }, [value, inputValue, fetchVendors]);

    const handleRemoveVendor = (indexToRemove: number) => {
        const updatedVendors = selectedVendors.filter(
            (_, index) => index !== indexToRemove
        );
        setSelectedVendors(updatedVendors);
    };

    const handleCreateEvent = (event: any) => {
        event.preventDefault();
        const data: any = new FormData(event.target);
        const vendorIdsList: any[] =
            selectedVendors && selectedVendors.map((vendor: any) => vendor.id);

        if (!isDateValid) {
            dispatch(
                openSnackbar({
                    message: "End date cannot be before start date",
                    backgroundColor: ERROR_COLOR,
                })
            );
            return;
        }

        if (vendorIdsList.length == 0) {
            dispatch(
                openSnackbar({
                    message: "Please select vendor to proceed",
                    backgroundColor: ERROR_COLOR,
                })
            );
            return;
        }

        const formData = new FormData();
        formData.append("packageId", props.package.id);
        formData.append("projectId", props.project.id);
        formData.append("eventName", data.get("eventName"));
        formData.append("emailBody", data.get("emailBody"));
        formData.append("sealedBid", data.get("sealedBid"));
        formData.append("startDate", startDateValue);
        formData.append("endDate", endDateValue);
        formData.append("links", JSON.stringify(links));
        vendorIdsList.map((vendorId: any) => {
            formData.append("vendorIds[]", vendorId);
        });

        formData.append("questions", JSON.stringify(questions));

        Array.from(documents).map((selectedDocument: any) => {
            formData.append("documents[]", selectedDocument.document);
            formData.append("comments[]", selectedDocument.comment);
        });

        setIsLoading(true);
        if (editEnabled) {
            if (existingDocuments.length > 0) {
                formData.append("existingDocuments", JSON.stringify(existingDocuments));
            }
            api.put(`procurement/event/${props.event.id}`, formData).then((response) => response.data)
                .then((response: any) => {
                    if (response) {
                        dispatch(
                            openSnackbar({
                                message: "Successfully updated event",
                                backgroundColor: SUCCESS_COLOR,
                            })
                        );
                        props.handlePopupCallBack({event: "close", prPackage: "event"});
                    }
                })
                .catch((ex) => {
                    dispatch(
                        openSnackbar({
                            message: "Oops. Something went wrong, please try again",
                            backgroundColor: ERROR_COLOR,
                        })
                    );
                    console.log(ex);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            api.post(`procurement/event`, formData).then((response) => response.data)
                .then((response: any) => {
                    if (response) {
                        dispatch(
                            openSnackbar({
                                message: "Successfully created event",
                                backgroundColor: SUCCESS_COLOR,
                            })
                        );
                        props.handlePopupCallBack({event: "close", prPackage: "event"});
                    }
                })
                .catch((ex) => {
                    dispatch(
                        openSnackbar({
                            message: "Oops. Something went wrong, please try again",
                            backgroundColor: ERROR_COLOR,
                        })
                    );
                    console.log(ex);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    const handleClose = () => {
        setOpen(false);
        props.handlePopupCallBack({event: "close"});
    };

    const loadVendors = () => {
        setIsLoading(true);
        api
            .get(`/procurement/package/entity-details/${props.package.id}`)
            .then((response) => response.data)
            .then((response: any) => {
                setVendors(response);
                setIsLoading(false);
            })
            .catch((ex) => {
                console.log(ex);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        setSelectedVendors(vendors ? vendors : []);
    }, [vendors]);

    useEffect(() => {
        if (props.openPopup === true) {
            if (props.event != undefined) {
                const eventVendors: any[] = [];
                props.event.eventVendors.map((eventVendor: any) => {
                    eventVendors.push(eventVendor.vendor);
                });
                setExistingDocuments(props.event.documents);
                setQuestions(props.event.questions);
                setVendors(eventVendors);
                setEditEnabled(true);
                setStartDateValue(dayjs(props.event.startDate))
                setEndDateValue(dayjs(props.event.endDate))
            } else {
                loadVendors();
                setStartDateValue(today);
                setEndDateValue(nextWeek);
            }
            setOpen(true);
        }
    }, [props.openPopup]);

    return (
        <Modal
            disableScrollLock={false}
            open={open}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
            sx={{"& > .MuiBackdrop-root": {backdropFilter: "blur(0px)", backgroundColor: "transparent"}}}
        >
            <Box sx={{
                position: "absolute" as "absolute",
                bottom: 0,
                width: "100%",
                height: '90%',
                border: 0,
                backgroundColor: "background.paper",
                boxShadow: 1,
                '&:focus-visible': {
                    outline: 'none'
                }
            }}>
                <Box sx={{position: "relative", height: "100%"}} component={"form"} onSubmit={handleCreateEvent}>
                    <Box sx={style.header}>
                        <Box sx={style.headerContainer}>
                            <Box sx={{width: '95%'}}>
                                <PageHeading
                                    title={"Add Event Details"}
                                />
                            </Box>
                            <Box onClick={handleClose}
                                 sx={{display: 'flex', justifyContent: 'right', width: '5%', cursor: 'pointer'}}>
                                <CloseIcon/>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={"modal-body"} sx={style.body}>
                        <Box sx={style.bodyContainer}>
                            <Box className={"common-space-container"}>
                                {isLoading &&
                                    <Loader/>
                                }
                                <Box sx={{py: 2}}>
                                    <ProjectDetailsRow color={'253, 182, 122'} project={props.project}/>
                                </Box>

                                <Box sx={{pt: 2}}>
                                    <Box sx={{width: '26%', py: 2}}>
                                        <InputLabel required={true} sx={{pb: 1, fontSize: "0.8rem"}}>
                                            Event Name
                                        </InputLabel>
                                        <Box>
                                            <TextField
                                                fullWidth
                                                size={"small"}
                                                variant={"outlined"}
                                                className={"inputBox"}
                                                name="eventName"
                                                required={true}
                                                defaultValue={editEnabled ? props.event.name : ""}
                                                InputProps={{
                                                    style: {
                                                        borderRadius: "5px",
                                                        borderColor: 'primary.light',
                                                        fontSize: "0.8rem",
                                                    },
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                    <Box sx={{py: 2}}>
                                        <Box className={"space-between"}>
                                            <InputLabel sx={{pb: 1, fontSize: "0.8rem"}}>
                                                Email Body
                                            </InputLabel>
                                            <InputLabel sx={{pb: 1, fontSize: "0.8rem"}}>
                                                Note : This will be sent as email body for selected vendors of this
                                                event
                                            </InputLabel>
                                        </Box>
                                        <Box>
                                            {/*<Box>*/}
                                            {/*    <RichTextEditor/>*/}
                                            {/*</Box>*/}
                                            <TextField
                                                fullWidth size={"small"} variant={"outlined"} className={"inputBox"}
                                                name="emailBody"
                                                defaultValue={editEnabled ? props.event.description : ""}
                                                multiline rows={10}
                                                InputProps={{
                                                    style: {
                                                        borderRadius: "5px",
                                                        borderColor: 'primary.light',
                                                        fontSize: "0.8rem"
                                                    },
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Typography>Event Timeline</Typography>
                                        <Box sx={{width: '40%'}} display={"flex"} justifyContent={"start"}
                                             alignItems={"center"}>
                                            <Box sx={{pr: 2}}>
                                                <InputLabel required={true} sx={{py: 1, fontSize: "0.8rem"}}>Start
                                                    Date</InputLabel>
                                                {/*<ResponsiveDatePickers label="" value={startDateValue} onChange={setStartDateValue}/>*/}
                                                <CustomDateTimePicker label="" value={startDateValue} onChange={setStartDateValue}/>
                                            </Box>
                                            <Box>
                                                <InputLabel required={true} sx={{py: 1, fontSize: "0.8rem"}}>End
                                                    Date</InputLabel>
                                                {/*<ResponsiveDatePickers label="" value={endDateValue} onChange={handleEndDateChange}/>*/}
                                                <CustomDateTimePicker label="" value={endDateValue}
                                                                      onChange={handleEndDateChange}/>
                                            </Box>
                                        </Box>
                                        <Box sx={{width: "20.5%", py: 2}}>
                                            <InputLabel required={true} sx={{pb: 1, fontSize: "0.8rem"}}>
                                                Sealed Bid?
                                            </InputLabel>
                                            <Box sx={{width: '60%'}}>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    name={"sealedBid"}
                                                    required={true}
                                                    defaultValue={editEnabled ? props.event.sealedBid : ""}
                                                    style={{height: '40px', width: '100%'}}
                                                >
                                                    <MenuItem value={"false"}>No</MenuItem>
                                                    <MenuItem value={"true"}>Yes</MenuItem>
                                                </Select>
                                            </Box>
                                        </Box>
                                        <Box sx={{width: "26%"}}>
                                            <Box className={"space-between vertically-center-aligned"}>
                                                <Typography sx={{fontSize: "12px", fontWeight: 700, py: 2}}>
                                                    Select Vendors
                                                </Typography>
                                                <Link to={"/vendors"} target={"_blank"}>Create new vendor</Link>
                                            </Box>
                                            <Autocomplete
                                                id="vendor-autocomplete"
                                                sx={{width: '100%'}}
                                                getOptionLabel={(option: Vendor) => option.name}
                                                filterOptions={(x) => x}
                                                options={options}
                                                autoComplete
                                                includeInputInList
                                                filterSelectedOptions
                                                value={value}
                                                noOptionsText="No vendors"
                                                onChange={(event: any, newValue: any) => {
                                                    if (newValue) {
                                                        // Check if the selected vendor already exists in the vendors list
                                                        if (!selectedVendors.some((vendor: any) => vendor.name === newValue.name)) {
                                                            setSelectedVendors((prev: any) => [...prev, newValue,])
                                                        }
                                                    }
                                                    setValue(newValue);
                                                }}
                                                onInputChange={(event, newInputValue) => {
                                                    setInputValue(newInputValue);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField{...params} label="Enter name of vendor to select" fullWidth/>
                                                )}
                                                renderOption={(props, option) => (
                                                    <li {...props}>
                                                        <Box sx={{fontWeight: "regular"}}>
                                                            {option.name}
                                                        </Box>
                                                    </li>
                                                )}
                                            />
                                            <Box sx={{py: 2}}>
                                                <Table
                                                    sx={{
                                                        border: "1px solid",
                                                        borderColor: "#E8ECEF !important",
                                                    }}
                                                >
                                                    <TableBody>
                                                        {selectedVendors &&
                                                            selectedVendors.length > 0 &&
                                                            selectedVendors.map((vendor: any, index: number) => (
                                                                <TableRow key={"vendor-list-" + index}
                                                                          sx={{border: index == 0 ? 1 : 0, borderColor: '#E8ECEF', position: 'relative'}}>
                                                                    <TableCell
                                                                        sx={{fontSize: "12px", fontWeight: "400", lineHeight: "14.4px", textAlign: "left", color: "#333333",}}
                                                                    >
                                                                        {vendor.name}
                                                                        <IconButton
                                                                            sx={{position: "absolute", top: "50%", right: 0, transform: "translateY(-50%)",}}
                                                                            onClick={() => handleRemoveVendor(index)}
                                                                        >
                                                                            <CloseIcon sx={{fontSize: "14px"}}/>
                                                                        </IconButton>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                    </TableBody>
                                                </Table>
                                            </Box>
                                        </Box>
                                        <Box sx={{width: '60%'}}>
                                            <Box sx={{py: 2, width: '90%'}}>
                                                <Typography sx={{fontSize: '18px', fontWeight: 700}}>
                                                    Documents
                                                </Typography>

                                                {links.length > 0 && (
                                                    links.map((uploadedLink: any, linkIndex: number) => (
                                                        <Box key={"link-" + linkIndex} className={"space-between"}>
                                                            <Box className={"space-between"}
                                                                 sx={{border: 1, p: 1, mt: 1, width: '70%'}}>
                                                                <Box className={"vertically-center-aligned"}
                                                                     sx={{pr: 1}}>
                                                                    <InsertLinkIcon/>
                                                                </Box>
                                                                <Typography className={"vertically-center-aligned"}
                                                                            sx={{wordBreak: "break-all"}}>{uploadedLink.url}</Typography>
                                                                <Box className={"vertically-center-aligned"}
                                                                     sx={{pr: 1}}>
                                                                    <CloseIcon sx={{cursor: 'pointer'}}
                                                                               onClick={() => removeLink(linkIndex)}/>
                                                                </Box>
                                                            </Box>
                                                            {uploadedLink.comment == "" ? (
                                                                <Button variant={"text"}
                                                                        onClick={() => handleAddCommentToLink(linkIndex)}>
                                                                    Add Comment
                                                                </Button>
                                                            ) : (
                                                                <Box className={"vertically-center-aligned"}
                                                                     sx={{px: 2, width: '30%'}}>
                                                                    <ReadMore text={uploadedLink.comment}
                                                                              maxChars={20}/>
                                                                </Box>
                                                            )}
                                                        </Box>
                                                    ))
                                                )}
                                                {editEnabled && existingDocuments.length > 0 && (
                                                    <Box>
                                                        {existingDocuments.map((existingDocument: any, docIndex: number) => (
                                                            <Box key={"existing-document-" + docIndex}
                                                                 className={"space-between"}>
                                                                <Box className={"space-between"}
                                                                     sx={{border: 1, p: 1, mt: 1, width: '70%'}}>
                                                                    <Box className={"vertically-center-aligned"}
                                                                         sx={{pr: 1}}>
                                                                        {existingDocument.type == "link" ?
                                                                            <InsertLinkIcon/> : <TextSnippetIcon/>}
                                                                    </Box>
                                                                    <Typography
                                                                        className={"vertically-center-aligned"}
                                                                        sx={{wordBreak: "break-all"}}>{existingDocument.name}</Typography>
                                                                    <Box className={"vertically-center-aligned"}
                                                                         sx={{pr: 1}}>
                                                                        <CloseIcon sx={{cursor: 'pointer'}}
                                                                                   onClick={() => removeFromExistingDocument(docIndex)}/>
                                                                    </Box>
                                                                </Box>
                                                                {existingDocument.comment == "" ? (
                                                                    <Button variant={"text"}
                                                                            onClick={() => handleAddCommentToExisting(docIndex)}>
                                                                        Add Comment
                                                                    </Button>
                                                                ) : (
                                                                    <Box className={"vertically-center-aligned"}
                                                                         sx={{px: 2, width: '30%'}}>
                                                                        <ReadMore text={existingDocument.comment}
                                                                                  maxChars={20}/>
                                                                    </Box>
                                                                )}
                                                            </Box>
                                                        ))}
                                                    </Box>
                                                )}
                                                {documents.length > 0 && (
                                                    <Box>
                                                        {Array.from(documents).map((selectedDocument: any, docIndex: number) => {
                                                            return (
                                                                <Box key={"document-" + docIndex}
                                                                     className={"space-between"}>
                                                                    <Box className={"space-between"}
                                                                         sx={{border: 1, p: 1, mt: 1, width: '70%'}}>
                                                                        <Box className={"vertically-center-aligned"}
                                                                             sx={{pr: 1}}>
                                                                            <TextSnippetIcon/>
                                                                        </Box>
                                                                        <Typography
                                                                            className={"vertically-center-aligned"}
                                                                            sx={{wordBreak: "break-all"}}>{selectedDocument.document?.name}</Typography>
                                                                        <Box className={"vertically-center-aligned"}
                                                                             sx={{pr: 1}}>
                                                                            <CloseIcon sx={{cursor: 'pointer'}}
                                                                                       onClick={() => removeDocument(docIndex)}/>
                                                                        </Box>
                                                                    </Box>
                                                                    {selectedDocument.comment == "" ? (
                                                                        <Button variant={"text"}
                                                                                onClick={() => handleAddComment(docIndex)}>
                                                                            Add Comment
                                                                        </Button>
                                                                    ) : (
                                                                        <Box className={"vertically-center-aligned"}
                                                                             sx={{px: 2, width: '30%'}}>
                                                                            <ReadMore text={selectedDocument.comment}
                                                                                      maxChars={20}/>
                                                                        </Box>
                                                                    )}
                                                                </Box>
                                                            )
                                                        })}
                                                    </Box>
                                                )}
                                                <Box sx={{py: 2}}>
                                                    <Button component="label"
                                                            sx={{borderColor: '#6C63FF', color: '#6C63FF'}}
                                                            variant={"outlined"} endIcon={<AddIcon/>}>
                                                        {documents.length > 0 ? "Add More Files" : "Add Files"}
                                                        <input name="documents" onChange={handleDocumentUpload} hidden
                                                               multiple type="file"/>
                                                    </Button>
                                                    <Button sx={{borderColor: '#6C63FF', color: '#6C63FF', ml: 2}}
                                                            onClick={handleAddLink} variant={"outlined"}
                                                            endIcon={<AddIcon/>}>
                                                        Add Link
                                                    </Button>
                                                </Box>
                                            </Box>
                                            <Box sx={{py: 2, width: '90%'}}>
                                                <Typography sx={{fontSize: '18px', fontWeight: 700}}>
                                                    Questions
                                                </Typography>
                                                {questions.length > 0 && (
                                                    <Box sx={{py: 1}}>
                                                        {questions.map((addedQuestion: any, index: number) => (
                                                            <Box key={"question-block-" + index} sx={{pb: 1}}>
                                                                <Box className={"space-between"}>
                                                                    <Typography
                                                                        className={"vertically-center-aligned"}>{addedQuestion.question}</Typography>
                                                                    <Button variant={"text"}
                                                                            onClick={() => handleQuestionEdit(index)}>Edit</Button>
                                                                </Box>
                                                                <Box className={"space-between"}>
                                                                    <Typography>Type: {addedQuestion?.questionType == "radio" ? "Single Choice" : addedQuestion?.questionType == "checkbox" ? "Multiple Choice" : "Text"}</Typography>
                                                                    {Array.isArray(addedQuestion.answers) && addedQuestion.answers.length > 0 && (
                                                                        <Typography>Answers: {addedQuestion.answers.join(", ")}</Typography>
                                                                    )}
                                                                </Box>
                                                            </Box>
                                                        ))}
                                                    </Box>
                                                )}
                                                <Box sx={{py: 2}}>
                                                    <Button sx={{borderColor: '#969696', color: '#969696'}}
                                                            onClick={handleAddQuestion}
                                                            variant={"outlined"} endIcon={<AddIcon/>}>
                                                        Add Question
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={style.footer}>
                        <Grid container sx={{px: 2, height: "100%"}} className={"center-aligned"}>
                            <Grid item xs/>
                            <Grid item>
                                <Box>
                                    <Button variant={"text"} sx={{mr: 2, fontSize: "0.8rem"}}
                                            onClick={handleClose}>
                                        Cancel
                                    </Button>
                                    <Button
                                        type={"submit"}
                                        variant={"contained"}
                                        sx={{fontSize: "0.8rem"}}
                                    >
                                        Save
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                {openQuestionPopup && (
                    <QuestionnairePopup
                        openPopup={openQuestionPopup}
                        questions={questions}
                        selectedQuestionIndex={selectedQuestionIndex}
                        handleQuestionPopupCallback={handleQuestionPopupCallback}
                    />
                )}
                {openAddLinkPopup && (
                    <AddLink openPopup={openAddLinkPopup} handleAddLinkPopupCallback={handleAddLinkPopupCallback}/>
                )}
                {openAddCommentPopup && (
                    <AddComment openPopup={openAddCommentPopup}
                                handleAddCommentPopupCallback={handleAddCommentPopupCallback}/>
                )}
                {openAddCommentToExistingPopup && (
                    <AddComment openPopup={openAddCommentToExistingPopup}
                                handleAddCommentPopupCallback={handleAddCommentToExistingPopupCallback}/>
                )}
            </Box>
        </Modal>
    );
};

export default CreateEventPopup;

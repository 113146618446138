import {Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import React, {useState} from "react";
import EventVendorDetailsPopup from "./EventVendorDetailsPopup";
import {api} from "../../../utils/Axios";
import {openSnackbar} from "../../common/features/snackbarSlice";
import {ERROR_COLOR, SUCCESS_COLOR} from "../../../constants";
import {useAppDispatch} from "../../../app/hooks";
import Loader from "../../Loader";

const EventVendorDetails = (props: any) => {
    const {eventDetails, eventVendors} = props;
    const [selectedEventVendor, setSelectedEventVendor] = useState<any>({});
    const [openVendorDetails, setOpenVendorDetails] = useState<any>(false);
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useAppDispatch();

    const endDate = new Date(eventDetails.endDate);
    const today = new Date();
    endDate.setHours(23, 59, 59, 0);
    today.setHours(23, 59, 59, 0);
    let isEventExpired = false;
    if (today > endDate) {
        isEventExpired = true;
    }

    const notifyVendor = (eventVendor: any) => {
        setIsLoading(true);
        api.get(`/procurement/event//notify-vendor/${eventDetails.id}/${eventVendor.id}`).then((response) => {
            return response.data;
        }).then((res) => {
            if(res) {
                dispatch(
                    openSnackbar({
                        message: "Successfully notified vendor",
                        backgroundColor: SUCCESS_COLOR,
                    })
                );
            }
        }).catch((ex) => {
            dispatch(
                openSnackbar({
                    message: "Opps! Something went wrong, please try again later",
                    backgroundColor: ERROR_COLOR,
                })
            );
            console.log(ex);
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const handleViewVendorDetails = (eventVendor: any) => {
        setSelectedEventVendor(eventVendor);
        setOpenVendorDetails(true);
    }

    const handlePopupCallback = () => {
        setSelectedEventVendor({});
        setOpenVendorDetails(false);
    }


    if (isLoading) {
        return <Loader/>;
    }

    return (
        <Box mt={4}>
            <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Typography variant={"h4"} sx={{pb: 1}}>Vendor Details</Typography>
            </Box>
            <TableContainer
                component={"div"}
                sx={{border: "1px solid", borderColor: "divider", borderBottom: 0}}
            >
                <Table sx={{minWidth: 650}} aria-label="simple table">
                    <TableHead sx={{backgroundColor: "info.contrastText"}}>
                        <TableRow>
                            <TableCell width={"6%"} align={"left"}>Sr. No.</TableCell>
                            <TableCell align={"left"}>Vendor Name</TableCell>
                            <TableCell align={"left"}>Vendor Active?</TableCell>
                            <TableCell align={"left"}>Vendor Event Status</TableCell>
                            <TableCell align={"left"}>Documents Added</TableCell>
                            <TableCell align={"left"}>Questions Answered</TableCell>
                            <TableCell align={"left"}>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {eventVendors.map((eventVendor: any, index: number) => {
                            let statusLabel = "Rejected";
                            let statusColor = "#1565c0";
                            if (eventVendor?.status == "REJECTED") {
                                statusColor = "#d32f2f";
                            }
                            if (eventVendor.status == "CREATED" || eventVendor.status == "PARTICIPATED") {
                                statusLabel = "Not Submitted";
                            }
                            if (eventVendor.status == "SUBMITTED") {
                                statusLabel = "Bid Submitted";
                            }
                            return (
                                <TableRow key={eventVendor.vendorId}>
                                    <TableCell align={"left"}>{index + 1}</TableCell>
                                    <TableCell align={"left"}>
                                        <Link to={`/vendors/details/${eventVendor.vendorId}`} target={"_blank"}>
                                            {eventVendor.vendor.name}
                                        </Link>
                                    </TableCell>
                                    <TableCell align={"left"}>
                                        <Typography
                                            sx={{textTransform: 'capitalize'}}>{eventVendor?.vendor.status.toLowerCase()}</Typography>
                                    </TableCell>
                                    <TableCell align={"left"}>
                                        <Typography
                                            sx={{
                                                textTransform: "capitalize",
                                                border: 1,
                                                color: 'common.white',
                                                borderColor: `${statusColor}`,
                                                borderRadius: 1,
                                                p: 0.6,
                                                backgroundColor: `${statusColor}`,
                                                minWidth: "100px",
                                                textAlign: "center",
                                                width: "60%",
                                            }}
                                        >
                                            {statusLabel}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align={"left"}>
                                        {(eventVendor.link != "" && eventVendor.link != null) ? (eventVendor.vendorDocuments.length + 1) : eventVendor.vendorDocuments.length}
                                    </TableCell>
                                    <TableCell align={"left"}>
                                        {eventVendor.vendorQuestions.length}
                                    </TableCell>
                                    <TableCell align={"left"}>
                                        <Button variant={"text"} sx={{minHeight: '10px', m: 0, p: 0}}
                                                onClick={() => handleViewVendorDetails(eventVendor)}>
                                            View Details
                                        </Button>
                                        {eventDetails.status == "PUBLISHED" && !isEventExpired && (
                                            <Box sx={{ml: 1, display: 'inline-block'}}>
                                                <Button variant={"outlined"} sx={{minHeight: '10px', m: 0, px: 1, py: 0.5}}
                                                        onClick={() => notifyVendor(eventVendor)}>
                                                    Notify Vendor
                                                </Button>
                                            </Box>
                                        )}
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            {openVendorDetails && (
                <EventVendorDetailsPopup openPopup={openVendorDetails} eventVendor={selectedEventVendor}
                                         questions={eventDetails.questions} handlePopupCallback={handlePopupCallback}/>
            )}
        </Box>
    )
}
export default EventVendorDetails;
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {useEffect, useState} from "react";

const SelectBox = (props: any) => {
    const {formField} = props;
    const [fieldValue, setFieldValue] = useState<any>("");

    const onChange = (e: any) => {
        setFieldValue(e.target.value);
        props.onChangeHandler(e);
    }

    useEffect(() => {
        let val = (props?.valueFields?.hasOwnProperty(formField.name)) ? props.valueFields[formField.name] :  (formField.value != undefined ? formField.value : "");
        setFieldValue(val);
    }, []);

    useEffect(() => {
        if(props?.valueFields?.hasOwnProperty(formField.name) && props?.valueFields[formField.name] != formField) {
            setFieldValue(props?.valueFields[formField.name]);
        }
        //console.log(props.valueFields);
    }, [props.valueFields, props.disableFields])

    return (
        <FormControl fullWidth>
            <Select
                labelId="demo-simple-select-label"
                id={formField.name}
                // size={"small"}
                name={formField.name}
                required={formField?.required ? formField.required : false}
                //onChange={(event: any) => props.onChangeHandler(event)}
                onChange={(e) => onChange(e)}
                //defaultValue={formField.value != undefined ? formField.value : ""}
                value={fieldValue}
                disabled={(formField?.disableOn && props?.disableFields?.hasOwnProperty(formField.name)) ? true : false}
                readOnly={formField?.readonly}
                style={{height: '40px'}}
            >
                {formField.options.map((option: any, index: number) => (
                    <MenuItem key={"build-form-select-" + index}
                              value={option.id}>{option.label}</MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
export default SelectBox;
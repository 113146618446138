import {useParams} from 'react-router-dom';
import {SyntheticEvent, useEffect, useState, useRef} from 'react';
import Loader from '../../../../components/Loader';
import {Box, Tab, Tabs, Button} from '@mui/material';
import {a11yProps, CustomTabPanel} from '../../../../utils/TabsUtils';
import HvacTab from '../../../../components/parameter-tabs/HvacTab';
import ElectricalTab from '../../../../components/parameter-tabs/ElectricalTab';
import IbmsTab from '../../../../components/parameter-tabs/IbmsTab';
import PheTab from '../../../../components/parameter-tabs/PheTab';
import QuantityBuffer from '../../../../components/parameter-tabs/QuantityBuffer';
import InfoTech from '../../../../components/parameter-tabs/InfoTech';
import CiPlusCommonTab from '../../../../components/parameter-tabs/CiPlusCommonTab';
import {api} from "../../../../utils/Axios";
import MEPTab from '../../../../components/parameter-tabs/MEPTab';
import LtCable from '../../../../components/parameter-tabs/LtCable';
import ECatalogDialog from "../../../../components/ecatalog/ECatalogDialog";
import CheckIcon from '@mui/icons-material/Check';

const SiteConditionTab = (props: any) => {
    let {id} = useParams();
    const [formState, setFormState] = useState<any>({});
    const {project} = props;
    const [isLoading, setIsLoading] = useState(false);
    const [tabValue, setTabValue] = useState("");
    const [fieldUpdated, setFieldUpdated] = useState(false);
    const [enabledCountinue, setEnabledCountinue] = useState(false);
    const [open, setOpen] = useState(false);
    const [nextTabValue, setNextTabValue] = useState("");
    const [toHeadCount, setToHeadCount] = useState<any>(0);
    const [tabs, setTabs] = useState([
        {
            id: 'c-and-i-common',
            name: 'C&I + Common',
            disabled: false,
            complete: false,
        },
        {
            id: 'ibms',
            name: 'IBMS',
            disabled: false,
            complete: false,
        },
        {
            id: 'phe-ff',
            name: 'PHE & FF',
            disabled: false,
            complete: false,
        },
        {
            id: 'hvac',
            name: 'HVAC',
            disabled: false,
            complete: false,
        },
        {
            id: 'electrical',
            name: 'Electrical',
            disabled: false,
            complete: false,
        },
        {
            id: 'info-tech',
            name: 'IT',
            disabled: false,
            complete: false,
        },
       /* {
            id: 'mep',
            name: 'MEP',
            disabled: true,
        },*/
        {
            id: 'ltCable',
            name: 'LT Cable',
            disabled: false,
            complete: false,
        },
    ])

    const [allParameterCategories, setAllParameterCategories] = useState([]);
    const buttonRef = useRef<null | HTMLDivElement>(null);
    const [loadedParameters, setLoadedParameters] = useState(false);

    const loadAllParameters = () => {
        console.log("Getting Caaled ======================");
        setIsLoading(true);
        api.get(`/procurement/project/parameter/all/${project.id}?revisionId=${props?.revision?.id}`).then((response) => {
            return response.data;
        }).then(response => {
            //console.log("res", response)
            setAllParameterCategories(response);
            const initialFormState = response.reduce((acc: any, category: any) => {
                acc[category.categoryName] = category.parameters || {};
                return acc;
            }, {});
            setFormState(initialFormState);
            const updatedTabs = [...tabs];
            
            updatedTabs.forEach((tab: any, idx: number) => {
                if (response.some((res: any) => res.categoryName === tab.id) && idx < tabs.length - 1) {
                    const nextTab = tabs[idx + 1];
                    nextTab.disabled = false;
                }
                if (response.some((res: any) => res.categoryName === tab.id)) {
                    const currTab = tabs[idx];
                    currTab.complete = true;
                }
            });
            setTabs(updatedTabs);

            let enabledTab = true;
            updatedTabs.forEach((tab: any, idx: number) => {
                if(!tab.complete) {
                    enabledTab = false;
                }
            });
            setEnabledCountinue(enabledTab);

            setIsLoading(false);
            
        }).catch(ex => {
            console.log(ex);
            setIsLoading(false);
        });
    };

    useEffect(() => {
        loadAllParameters();
        setTabValue("c-and-i-common");
    }, []);

    useEffect(() => {
        loadAllParameters();
    }, [tabValue]);

    useEffect(() => {
        if(toHeadCount != 0) {
            proceedToNextTab();
        }
    }, [toHeadCount]);

    //console.log("allParameterCategories", allParameterCategories)
    if (isLoading) {
        return <Loader/>;
    }

    const handleTabChange = (event: SyntheticEvent, newValue: string) => {
        if(fieldUpdated) {
            setNextTabValue(newValue);
            setOpen(true);
        } else {
            loadAllParameters();
            setTabValue(newValue);
        }
    };

    const handleClose = (value:any, tabValue:any) => {
        setOpen(false);
        console.log(tabValue);
        if(value == "proceed") {
            setFieldUpdated(false);
            if(tabValue == "proceedToNextTab") {
                goToNextTab();
            } else {
                loadAllParameters();
                setTabValue(tabValue);
            }
        } else {
            
            buttonRef.current?.scrollIntoView({ behavior: "smooth" })
        }
    };

    const updateFieldChange = (value:boolean) => {
        setFieldUpdated(value);
    }

    const handleNext  = (categoryName: any) => {
        props.handleDisableNextTabs("site-condition", true);
        tabs.map((tab: any, idx: number) => {
            if(tab.id === categoryName){
                let nextTab = tabs[idx + 1];
                nextTab.disabled = false;
                setTabValue(nextTab.id)
            }
        })
    }

    const goToNextTab = () => {
        props.handleDisableNextTabs("site-condition", true);
        api.post(`/procurement/project/sync-parameters/${project.id}?revisionId=${props?.revision?.id}`).then((response) => {
            props.proceedTabChange('head-count')
        }).catch(ex => {
            console.log(ex);
        });
    }

    const proceedToNextTab = () => {
        if(fieldUpdated) {
            console.log("Field state change found ..");
            setNextTabValue("proceedToNextTab");
            setOpen(true);
        } else {
            goToNextTab();
        }
    }

    const updateToHeadCount = () => {

        //let newHeadCount = toHeadCount + 1;
        //setToHeadCount(newHeadCount);
        let found = false;
        tabs.map((tab: any, idx: number) => {
            if(!tab.complete && !found && tab.id != 'ltCable') {
                found = true;
                let nextTab = tabs[idx];
                nextTab.disabled = false;
                setTabValue(nextTab.id)
                return;
            }
        })
        if(!found) {
            loadAllParameters();
        }


    }
    
    return (
        <>
            <Box sx={{position: 'relative'}}>
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    aria-label="Site Conditions & Assumptions Tabs"
                    textColor="primary"
                    indicatorColor="primary"
                    sx={{
                        display: "flex",
                        mb: 0,
                        "& .MuiTabs-indicator": {
                            backgroundColor: "primary.main",
                        },
                        "& .MuiTabs-scroller": {
                            display: "flex !important",
                        },
                        borderBottom: 1,
                        borderColor: 'divider'
                    }}
                    TabIndicatorProps={{
                      sx: {
                        backgroundColor: 'info.main',
                      },
                    }}
                    
                >
                    {tabs.map((tab: any, index: number) => (
                        
                        <Tab
                            key={tab.id + '_' + index}
                            {...a11yProps(tab.id, true)}
                            value={tab.id}
                            label={tab.name}
                            disabled={!tab.disabled || tabValue === tab.id ? false : true}
                            wrapped
                            icon={tab.complete ? <CheckIcon sx={{height: "20px", width: "20px", color: "#00ab41"}}/> : <CheckIcon sx={{height: "20px", width: "20px", color: "#d5d5d5"}}/>}
                            iconPosition="start"
                            sx={{
                                fontWeight: tabValue === tab.id ? 'bold' : 500,
                                color: tabValue === tab.id ? '#1E2B51' : '#1E2B51',
                                '&.Mui-selected': {
                                    color: '#1E2B51 !important'
                                },
                                alignItems: "center",
                                minHeight: "48px",
                            }}
                        />
                    ))}
                </Tabs>
                <Box sx={{display: 'flex', justifyContent: 'right', py: 2}}>
                    {enabledCountinue && 
                    <Button className="site-condition-next" variant={"outlined"} sx={{mr: 2, position: 'absolute', top: '-2px', right: '5px'}} 
                        onClick={() => proceedToNextTab()}>
                        Continue
                    </Button>}
                </Box>
                <ECatalogDialog
                            open={open}
                            onClose={handleClose}
                            confirmText={"You have unsaved changes. Are you sure you want to continue without saving?"}
                            nextTabValue={nextTabValue}
                          />
                {tabValue == 'c-and-i-common' &&
                    <CustomTabPanel value={tabValue} index={tabValue}>
                        <Box sx={{p: 2}}>
                            <CiPlusCommonTab project={project} categoryName={tabValue} formState={formState} setFormState={setFormState}
                                             allParameters={allParameterCategories} handleNext={handleNext} updateFieldChange={updateFieldChange} buttonRef={buttonRef}/>
                        </Box>
                    </CustomTabPanel>
                }
                {tabValue == 'ibms' &&
                    <CustomTabPanel value={tabValue} index={'ibms'}>
                        <Box sx={{p: 2}}>
                            <IbmsTab project={project} categoryName={tabValue} allParameters={allParameterCategories} handleNext={handleNext} formState={formState} setFormState={setFormState} updateFieldChange={updateFieldChange} buttonRef={buttonRef}/>
                        </Box>
                    </CustomTabPanel>
                }
                {tabValue == 'phe-ff' &&
                    <CustomTabPanel value={tabValue} index={'phe-ff'}>
                        <Box sx={{p: 2}}>
                            <PheTab project={project} categoryName={tabValue} allParameters={allParameterCategories} handleNext={handleNext} formState={formState} setFormState={setFormState} updateFieldChange={updateFieldChange} buttonRef={buttonRef}/>
                        </Box>
                    </CustomTabPanel>
                }
                {tabValue == 'hvac' &&
                    <CustomTabPanel value={tabValue} index={'hvac'}>
                        <Box sx={{p: 2}}>
                            <HvacTab project={project} categoryName={tabValue} allParameters={allParameterCategories} handleNext={handleNext} formState={formState} setFormState={setFormState} updateFieldChange={updateFieldChange} buttonRef={buttonRef}/>
                        </Box>
                    </CustomTabPanel>
                }
                {tabValue == 'electrical' &&
                    <CustomTabPanel value={tabValue} index={'electrical'}>
                        <Box sx={{p: 2}}>
                            <ElectricalTab project={project} categoryName={tabValue}
                                           allParameters={allParameterCategories} handleNext={handleNext} formState={formState} setFormState={setFormState} updateFieldChange={updateFieldChange} buttonRef={buttonRef}/>
                        </Box>
                    </CustomTabPanel>
                }
                {tabValue == 'info-tech' &&
                    <CustomTabPanel value={tabValue} index={'info-tech'}>
                        <Box sx={{p: 2}}>
                            <InfoTech project={project} categoryName={tabValue} allParameters={allParameterCategories} handleNext={handleNext} formState={formState} setFormState={setFormState} updateFieldChange={updateFieldChange} buttonRef={buttonRef}/>
                        </Box>
                    </CustomTabPanel>
                }
                {tabValue == 'mep' &&
                    <CustomTabPanel value={tabValue} index={'mep'}>
                        <Box sx={{p: 2}}>
                            <MEPTab project={project} categoryName={tabValue} allParameters={allParameterCategories} handleNext={handleNext} formState={formState} setFormState={setFormState} updateFieldChange={updateFieldChange} buttonRef={buttonRef}/>
                        </Box>
                    </CustomTabPanel>
                }
                {tabValue == 'ltCable' &&
                    <CustomTabPanel value={tabValue} index={'ltCable'}>
                        <Box sx={{p: 2}}>
                            <LtCable project={project} categoryName={tabValue} allParameters={allParameterCategories} proceedToNextTab={proceedToNextTab} formState={formState} setFormState={setFormState} updateFieldChange={updateFieldChange} updateToHeadCount={updateToHeadCount} buttonRef={buttonRef}/>
                        </Box>
                    </CustomTabPanel>
                }
            </Box>
        </>
    );
};

export default SiteConditionTab;

import {Box, Tab, Tabs} from "@mui/material";
import {a11yProps, CustomTabPanel} from "../../../../utils/TabsUtils";
import {SyntheticEvent, useEffect, useState} from "react";
import SelectPackage from "../../../../components/package-tabs/SelectPackage";
import {api} from "../../../../utils/Axios";
import Loader from "../../../../components/Loader";
import PackageList from "../../../../components/package-tabs/PackageList";
import EventList from "../../../../components/package-tabs/EventList";

const PackageTab = (props: any) => {
    const [isLoading, setIsLoading] = useState(false);
    const [vendorSelectionInProgress, setVendorSelectionInProgress] = useState(false);
    const [packageTabValue, setPackageTabValue] = useState("pending");
    const [boq, setBoq] = useState<any>({});
    const [selectedPackage, setSelectedPackage] = useState<any>({});
    const [revision, setRevision] = useState<any>({});
    const [tabs, setTabs] = useState([
        {id: "pending", name: "Pending",},
        {id: "created", name: "All Packages",},
        {id: "event", name: "Events",},
    ]);

    const handlePackageTabChange = (event: SyntheticEvent, newValue: string) => {
        setPackageTabValue(newValue);
    };

    const loadProjectDetails = () => {
        setIsLoading(true);
        api
            .get(`/procurement/project/project-details/${props.project.id}`)
            .then((response) => {
                return response.data;
            })
            .then((res) => {
                props.project.projectDetails = res;
                setIsLoading(false);
            })
            .catch((ex) => {
                console.log(ex);
                setIsLoading(false);
            });
    };

    const loadBoQ = () => {
        setIsLoading(true);
        api.get(`/procurement/boq/get-by-project/${props.project.id}`).then((response) => {
            return response.data;
        }).then(res => {
            setBoq(res);
            setIsLoading(false);
        }).catch(ex => {
            console.log(ex);
            setIsLoading(false);
        });
    }

    const handlePackageListEventCallback = (data: any) => {
        if (data.event == 'edit') {
            setSelectedPackage(data.selectedRow);
            setPackageTabValue("pending");
        } else if (data.event == 'reload') {
            handlePackageCreated();
        } else if (data.event === "event") {
            setPackageTabValue("event");
        } else {
            props.proceedTabChange("pr");
        }
    };
    const handlePackageListCallback = () => {
        props.proceedTabChange("pr");
    };

    const handlePendingCallback = () => {
        getPackageCounter();
    };

    const getPackageCounter = () => {
        api
            .get(`/procurement/package/get-counter/${props.project.id}`)
            .then((response) => {
                return response.data;
            })
            .then((response: any) => {
                setTabs(prevTabs =>
                    prevTabs.map(tab => {
                        if (tab.id === "created") {
                            return {...tab, name: "All Packages (" + response?.created + ")"};
                        }
                        if (tab.id === "event") {
                            return {...tab, name: "Events (" + response?.event + ")"};
                        }
                        return tab;
                    })
                );
            })
            .catch((ex) => {
                console.log(ex);
            });
    };

    const handleRefresh = () => {
        setIsLoading(true);

        api.get(`/procurement/project/primary-details/${props.project.id}`).then((response) => {
            return response.data;
        }).then((response: any) => {
            if (response) {
                if (response.status !== "VENDOR_ASSIGNMENT") {
                    setVendorSelectionInProgress(false);
                    loadBoQ();
                    getPackageCounter();
                }
            }
        }).catch(ex => {
            console.log(ex);
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const assignVendorPackage = () => {
        setIsLoading(true);

        api.get(`/procurement/package/assign-vendor/${props.project.id}`).then((response) => {
            return response.data;
        }).then((response: any) => {
            if (response) {
                if (response.status == "VENDOR_ASSIGNMENT") {
                    setVendorSelectionInProgress(true);
                }
            }
        }).catch(ex => {
            console.log(ex);
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const loadRevision = () => {
        setIsLoading(true);

        api.get(`/procurement/estimation/revision/published/${props.project.id}`).then((response) => {
            return response.data;
        }).then((response: any) => {
            if (response) {
                setRevision(response);
            }
        }).catch(ex => {
            console.log(ex);
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const handlePackageCreated = () => {
        setSelectedPackage({});
        loadBoQ();
        getPackageCounter();
    };

    useEffect(() => {
        loadBoQ();
        getPackageCounter();
        if (props.project.status == "VENDOR_ASSIGNMENT") {
            setVendorSelectionInProgress(true);
        }
    }, [props.project.id]);

    useEffect(() => {
        loadBoQ();
        if (props.project.projectDetails == null) {
            loadProjectDetails();
        }
    }, []);

    useEffect(() => {
        getPackageCounter();
    }, [packageTabValue]);

    useEffect(() => {
        loadRevision();
    }, []);

    if (isLoading) {
        return <Loader/>;
    }

    return (
        <>
            {(boq.hasOwnProperty("id") && boq?.id != null) ? (
                <Box>
                    <Tabs
                        value={packageTabValue}
                        onChange={handlePackageTabChange}
                        indicatorColor="secondary"
                        variant={"fullWidth"}
                        sx={{
                            width: "40%",
                            fontFamily: 'Lato',
                            "& .Mui-selected": {
                                backgroundColor: "primary.main",
                                color: "#fff!important",
                                fontWeight: 700,
                            },
                        }}
                        aria-label="wrapped label tabs example"
                        TabIndicatorProps={{
                            sx: {
                                backgroundColor: "primary.main",
                            },
                        }}
                    >
                        {tabs.map((tab: any, index: number) => (
                            <Tab
                                key={tab.id + "_" + index}
                                {...a11yProps(tab.id, true)}
                                value={tab.id}
                                label={tab.name}
                                // disabled={tabValue == tab.id ? false : true}
                                wrapped
                                sx={{border: 1, borderColor: "divider"}}
                            />
                        ))}
                    </Tabs>

                    <CustomTabPanel value={packageTabValue} index={"pending"}>
                        <Box sx={{pt: 2, pb: 5}}>
                            <SelectPackage
                                showUpdatedCharges={props.showUpdatedCharges}
                                boq={boq}
                                revision={revision}
                                categories={props.categories}
                                project={props.project}
                                selectedPackage={selectedPackage}
                                vendorSelectionInProgress={vendorSelectionInProgress}
                                handlePendingCallback={handlePendingCallback}
                                handlePackageCreated={handlePackageCreated}
                                assignVendorPackage={assignVendorPackage}
                                handleRefresh={handleRefresh}
                            />
                        </Box>
                    </CustomTabPanel>
                    <CustomTabPanel value={packageTabValue} index={"created"}>
                        <Box sx={{pt: 2, pb: 5}}>
                            <PackageList
                                showUpdatedCharges={props.showUpdatedCharges}
                                project={props.project}
                                revision={revision}
                                categories={props.categories}
                                handleCallback={handlePackageListEventCallback}
                                handlePendingCallback={handlePendingCallback}
                            />
                        </Box>
                    </CustomTabPanel>
                    <CustomTabPanel value={packageTabValue} index={"event"}>
                        <Box sx={{pt: 2, pb: 5}}>
                            <EventList
                                showUpdatedCharges={props.showUpdatedCharges}
                                project={props.project}
                                categories={props.categories}
                                handleCallback={handlePackageListCallback}
                            />
                        </Box>
                    </CustomTabPanel>
                </Box>
            ) : (
                <Box sx={{height: '100%', minHeight: "300px"}} className={"center-aligned"}>
                    <Loader/>
                </Box>
            )}
        </>
    );
};

export default PackageTab;

import {getStyle} from "../../../utils/ModalUtils";
import React, {useEffect, useState} from "react";
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    Modal,
    Radio,
    RadioGroup,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import {Link} from "react-router-dom";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import ReadMore from "../../read-more/ReadMore";

const EventVendorDetailsPopup = (props: any) => {
    const {eventVendor} = props;
    const style = getStyle(55, 65, 15, 1);
    const [open, setOpen] = useState(false);

    let statusLabel = "Rejected";
    let statusColor = "#1565c0";
    if (eventVendor?.status == "REJECTED") {
        statusColor = "#d32f2f";
    }
    if (eventVendor.status == "CREATED" || eventVendor.status == "PARTICIPATED") {
        statusLabel = "Not Submitted";
    }
    if (eventVendor.status == "SUBMITTED") {
        statusLabel = "Bid Submitted";
    }

    const handleClose = () => {
        setOpen(false);
        props.handlePopupCallback();
    }

    useEffect(() => {
        if (props.openPopup === true) {
            setOpen(true);
        }
    }, [props.openPopup]);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <Box sx={style.main}>
                <Box sx={{position: "relative", height: "100%"}}>
                    <Box sx={style.header}>
                        <Box sx={style.headerContainer}>
                            <Box>
                                <Typography variant={"h5"} sx={{fontWeight: 700}}>
                                    Vendor Details: {eventVendor.vendor.name}
                                </Typography>
                            </Box>
                            <Box sx={{ml: "auto"}}>
                                <CloseIcon sx={{cursor: "pointer"}} onClick={handleClose}/>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={"modal-body"} sx={style.body}>
                        <Box sx={style.bodyContainer}>
                            <Box>
                                <TableContainer
                                    component={"div"}
                                    sx={{border: "1px solid", borderColor: "divider", borderBottom: 0}}
                                >
                                    <Table sx={{minWidth: 650}} aria-label="simple table">
                                        <TableHead sx={{backgroundColor: "info.contrastText"}}>
                                            <TableRow>
                                                <TableCell align={"left"}>Vendor Name</TableCell>
                                                <TableCell align={"left"}>Vendor Active?</TableCell>
                                                <TableCell align={"left"}>Vendor Event Status</TableCell>
                                                <TableCell align={"left"}>Documents Added</TableCell>
                                                <TableCell align={"left"}>Questions Answered</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow key={eventVendor.vendorId}>
                                                <TableCell align={"left"}>
                                                    <Link to={`/vendors/details/${eventVendor.vendorId}`}
                                                          target={"_blank"}>
                                                        {eventVendor.vendor.name}
                                                    </Link>
                                                </TableCell>
                                                <TableCell align={"left"}>
                                                    <Typography
                                                        sx={{textTransform: 'capitalize'}}>{eventVendor?.vendor.status.toLowerCase()}</Typography>
                                                </TableCell>
                                                <TableCell align={"left"}>
                                                    <Typography
                                                        sx={{
                                                            textTransform: "capitalize",
                                                            border: 1,
                                                            color: 'common.white',
                                                            borderColor: `${statusColor}`,
                                                            borderRadius: 1,
                                                            p: 0.6,
                                                            backgroundColor: `${statusColor}`,
                                                            minWidth: "100px",
                                                            textAlign: "center",
                                                            width: "60%",
                                                        }}
                                                    >
                                                        {statusLabel}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align={"left"}>
                                                    {eventVendor.vendorDocuments.length}
                                                </TableCell>
                                                <TableCell align={"left"}>
                                                    {eventVendor.vendorQuestions.length}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {eventVendor.status == "REJECTED" && (
                                    <Typography sx={{py: 2}}><strong>Bid Reject
                                        Reason</strong>: {eventVendor?.rejectReason}</Typography>
                                )}
                                {eventVendor.vendorDocuments.length > 0 && (
                                    <Box sx={{py: 2}}>
                                        <Typography sx={{fontSize: '18px', fontWeight: 700}}>
                                            Vendor Documents ({eventVendor.vendorDocuments.length})
                                        </Typography>

                                        {eventVendor.vendorDocuments.map((vendorDocument: any, docIndex: number) => (
                                            <Box key={"client-document-" + docIndex}
                                                 className={"space-between"}>
                                                <Box className={"space-between"}
                                                     sx={{border: 1, p: 1, mt: 1, width: '65%'}}>
                                                    <Box className={"vertically-center-aligned"}
                                                         sx={{pr: 1}}>
                                                        {vendorDocument.type == "link" ?
                                                            <InsertLinkIcon/> : <InsertDriveFileOutlinedIcon/>}
                                                    </Box>
                                                    <Typography
                                                        className={"vertically-center-aligned"}
                                                        sx={{wordBreak: "break-all"}}>{vendorDocument.name}</Typography>
                                                    <Link to={vendorDocument?.documentLink}
                                                          target={"_blank"}
                                                          className={"vertically-center-aligned"}
                                                          style={{paddingRight: 1}}>
                                                        View
                                                    </Link>
                                                </Box>
                                                {vendorDocument.comment !== "" && (
                                                    <Box className={"vertically-center-aligned"}
                                                         sx={{px: 1, width: '35%'}}>
                                                        <ReadMore text={"Comment: " + vendorDocument.comment}
                                                                  maxChars={20}/>
                                                    </Box>
                                                )}
                                            </Box>
                                        ))}
                                    </Box>
                                )}
                                {props.questions.length > 0 && eventVendor.vendorQuestions.length > 0 && (
                                    <Box>
                                        <Typography sx={{fontSize: '18px', fontWeight: 700}}>
                                            Questions
                                        </Typography>
                                        <Typography sx={{py: 1, fontSize: '12px'}}>
                                            Mandatory questions are marked with *.
                                        </Typography>
                                        {props.questions.map((clientQuestion: any, questionIndex: number) => {
                                                let submittedAnswers: any = [];
                                                if (eventVendor.vendorQuestions.length > 0) {
                                                    eventVendor.vendorQuestions.map((vendorQuestion: any) => {
                                                        if (vendorQuestion.eventQuestionnaireId == clientQuestion.id) {
                                                            submittedAnswers = vendorQuestion.answers;
                                                        }
                                                    });
                                                }
                                                if (submittedAnswers.length > 0) {
                                                    return (
                                                        <Box key={"question-" + questionIndex}>
                                                            <Typography sx={{py: 2}}>
                                                                {questionIndex + 1}. {clientQuestion.question}
                                                                {clientQuestion.mandatory && (
                                                                    <sup>*</sup>
                                                                )}
                                                            </Typography>
                                                            <Box sx={{width: '60%', px: 2}}>
                                                                <Typography sx={{pb: 1}}>
                                                                    <strong>Answer</strong>: {submittedAnswers.join(", ")}
                                                                </Typography>
                                                                {clientQuestion.questionType == "radio" ? (
                                                                    <FormControl>
                                                                        <RadioGroup
                                                                            row
                                                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                                                            name={clientQuestion.id}
                                                                            defaultValue={submittedAnswers.length > 0 ? submittedAnswers[0] : ""}
                                                                        >
                                                                            {
                                                                                clientQuestion.answers.map((option: any, index: number) => (
                                                                                    <FormControlLabel
                                                                                        key={`radio-button-form-${index}-${clientQuestion.id}`}
                                                                                        value={option}
                                                                                        control={<Radio disabled/>}
                                                                                        label={option}/>
                                                                                ))
                                                                            }
                                                                        </RadioGroup>
                                                                    </FormControl>
                                                                ) : (
                                                                    clientQuestion.questionType == "checkbox" ? (
                                                                        clientQuestion.answers.map((option: any, answerIndex: number) => {
                                                                            return (
                                                                                <FormControlLabel
                                                                                    key={`checkbox-form-${clientQuestion.id}-` + answerIndex}
                                                                                    label={option}
                                                                                    control={
                                                                                        <Checkbox
                                                                                            disabled
                                                                                            checked={submittedAnswers.length > 0 && submittedAnswers.some((obj: any) => {
                                                                                                return obj === option
                                                                                            })}
                                                                                            name={clientQuestion.id}
                                                                                            value={option}
                                                                                        />
                                                                                    }
                                                                                />
                                                                            );
                                                                        })
                                                                    ) : (
                                                                        <TextField
                                                                            fullWidth
                                                                            disabled
                                                                            size={"small"}
                                                                            variant={"outlined"}
                                                                            className={"inputBox"}
                                                                            name={clientQuestion.id}
                                                                            required={clientQuestion.mandatory}
                                                                            defaultValue={submittedAnswers.length > 0 ? submittedAnswers[0] : ""}
                                                                            InputProps={{
                                                                                style: {
                                                                                    borderRadius: "5px",
                                                                                    borderColor: 'primary.light',
                                                                                    fontSize: "0.8rem",
                                                                                },
                                                                            }}
                                                                        />
                                                                    )
                                                                )}
                                                            </Box>
                                                        </Box>
                                                    )
                                                }
                                            }
                                        )}
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}

export default EventVendorDetailsPopup;
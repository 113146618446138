import {Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {api} from "../../../../utils/Axios";

const EstimationSkuDetails = (props: any) => {
    const {sku, category, subCategory} = props;
    console.log(sku);
    const [isLoading, setIsLoading] = useState(false);

    return (
        <>
            {sku.hasOwnProperty("id") &&
                <Grid container spacing={2}>
                    <Grid item xs>
                        <Box display="flex" className={"show-view-details-sku"}
                             sx={{padding: "2rem", border: 1, borderTop: 0, borderColor: 'divider'}}>
                            <Box padding={"0 4rem"} sx={{width: "380px"}}>
                                <Box display="flex" alignItems="flex-start"
                                     justifyContent={"space-between"} padding={"4px"}>
                                    <Typography textAlign={"start"}>Category Name</Typography>
                                    <Typography textAlign={"start"}
                                                fontWeight={600}>{category.name}</Typography>
                                </Box>
                                <Box display="flex" alignItems="flex-start"
                                     justifyContent={"space-between"} padding={"4px"}>
                                    <Typography>Sub Category name</Typography>
                                    <Typography textAlign={"start"}
                                                fontWeight={600}>{subCategory.name}</Typography>
                                </Box>
                                <Box display="flex" alignItems="flex-start"
                                     justifyContent={"space-between"} padding={"4px"}>
                                    <Typography>Sku Name</Typography>
                                    <Typography textAlign={"start"}
                                                fontWeight={600}>{sku?.name}</Typography>
                                </Box>
                                <Box display="flex" alignItems="flex-start"
                                     justifyContent={"space-between"} padding={"4px"}>
                                    <Typography>Sku Code</Typography>
                                    <Typography textAlign={"start"}
                                                fontWeight={600}>{sku?.code}</Typography>
                                </Box>
                                <Box display="flex" alignItems="flex-start"
                                     justifyContent={"space-between"} padding={"4px"}>
                                    <Typography>Sku UOM</Typography>
                                    <Typography textAlign={"start"}
                                                fontWeight={600}>{sku?.uom}</Typography>
                                </Box>
                            </Box>
                            {(sku?.skuOptions && sku.skuOptions.length > 0) &&
                                <TableContainer sx={{width: "400px"}}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow
                                                sx={{
                                                    th: {
                                                        border: 1,
                                                        borderColor: "divider",
                                                        backgroundColor: "info.light",
                                                        fontWeight: "700",
                                                    },
                                                }}
                                            >
                                                <TableCell>Sku Option Name</TableCell>
                                                <TableCell>Sku Option Value</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {sku?.skuOptions.map((skuOption: any) => (
                                                <TableRow sx={{border: 1, borderColor: 'divider'}}>
                                                    <TableCell
                                                        sx={{border: 1, borderColor: 'divider'}}>{skuOption?.name}</TableCell>
                                                    <TableCell
                                                        sx={{border: 1, borderColor: 'divider'}}>{skuOption?.value}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            }
                        </Box>
                    </Grid>
                    {sku?.entityPrices && sku.entityPrices > 0 && (
                        <Grid item xs>

                        </Grid>
                    )}
                </Grid>
            }
        </>
    )
}

export default EstimationSkuDetails;
import {SyntheticEvent, useEffect, useState} from "react";
import {Box, Button, Typography} from "@mui/material";
import {CustomTabPanel} from "../../../../utils/TabsUtils";
import ECatalogDialog from "../../../../components/ecatalog/ECatalogDialog";
import SearchBar from "../../../../components/filter/SearchBar";
import RemoveFilters from "../../../../components/filter/RemoveFilters";
import {api} from "../../../../utils/Axios";
import Modal from '@mui/material/Modal';
import CancelIcon from '@mui/icons-material/Cancel';
import ECatalogSubCategoryTab from "../../../../components/tabs/ECatalogSubCategoryTab";
import EstimationSkuSearchList from "../../../../components/estimation/popups/sku-popup/EstimationSkuSearchList";
import ECatalogLoader from "../../../../components/ecatalog/ECatalogLoader";

const ECatalogTab = (props: any) => {
    const [catalogSubCategories, setCatalogSubCategories] = useState<any>([]);
    const [selectedSubCategory, setSelectedSubCategory] = useState<any>({});
    const [subCategoryTabValue, setSubCategoryTabValue] = useState("");
    const [open, setOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [appliedFilters, setAppliedFilters] = useState({});
    const [priceRange, setPriceRangeValue] = useState<number[]>([]);
    const priceRangeMinDistance = 100;
    const [assignedQty, setAssignedQty] = useState<number>(0);
    const [availableQty, setAvailableQty] = useState<number>(0);
    const [selectOptions, setSelectOptions] = useState<any>({"make": [], "model": [], 'item_category': []});
    const [make, setMake] = useState("");
    const [defaultModelOptions, setDefaultModelOptions] = useState([]);
    const [priceSlide, setPriceSlide] = useState("");
    const [priceType, setPriceType] = useState("");
    const [skuLabelWithQty, setSkuLabelWithQty] = useState<any>({});
    const [isApiLoading, setIsApiLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [itemCategories, setItemCategories] = useState<any>([]);
    const [selectedItemCategory, setSelectedItemCategory] = useState<any>("");
    const [enableItemCategoryFilter, setEnableItemCategoryFilter] = useState(false);
    const [statusCheck, setStatusCheck] = useState(false);

    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);

    const handleFilterOptions = (key: string, data: any[]) => {
        const newSelectionOptions = {...selectOptions, [key]: data};

        setSelectOptions(newSelectionOptions);
    }

    const handleSubCategoryTabChange = (event: SyntheticEvent, newValue: string) => {
        setSelectedItemCategory("");
        setSubCategoryTabValue(newValue);
        setAppliedFilters({});
        loadEstimationDetails(newValue);
    };

    const updateApiLoading = (loading: boolean) => {
        setIsApiLoading(loading);
    }

    const handleContinue = () => {
        setOpen(true);
    }

    const handleClose = (value: any) => {
        setOpen(false);
        if (value == "proceed") {
            props.proceedTabChange('summary');
        }
    };

    const handelSearchFilter = (query: any) => {
        setSearchQuery(query);
    }

    const handleFilterChange = (appliedParams: any) => {
        if(appliedParams.hasOwnProperty("item_category")) {
            itemCategories.map((itemCategory: any) => {
                if(itemCategory.name == appliedParams['item_category']) {
                    setSelectedItemCategory(itemCategory.id);
                }
            })
        } else {
            if(enableItemCategoryFilter && selectedItemCategory != "all") {
                setSelectedItemCategory('all');
            }
        }
        setAppliedFilters(appliedParams);

        let update = true;
        let updatePriceSlide = true;

        if (Object.keys(appliedParams).length > 0) {
            Object.entries(appliedParams).map(([name, value]: any) => {
                if (name == "make") {
                    setMake(value);
                    update = false;
                }
                if (name === "price") {
                    setPriceSlide(value.toString());
                    updatePriceSlide = false;
                }
                if (name === "price_type") {
                    setPriceType(value.toString());
                }
            });
        }
        if (update) {
            setMake("");
        }
        if (updatePriceSlide) {
            setPriceSlide("");
        }

    }

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        maxHeight: 500,
        bgcolor: 'background.paper',
        boxShadow: 24,
        padding: "25px 16px"
    };

    const updateAssignedQty = (qty: number) => {
        setAssignedQty(qty);
    }
    const loadEstimationDetails = (subCategoryId: any) => {
        let url = `/procurement/estimation/get-ff-category-selected-items/${props.project.id}/${subCategoryId}?revisionId=${props?.revision?.id}`;
        api.get(url)
            .then((response) => {
                return response.data;
            })
            .then((response: any) => {
                let availQty: number = 0;
                let localSkuLabelWithQty: any = {};
                if (Array.isArray(response) && response.length > 0) {
                    response.forEach((estimation: any) => {
                        availQty += (estimation.estimationQuantity ? estimation.estimationQuantity : 0);
                        console.log(estimation.estimationSkus);
                        if (estimation?.estimationSkus) {
                            localSkuLabelWithQty = {...localSkuLabelWithQty, ...estimation.estimationSkus};
                        }
                    });
                }
                console.log("localSkuLabelWithQty");
                console.log(localSkuLabelWithQty);
                setAvailableQty(availQty);
                setSkuLabelWithQty(localSkuLabelWithQty);
            })
            .catch((ex) => {
                console.log(ex);
            });
    };

    const loadItemCategories = (subCategory: any) => {
        //setSelectOptions({"make": [], "model": [], 'item_category': []});
        //handleFilterOptions('item_category', []);
        

        api.get(`/catalogue/item-category/list`, {params: {'sub_category_id': subCategory.id}})
            .then((response) => {
                return response.data;
            })
            .then((response: any) => {
                let currentOptions:any = selectOptions;
                currentOptions.item_category = [];

                let itemCategoryData = [];
                if (subCategory.code == "FL") {
                    response.forEach((itemCategory: any) => {
                        if (itemCategory.code == "DM") {
                            itemCategoryData.push(itemCategory);
                            setSelectedItemCategory(itemCategory.id);
                            setEnableItemCategoryFilter(false);
                        }
                    });
                } else {
                    itemCategoryData = response;
                    setSelectedItemCategory("all");
                    setEnableItemCategoryFilter(true);
                    let itemCategoryOptions = itemCategoryData.map((itemCategory: any) => {
                        return {id: itemCategory.name, label: itemCategory.name};
                    });

                    //handleFilterOptions('item_category', itemCategoryOptions)
                    currentOptions.item_category =  itemCategoryOptions;
                    setSelectOptions(currentOptions);
                }

                setItemCategories(itemCategoryData);
            })
            .catch((ex) => {
                console.log(ex);
            });
    }

    const loadPriceRangeData = (itemCategoryId: string, subCategoryId: any, reset: any) => {
        const params = new URLSearchParams();
        params.append("price_type", priceType.toString());
        api.get(`/catalogue/item-category/item-price-range/${subCategoryId}/${itemCategoryId}`, {
            params: params
        })
            .then((response) => {
                return response.data;
            })
            .then((response: any) => {
                let maxPrice = response?.maxPrice && (parseInt(response.maxPrice.toString()) > 10) ? parseInt(response.maxPrice.toString()) : 500000;

                let rem = maxPrice % 100;
                let adNum = 100 - rem;
                maxPrice = maxPrice + adNum;

                setPriceRangeValue([0, maxPrice]);

                if (Object.keys(appliedFilters).length > 0 && reset) {
                    let setFilter = false;
                    let appliedValues: any = [];
                    Object.entries(appliedFilters).map(([name, value]: any) => {
                        appliedValues[name] = value;
                        if (name === "price") {
                            appliedValues["price"] = [0, maxPrice].toString();
                            appliedValues["price_type"] = priceType.toString();
                            setFilter = true;
                        }
                    });
                    if (setFilter) {
                        setAppliedFilters(appliedValues);
                    }
                }

            })
            .catch((ex) => {
                let minMaxPrices = [0, 200000];
                setPriceRangeValue(minMaxPrices);
                console.log(ex);
            });

    };

    const loadMakeNModelData = (itemCategoryId: string, subCategoryId: any) => {
        let url = `/catalogue/item-category/item-make-model/${subCategoryId}/${itemCategoryId}`;
        const params = new URLSearchParams();
        if (Object.keys(appliedFilters).length > 0) {
            Object.entries(appliedFilters).map(([name, value]: any) => {
                if (name == "price") {
                    params.append(name, value.toString());
                    params.append("price_type", priceType.toString());
                }
            });
        }
        let currentOptions:any = selectOptions;
        currentOptions.make = [];
        currentOptions.model = [];

        api.get(url, {
            params: params,
        })
            .then((response) => {
                return response.data;
            })
            .then((response: any) => {
                if (Array.isArray(response.makes) && response.makes.length > 0) {
                    let makeOptions = response.makes.map((make: any) => {
                        return {id: make, label: make};
                    })
                    currentOptions.make = makeOptions;
                    //handleFilterOptions('make', makeOptions);
                } 
                if (Array.isArray(response.models) && response.models.length > 0) {
                    let modelOptions = response.models.map((model: any) => {
                        return {id: model, label: model};
                    })
                    currentOptions.model = modelOptions;

                    //handleFilterOptions('model', modelOptions);
                    setDefaultModelOptions(modelOptions);
                }
                setSelectOptions(currentOptions);
            }).then(() =>  {
                if(make != "") {
                    loadModelByMakeValue();
                }
            })
            .catch((ex) => {
                console.log(ex);
            });
    };

    const loadModelByMakeValue = () => {
        let url = `/catalogue/item-category/models-by-make/${subCategoryTabValue}/${selectedItemCategory}/${make}`;

        const params = new URLSearchParams();
        if (Object.keys(appliedFilters).length > 0) {
            Object.entries(appliedFilters).map(([name, value]: any) => {
                if (name == "price") {
                    params.append(name, value.toString());
                    params.append("price_type", priceType.toString());
                }
            });
        }

        api.get(url, {
            params: params,
        })
            .then((response) => {
                return response.data;
            })
            .then((response: any) => {
                let options = selectOptions;
                let modelOptions: any[] = [];
                if (Array.isArray(response.models) && response.models.length > 0) {
                    modelOptions = response.models.map((model: any) => {
                        return {id: model, label: model};
                    })
                }

                setTimeout(() => {
                    handleFilterOptions("model", modelOptions);
                }, 10);

            })
            .catch((ex) => {
                setTimeout(() => {
                    handleFilterOptions("model", defaultModelOptions);
                }, 10);
                console.log(ex);
            });
    }

    const checkEstimationStatus = () => {
        let url = `/procurement/estimation/estimation-status/${props?.revision?.id}`;
        api.get(url)
        .then((response) => {
            setStatusCheck(true);
        })
        .catch((ex) => {
            setStatusCheck(true);
            console.log(ex);
        });
    }

    const updatePriceFilterType = (priceType: any) => {
        setPriceType(priceType);
    }

    useEffect(() => {
        if(statusCheck) {
            catalogSubCategories.forEach((subCategory: any) => {
                if (subCategory.id == subCategoryTabValue) {
                    setSelectedSubCategory(subCategory);
                    loadItemCategories(subCategory);
                    loadEstimationDetails(subCategoryTabValue);
                }
            });
        }
    }, [subCategoryTabValue, statusCheck]);

    useEffect(() => {
        if (selectedItemCategory) {
            setPriceType("price");
            loadMakeNModelData(selectedItemCategory, subCategoryTabValue);
            loadPriceRangeData(selectedItemCategory, subCategoryTabValue, true);
        }
    }, [selectedItemCategory]);

    useEffect(() => {
        loadMakeNModelData(selectedItemCategory, subCategoryTabValue);
    }, [priceSlide]);

    useEffect(() => {
        if (make && subCategoryTabValue && selectedItemCategory)
            loadModelByMakeValue();
        else if(make == "")
           loadMakeNModelData(selectedItemCategory, subCategoryTabValue); 
    }, [make]);

    useEffect(() => {
        if (priceType && subCategoryTabValue && selectedItemCategory)
            loadPriceRangeData(selectedItemCategory, subCategoryTabValue, true);
    }, [priceType])

    useEffect(() => {
        const enabledSubCategories: any = []
        props.categories.map((category: any) => {
            category.subCategories.filter((subCategory: any) => {
                if (subCategory?.ecatalogEnabled)
                    enabledSubCategories.push(subCategory);
            });
        });
        if (enabledSubCategories.length > 0) {
            setSubCategoryTabValue(enabledSubCategories[0].id || "");
        }
        setCatalogSubCategories(enabledSubCategories);
    }, [props.categories]);

    useEffect(() => {
        checkEstimationStatus();
    }, [])
    if (!statusCheck) {
        return <ECatalogLoader eCatalogError={false} margin={'235px'}/>
    }

    return (
        <Box sx={{position: "relative"}}>
            {isApiLoading && <Box sx={{
                position: "absolute",
                width: "100%",
                left: "0",
                top: "0%",
                height: "80vh",
                background: "transparent",
                zIndex: "3"
            }}></Box>}
            <ECatalogSubCategoryTab
                subCategoryTabValue={subCategoryTabValue}
                subCategories={catalogSubCategories}
                handleSubCategoryTabChange={handleSubCategoryTabChange}
                noBotttomBorder={true}
            />
            <Box
                sx={{
                    height: "calc(100vh - 135px)",
                    overflowY: "auto",
                    paddingTop: "5px",
                    pb: 5,
                }}
            >
                <Box sx={{display: "flex"}}>
                    <Box
                        sx={{flexBasis: "80%", border: "1px solid #d5d5d5", padding: "10px 20px", borderRadius: "3px"}}>
                        <Typography sx={{display: 'block', marginBottom: '10px', fontSize: "0.8rem"}}> <b>Available
                            Quantity:</b> {(availableQty - assignedQty) >= 0 ? (availableQty - assignedQty) : 0}
                        </Typography>
                        <Typography sx={{display: 'block', fontSize: "0.8rem"}}>
                            <b>Assigned Quantity:</b> {assignedQty}
                            {Object.keys(skuLabelWithQty).length > 0 &&
                                <Typography sx={{
                                    display: 'inline-block',
                                    fontSize: "0.8rem",
                                    marginLeft: "30px",
                                    color: "blue",
                                    cursor: "pointer"
                                }} onClick={handleModalOpen}> View Engine Suggestions <span
                                    style={{verticalAlign: "middle"}}>{">"}</span></Typography>
                            }
                        </Typography>
                    </Box>
                    <Box sx={{display: 'flex', justifyContent: 'right', flexBasis: "20%", alignItems: "center"}}>
                        <Button variant={"outlined"} sx={{mr: 2, height: "38px"}} onClick={() => handleContinue()}>Proceed
                            to Estimation</Button>
                    </Box>
                </Box>
                <Modal
                    open={modalOpen}
                    onClose={handleModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={modalStyle}>
                        <Typography id="modal-modal-title" variant="h6" component="h2"
                                    sx={{fontSize: "1.2rem", fontWeight: "600", marginBottom: "20px"}}>
                            Engine Suggestions - {selectedSubCategory.name}'s
                        </Typography>
                        <CancelIcon onClick={handleModalClose}
                                    sx={{right: "15px", top: "28px", position: "absolute", cursor: "pointer"}}/>
                        {Object.keys(skuLabelWithQty).length > 0 && <Box sx={{marginTop: "10px"}}>
                            <Box sx={{
                                display: "flex",
                                flexBasis: "100%",
                                borderBottom: "1px solid #d5d5d5",
                                paddingBottom: "10px",
                                color: "#666"
                            }}>
                                <Box sx={{flexBasis: "35%"}}>AREA</Box>
                                <Box sx={{flexBasis: "50%"}}>NAME</Box>
                                <Box sx={{flexBasis: "15%"}}>COUNT</Box>
                            </Box>
                             <Box sx={{
                                display: "block",
                                height: '100%',
                                maxHeight: '370px',
                                overflow: 'scroll',
                            }}>
                                
                            
                            {Object.entries(skuLabelWithQty).map(([roomLabel, skuData]: any, index: number) => {
                                return (
                                    <>
                                    {Object.entries(skuData).map(([skuLabel, qty]: any) => {
                                        return (
                                        
                                            <Box sx={{
                                                display: "flex",
                                                flexBasis: "100%",
                                                border: "1px solid #d5d5d5",
                                                borderTop: "none",
                                                fontSize: "0.8rem"
                                            }}>
                                                <Box sx={{
                                                    flexBasis: "35%",
                                                    borderRight: "1px solid #d5d5d5",
                                                    padding: "15px"
                                                }}>{roomLabel != 'default' ?  roomLabel  : ""}</Box>
                                                <Box sx={{flexBasis: "50%", padding: "10px", fontWeight: "700", borderRight: "1px solid #d5d5d5",}}>{skuLabel}</Box>
                                                <Box sx={{flexBasis: "15%", padding: "10px", fontWeight: "700"}}>{qty}</Box>
                                            </Box>
                                        )
                                    })}
                                </>
                                );
                            })
                            }
                            </Box>

                        </Box>}
                        {Object.keys(skuLabelWithQty).length === 0 &&
                            <Box sx={{marginTop: "10px"}}>
                                <Box sx={{display: "flex", flexBasis: "100%", paddingBottom: "10px", color: "#666"}}>
                                    No suggestions found!
                                </Box>
                            </Box>
                        }
                    </Box>
                </Modal>

                {subCategoryTabValue !== "" && Object.keys(selectedSubCategory).length > 0 && selectedItemCategory !== "" && (
                    <CustomTabPanel value={subCategoryTabValue} index={subCategoryTabValue}>
                        <Box sx={{display: 'flex', justifyContent: 'right', py: 2}}>
                            <SearchBar
                                handleFilterChange={handleFilterChange}
                                filters={filters}
                                appliedFilters={appliedFilters}
                                showFilters={true}
                                showSearch={true}
                                showRangeSlider={true}
                                showItemCategoryFilter={enableItemCategoryFilter}
                                query={searchQuery}
                                handleSearchFilter={handelSearchFilter}
                                range={priceRange}
                                minDistance={priceRangeMinDistance}
                                selectOptions={selectOptions}
                                subCategory={selectedSubCategory}
                                updatePriceFilterType={updatePriceFilterType}
                            />
                        </Box>
                        <Box>
                            <RemoveFilters handleFilterChange={handleFilterChange} filters={filters}
                                           appliedFilters={appliedFilters}/>
                        </Box>
                        <ECatalogDialog
                            open={open}
                            onClose={handleClose}
                            confirmText={"Are you sure you want to proceed to Estimation?"}
                            nextTabValue={""}
                        />
                        <Box className={"e-catalog-block"} sx={{}}>
                            <EstimationSkuSearchList
                                revision={props.revision}
                                tab={"e-catalog"}
                                showUpdatedCharges={props.showUpdatedCharges}
                                subCategoryId={subCategoryTabValue}
                                subCategory={selectedSubCategory}
                                itemCategoryId={selectedItemCategory}
                                project={props.project}
                                appliedFilters={appliedFilters}
                                searchQuery={searchQuery}
                                updateAssignedQty={updateAssignedQty}
                                updateApiLoading={updateApiLoading}
                            />
                        </Box>
                    </CustomTabPanel>
                )}
            </Box>
        </Box>
    );
};

const filters = {
    'item_category': {
        element: 'select',
        type: 'text',
        placeholder: 'Search By Item Category',
        label: 'Item Category',
        name: 'item_category',
        options: [
            {id: '', label: 'Select'}
        ],
    },
    'make': {
        element: 'select',
        type: 'text',
        placeholder: 'Search By Make',
        label: 'Make',
        name: 'make',
        options: [
            {id: '', label: 'Select'},
            {id: 'HNI', label: 'HNI'},
            {id: 'Steelcase', label: 'Steelcase'},
        ],
    },
    'model': {
        element: 'select',
        type: 'text',
        placeholder: 'Search By Model',
        label: 'Model',
        name: 'model',
        options: [
            {id: '', label: 'Select'},
            {id: 'HNI', label: 'HNI'},
            {id: 'Steelcase', label: 'Steelcase'},
        ],
    },
    'price': {
        element: 'slider',
        type: 'text',
        placeholder: 'Search By Item Code',
        label: 'Price',
        name: 'price',
        range: [0, 100],
        distance: 10
    }
};

export default ECatalogTab;

import React, {useEffect, useState} from "react";
import Loader from "../../../Loader";
import {api} from "../../../../utils/Axios";
import {Box, Button, Checkbox, Grid, TextField, Typography} from "@mui/material";
import ReadMore from "../../../read-more/ReadMore";
import {getPriceInFormatted} from "../../../../utils/Common";
import EstimationSkuDetails from "./EstimationSkuDetails";
import SelectDeliveryArea from "./list-estimate/SelectDeliveryArea";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CloseIcon from '@mui/icons-material/Close';
import {openSnackbar} from "../../../common/features/snackbarSlice";
import {ERROR_COLOR} from "../../../../constants";
import {useAppDispatch} from "../../../../app/hooks";
import ECatalogSkuDetails from "./ECatalogSkuDetails";

const SimilarSkus = (props: any) => {
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [skus, setSkus] = useState([]);
    const [selectedSkuIds, setSelectedSkuIds] = useState<any>([]);
    const [selectedSkus, setSelectedSkus] = useState<any>([]);
    const [showViewDetails, setShowViewDetails] = useState<any>({});
    const [showDeliveryAreaDetails, setShowDeliveryAreaDetails] = useState<any>({});

    const handleClose = () => {
        props.handleSimilarSkuClose(props.skuId);
    }

    const saveSkuSelection = () => {
        setIsLoading(true);
        let errorFlag = false;
        const formSkus: any = [];
        let errorMessage = "";
        if (selectedSkus.length > 0) {
            selectedSkus.forEach((selectedSku: any) => {
                if (!errorFlag) {
                    let totalDeliveryAreaQuantity = 0;
                    // if ((selectedSku.cogsPrice == undefined || selectedSku.cogsPrice <= 0) && selectedSku.gsvPrice == 0) {
                    if (selectedSku.targetPrice == undefined || selectedSku.targetPrice <= 0) {
                        errorFlag = true;
                        errorMessage = "Please enter valid planned COGS for selected sku(s)";
                    }
                    if (selectedSku.nsvPercentage == undefined || selectedSku.nsvPercentage <= 0) {
                        errorFlag = true;
                        errorMessage = "Please enter valid nsv percentage for selected sku(s)";
                    }
                    if (selectedSku.sellingPrice == undefined || selectedSku.sellingPrice <= 0) {
                        errorFlag = true;
                        errorMessage = "Please enter valid gsv for selected sku(s)";
                    }
                    if (selectedSku.quantity == undefined || selectedSku.quantity <= 0) {
                        errorFlag = true;
                        errorMessage = "Please select quantity for selected sku(s)";
                    }

                    if (selectedSku.deliveryAreas == undefined || selectedSku.deliveryAreas == null || selectedSku.deliveryAreas == "") {
                        errorFlag = true;
                        errorMessage = "Please select delivery area for selected sku(s)";
                    } else {
                        selectedSku.deliveryAreas.map((deliveryAreaDetails: any) => {
                            totalDeliveryAreaQuantity += parseInt(deliveryAreaDetails.quantity);
                        });
                        if (totalDeliveryAreaQuantity != selectedSku.quantity) {
                            errorFlag = true;
                            errorMessage = "Delivery Area Quantity mismatch for selected sku(s)";
                        }
                    }

                    formSkus.push({
                        id: selectedSku.id,
                        entityType: selectedSku?.selectedEntity?.entityName,
                        entityId: selectedSku?.selectedEntity?.entityId,
                        quantity: selectedSku.quantity,
                        bufferQuantity: selectedSku?.bufferQuantity ? selectedSku?.bufferQuantity : 0,
                        sellingPrice: selectedSku?.sellingPrice,
                        targetPrice: selectedSku.targetPrice,
                        deliveryAreas: selectedSku.deliveryAreas,
                        nsvPercentage: selectedSku?.nsvPercentage,
                        itemId: selectedSku.itemId
                    });
                }
            });
        } else {
            errorFlag = true;
            errorMessage = "Please select sku from list to save";
        }

        if (!errorFlag) {
            let form = {
                revisionId: props.revision.id,
                skus: formSkus,
            };
            api.post(`/procurement/estimation/update-estimation-item-for-similar-skus`, form)
                .then((response) => {
                    return response.data;
                })
                .then((res) => {
                    setIsLoading(false);
                    props.handleSimilarSkuClose(props.skuId);
                })
                .catch((ex) => {
                    console.log(ex);
                    setIsLoading(false);
                });
        } else {
            setIsLoading(false);
            dispatch(
                openSnackbar({
                    message: errorMessage,
                    backgroundColor: ERROR_COLOR,
                })
            );
        }
    }

    const handleViewDetails = async (skuId: any) => {
        const newShowViewDetails = {...showViewDetails, [skuId]: !showViewDetails[skuId]};
        setShowViewDetails(newShowViewDetails);
    };

    const handleDeliveryAreaSelection = (sku: any) => {
        const newShowDeliveryAreaDetails = {...showDeliveryAreaDetails, [sku.id]: !showDeliveryAreaDetails[sku.id]};
        setShowDeliveryAreaDetails(newShowDeliveryAreaDetails);
    }

    const handleSkuSelection = (e: any, sku: any) => {
        const selected = e.target.checked;
        if (selected) {
            setSelectedSkus((prevSelectedSkus: any) => [...prevSelectedSkus, sku]);
            setSelectedSkuIds((prevSkuId: any) => [...prevSkuId, sku.id]);
        } else {
            setSelectedSkus((prevSelectedSkus: any) =>
                prevSelectedSkus.filter((selectedSku: any) => selectedSku.id !== sku.id)
            );
            setSelectedSkuIds((prevSkuId: any) =>
                prevSkuId.filter((id: any) => id !== sku.id)
            );
        }
        const newShowDeliveryAreaDetails = {...showDeliveryAreaDetails, [sku.id]: !showDeliveryAreaDetails[sku.id]};

        setShowDeliveryAreaDetails(newShowDeliveryAreaDetails);
    };

    const handleQuantitySelection = (event: any, sku: any) => {
        sku.quantity = event.target.value;
        setSelectedSkus((prevSelectedSkus: any) =>
            prevSelectedSkus.map((selectedSku: any) => {
                if (selectedSku.id === sku.id) {
                    return {...selectedSku, quantity: event.target.value};
                }
                return selectedSku;
            })
        )
    };

    const handleBufferQuantitySelection = (event: any, sku: any) => {
        sku.bufferQuantity = event.target.value;
        setSelectedSkus((prevSelectedSkus: any) =>
            prevSelectedSkus.map((selectedSku: any) => {
                if (selectedSku.id === sku.id) {
                    return {...selectedSku, bufferQuantity: event.target.value};
                }
                return selectedSku;
            })
        )
    };

    const handleCogsPrice = (event: any, sku: any) => {
        sku.cogsPrice = parseFloat(event.target.value);
        if (sku.cogsPrice != "" && sku.cogsPrice > 0) {
            if (sku.nsv > 0) {
                sku.gsvPrice = (sku.cogsPrice + (sku.cogsPrice * sku.nsv / 100)).toFixed(2);
            } else if (sku.gsvPrice > 0) {
                sku.nsv = ((parseFloat(sku.gsvPrice) - parseFloat(sku.cogsPrice)) / parseFloat(sku.cogsPrice) * 100).toFixed(2);
            }
        }
        setSelectedSkus((prevSelectedSkus: any) =>
            prevSelectedSkus.map((selectedSku: any) => {
                if (selectedSku.id === sku.id) {
                    return {...selectedSku, nsvPercentage: sku.nsv, targetPrice: sku.cogsPrice, sellingPrice: sku.gsvPrice};
                }
                return selectedSku;
            })
        );
    };

    const handleGsvPrice = (event: any, sku: any) => {
        sku.gsvPrice = parseFloat(event.target.value);
        if (sku.gsvPrice != "" && sku.gsvPrice > 0) {
            if (sku.nsv > 0) {
                sku.cogsPrice = (sku.gsvPrice - ((sku.gsvPrice * parseFloat(sku.nsv)) / 100)).toFixed(2);
            } else if (sku.cogsPrice > 0) {
                sku.nsv = ((sku.gsvPrice - sku.cogsPrice) / sku.cogsPrice * 100).toFixed(2);
            }
        }
        setSelectedSkus((prevSelectedSkus: any) =>
            prevSelectedSkus.map((selectedSku: any) => {
                if (selectedSku.id === sku.id) {
                    return {...selectedSku, nsvPercentage: sku.nsv, targetPrice: sku.cogsPrice, sellingPrice: sku.gsvPrice};
                }
                return selectedSku;
            })
        );
    };

    const handleNsv = (event: any, sku: any) => {
        sku.nsv = event.target.value;
        if (sku.nsv != "" && sku.nsv > 0) {
            if (sku.cogsPrice > 0) {
                sku.gsvPrice = (parseFloat(sku.cogsPrice) + (parseFloat(sku.cogsPrice) * sku.nsv / 100)).toFixed(2);
            } else if (sku.gsvPrice > 0) {
                let totalPercentage = (100 + parseFloat(sku.nsv));
                sku.cogsPrice = ((sku.gsvPrice * 100) / totalPercentage).toFixed(2);
            }
        }
        setSelectedSkus((prevSelectedSkus: any) =>
            prevSelectedSkus.map((selectedSku: any) => {
                if (selectedSku.id === sku.id) {
                    return {...selectedSku, nsvPercentage: sku.nsv, targetPrice: sku.cogsPrice, sellingPrice: sku.gsvPrice};
                }
                return selectedSku;
            })
        );
    };

    const handleDeliveryArea = (sku: any, data: any) => {
        let totalQty = 0;
        data.map((deliveryArea: any) => {
            totalQty += parseInt(deliveryArea.quantity);
        });
        setSelectedSkus((prevSelectedSkus: any) =>
            prevSelectedSkus.map((selectedSku: any) => {
                if (selectedSku.id === sku.id) {
                    return {...selectedSku, deliveryAreas: data, quantity: totalQty};
                }
                return selectedSku;
            })
        );
        sku.deliveryAreas = data;
        sku.quantity = totalQty;
        const newShowDeliveryAreaDetails = {...showDeliveryAreaDetails, [sku.id]: !showDeliveryAreaDetails[sku.id]};
        setShowDeliveryAreaDetails(newShowDeliveryAreaDetails);
    };

    const loadSelectedSkus = (skuDetails: any) => {
        const similarSkuIds = skuDetails.map((row: any) => row.id);
        console.log(skuDetails);
        let form = {'skuIds': similarSkuIds};
        api.post(`/procurement/estimation/get-estimation-details-for-skus/${props.revision.id}`, form)
            .then(response => response.data)
            .then(response => {
                const estimationSkus: any = [];
                if (Array.isArray(response) && response.length > 0) {
                    const skuIds: any[] = [];
                    response.forEach((sku: any) => {
                        let selectedSku = {...sku, id: sku.skuId};
                        estimationSkus.push(selectedSku);
                        skuIds.push(sku.skuId);
                    });
                    setSelectedSkuIds(skuIds);

                    setSelectedSkus(estimationSkus);
                }
                skuDetails.forEach((sku: any) => {
                    sku.targetPrice = sku.cogsPrice != null ? sku.cogsPrice : "";
                    sku.sellingPrice = sku.gsvPrice != null ? sku.gsvPrice : "";
                    sku.gsvPrice = sku.gsvPrice != null ? sku.gsvPrice : "";
                    sku.nsvPercentage = sku.nsv != null ? sku.nsv : "";
                    sku.quantity = "";
                    sku.bufferQuantity = "";
                    sku.deliveryAreas = [];

                    if (estimationSkus.length > 0) {
                        estimationSkus.forEach((selectedSku: any) => {
                            if (selectedSku.id === sku.id) {
                                sku.cogsPrice = selectedSku.targetPrice;
                                sku.quantity = selectedSku.quantity;
                                sku.bufferQuantity = selectedSku.bufferQuantity;
                                sku.deliveryAreas = selectedSku.deliveryAreas;
                                sku.gsvPrice = selectedSku.sellingPrice;
                                sku.nsv = selectedSku.nsvPercentage;
                            }
                        });
                    }
                });
                setSkus(skuDetails);
            })
            .catch(ex => {
                console.log(ex);
            });
    }

    const loadSimilarSkus = () => {
        setIsLoading(true);
        api.get(`/catalogue/sku/similar/${props.skuId}/${props.subCategoryId}`).then((response) => {
            return response.data;
        }).then((response: any) => {
            loadSelectedSkus(response);
        }).catch(ex => {
            console.log(ex);
        }).finally(() => {
            setIsLoading(false);
        });
    }
    useEffect(() => {
        loadSimilarSkus();
    }, [props.skuId]);

    if (isLoading) {
        return <Loader/>
    }

    return (
        <Box sx={{border: 1, borderColor: "#FAB12F", width:'100%'}}>
            {skus.length > 0 ? (
                <>
                    <Box sx={{backgroundColor: "#FAB12F", p: 2}}>
                        <Box sx={{display: 'flex', justifyContent: "space-between"}}>
                            <Box>
                                <Typography className={"vertically-center-aligned"}
                                            sx={{fontWeight: 400, fontSize: '12px', color: 'common.white'}}>
                                    <AutoAwesomeIcon/>
                                    <Typography component={"span"} sx={{px: 1}}>{skus.length} Similar Skus
                                        Found</Typography>
                                </Typography>
                            </Box>
                            <Box sx={{color: 'common.white', cursor: 'pointer'}} onClick={handleClose}>
                                <CloseIcon/>
                            </Box>
                        </Box>
                    </Box>
                    {skus.map((sku: any, index: number) => {
                        return (
                            <Box key={"sku-index-for-similar-sku-" + sku.id + "-" + index}>
                                <Grid
                                    container
                                    alignItems={"stretch"}
                                >
                                    <Grid
                                        item
                                        xs={0.5}
                                        sx={{
                                            border: 1,
                                            borderColor: "divider",
                                            pt: 2,
                                            borderTop: 0,
                                            textAlign: "center",
                                        }}
                                    >
                                        <Checkbox
                                            color={"info"}
                                            checked={selectedSkuIds.includes(sku.id)}
                                            onChange={(e: any) => handleSkuSelection(e, sku)}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={2.5}
                                        sx={{
                                            border: 1,
                                            borderColor: "divider",
                                            p: 1,
                                            borderLeft: 0,
                                            borderTop: 0,
                                        }}
                                    >
                                        <Grid container>
                                            <Grid item xs={4} sx={{pr: 1}}>
                                                {sku.primaryImage != null && (
                                                    <Box>
                                                        <img
                                                            src={sku?.primaryImage}
                                                            className={"sku-image"}
                                                            style={{width: "100%"}}
                                                            alt={sku.name}
                                                        />
                                                    </Box>
                                                )}
                                                {sku.primaryImage == null && (
                                                    <Box
                                                        sx={{
                                                            width: "100%",
                                                            height: "80px",
                                                            backgroundColor: "secondary.light",
                                                        }}
                                                    />
                                                )}
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Box>
                                                    <Typography
                                                        variant={"body1"}
                                                        sx={{fontSize: "0.9rem"}}
                                                    >
                                                        {sku.name}
                                                    </Typography>
                                                    <Box sx={{pt: 3}}>
                                                        <Typography
                                                            variant={"body2"}
                                                            sx={{fontSize: "0.8rem"}}
                                                        >
                                                            Label - {sku.code}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        item
                                        xs={2.2}
                                        sx={{
                                            border: 1,
                                            borderColor: "divider",
                                            p: 1,
                                            borderLeft: 0,
                                            borderTop: 0,
                                        }}
                                    >
                                        {props.showDescription ? (
                                            <ReadMore
                                                text={sku.description}
                                                maxChars={200}
                                                className={"sku-desc"}
                                            />
                                        ) : (
                                            <Box>
                                                {sku.options && sku.options.length > 0 ? (
                                                    sku.options.map((option: any, idx: number) => (
                                                        <Typography key={idx} variant={"body2"}
                                                                    sx={{
                                                                        fontSize: "0.8rem",
                                                                        padding: '0.4rem'
                                                                    }}>
                                                            <strong>{option.name}:</strong> {option.value}
                                                        </Typography>
                                                    ))
                                                ) : (
                                                    <Typography sx={{fontSize: "0.8rem", padding: '0.4rem'}}>
                                                        NA
                                                    </Typography>
                                                )}
                                            </Box>
                                        )}
                                    </Grid>


                                    <Grid
                                        item
                                        xs={1.2}
                                        sx={{
                                            border: 1,
                                            borderColor: "divider",
                                            p: 1,
                                            borderLeft: 0,
                                            borderTop: 0,
                                        }}
                                    >
                                        <Box
                                            className={"center-aligned"}
                                            sx={{height: "100%"}}
                                        >
                                            {selectedSkuIds.includes(sku.id) ? (
                                                <TextField
                                                    fullWidth
                                                    size={"small"}
                                                    type={"number"}
                                                    variant={"outlined"}
                                                    className={"inputBox"}
                                                    value={sku?.cogsPrice ? sku.cogsPrice : ""}
                                                    onChange={(e) => handleCogsPrice(e, sku)}
                                                    inputProps={{
                                                        type: "number"
                                                    }}
                                                    InputProps={{
                                                        style: {
                                                            borderRadius: "5px",
                                                            borderColor: "primary.light",
                                                            fontSize: "0.8rem"
                                                        },
                                                    }}
                                                />
                                            ) : (
                                                <Typography>
                                                    {sku?.cogsPrice ? getPriceInFormatted(sku.cogsPrice, props.project.country) : ""}
                                                </Typography>
                                            )}
                                        </Box>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={0.75}
                                        sx={{
                                            border: 1,
                                            borderColor: "divider",
                                            p: 1,
                                            borderLeft: 0,
                                            borderTop: 0,
                                            textAlign: "right",
                                        }}
                                    >
                                        <Box
                                            className={"center-aligned"}
                                            sx={{height: "100%"}}
                                        >
                                            {selectedSkuIds.includes(sku.id) ? (
                                                <TextField
                                                    fullWidth
                                                    size={"small"}
                                                    type={"number"}
                                                    variant={"outlined"}
                                                    className={"inputBox"}
                                                    value={sku?.nsv ? sku.nsv : ""}
                                                    onChange={(e) => handleNsv(e, sku)}
                                                    inputProps={{
                                                        type: "number",
                                                    }}
                                                    InputProps={{
                                                        style: {
                                                            borderRadius: "5px",
                                                            borderColor: "primary.light",
                                                            fontSize: "0.8rem"
                                                        },
                                                    }}
                                                />
                                            ) : (
                                                <Typography>
                                                    {sku?.nsv ? sku.nsv.toFixed(2) : ""}
                                                </Typography>
                                            )}
                                        </Box>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={1.25}
                                        sx={{
                                            border: 1,
                                            borderColor: "divider",
                                            p: 1,
                                            // pt: 2,
                                            borderLeft: 0,
                                            borderTop: 0,
                                            textAlign: "right",
                                        }}
                                    >
                                        <Box
                                            className={"center-aligned"}
                                            sx={{height: "100%"}}
                                        >
                                            {selectedSkuIds.includes(sku.id) ? (
                                                <TextField
                                                    fullWidth
                                                    size={"small"}
                                                    type={"number"}
                                                    variant={"outlined"}
                                                    className={"inputBox"}
                                                    value={sku?.gsvPrice}
                                                    onChange={(e) => handleGsvPrice(e, sku)}
                                                    inputProps={{
                                                        type: "number"
                                                    }}
                                                    InputProps={{
                                                        style: {
                                                            borderRadius: "5px",
                                                            borderColor: "primary.light",
                                                            fontSize: "0.8rem"
                                                        },
                                                    }}
                                                />
                                            ) : (
                                                <Typography>
                                                    {sku?.gsvPrice ? getPriceInFormatted(sku.gsvPrice, props.project.country) : ""}
                                                </Typography>
                                            )}
                                        </Box>
                                    </Grid>

                                    <Grid
                                        item
                                        xs={1.05}
                                        sx={{
                                            border: 1,
                                            borderColor: "divider",
                                            p: 1,
                                            borderLeft: 0,
                                            borderTop: 0,
                                        }}
                                    >
                                        <Box
                                            className={"center-aligned"}
                                            sx={{height: "100%"}}
                                        >
                                            {selectedSkuIds.includes(sku.id) ? (
                                                <TextField
                                                    fullWidth
                                                    disabled={!selectedSkuIds.includes(sku.id)}
                                                    size={"small"}
                                                    type={"number"}
                                                    variant={"outlined"}
                                                    className={"inputBox"}
                                                    value={sku?.quantity}
                                                    onChange={(e) => handleQuantitySelection(e, sku)}
                                                    InputProps={{
                                                        style: {
                                                            borderRadius: "5px",
                                                            borderColor: "primary.light",
                                                            fontSize: "0.8rem",
                                                        },
                                                    }}
                                                />
                                            ) : (
                                                <Typography>
                                                    {sku?.quantity ? sku.quantity : ""}
                                                </Typography>
                                            )}
                                        </Box>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={1.05}
                                        sx={{
                                            border: 1,
                                            borderColor: "divider",
                                            p: 1,
                                            borderLeft: 0,
                                            borderTop: 0,
                                        }}
                                    >
                                        <Box
                                            className={"center-aligned"}
                                            sx={{height: "100%"}}
                                        >
                                            {selectedSkuIds.includes(sku.id) ? (
                                                <TextField
                                                    fullWidth
                                                    size={"small"}
                                                    type={"number"}
                                                    variant={"outlined"}
                                                    className={"inputBox"}
                                                    value={sku?.bufferQuantity}
                                                    onChange={(e) => handleBufferQuantitySelection(e, sku)}
                                                    InputProps={{
                                                        style: {
                                                            borderRadius: "5px",
                                                            borderColor: "primary.light",
                                                            fontSize: "0.8rem",
                                                        },
                                                    }}
                                                />
                                            ) : (
                                                <Typography>
                                                    {sku?.bufferQuantity ? sku.bufferQuantity : ""}
                                                </Typography>
                                            )}
                                        </Box>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={1}
                                        sx={{
                                            border: 1,
                                            borderColor: "divider",
                                            p: 1,
                                            borderLeft: 0,
                                            borderTop: 0,
                                        }}
                                    >
                                        {(selectedSkuIds.includes(sku.id)) &&
                                            <Box className={"center-aligned"}
                                                 sx={{height: "100%", flexDirection: 'column'}}>
                                                <Typography sx={{fontSize: '0.7rem'}}>
                                                    {sku.deliveryAreas.length > 0 ? "Selected Delivery Areas" : "NA"}
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontSize: '0.7rem',
                                                        cursor: 'pointer',
                                                        color: 'primary.main'
                                                    }}
                                                    onClick={() => handleDeliveryAreaSelection(sku)}
                                                >
                                                    {!showDeliveryAreaDetails[sku.id] ? "Show" : "Hide"}
                                                </Typography>
                                            </Box>
                                        }
                                    </Grid>
                                    <Grid
                                        item
                                        xs={0.5}
                                        sx={{
                                            border: 1,
                                            borderColor: "divider",
                                            p: 1,
                                            borderLeft: 0,
                                            borderTop: 0,
                                        }}
                                    >
                                        <Box
                                            className={"center-aligned"}
                                            sx={{height: "100%"}}
                                        >
                                            <Button onClick={() => handleViewDetails(sku.id)}
                                                    sx={{fontSize: '0.7rem'}}>
                                                {showViewDetails[sku.id] ? "Hide Details" : "View Details"}
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                                {showViewDetails[sku.id] && (
                                    <ECatalogSkuDetails skuId={sku.id}/>
                                )}
                                {(showDeliveryAreaDetails[sku.id]) &&
                                    <SelectDeliveryArea
                                        sku={sku}
                                        handleDeliveryArea={handleDeliveryArea}
                                    />
                                }
                            </Box>
                        )
                    })}
                    <Box className={"right-align-content"} sx={{p: 2}}>
                        <Button
                            variant={"contained"}
                            sx={{fontSize: "0.8rem"}}
                            onClick={saveSkuSelection}
                        >
                            Save Similar Sku
                        </Button>
                    </Box>
                </>
            ) : (
                <>No Matching Skus</>
            )}
        </Box>
    )
}

export default SimilarSkus;
import PageHeading from "../../../components/page-heading/PageHeading";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {rolePageConfig} from "../config/roles";
import {api} from "../../../utils/Axios";
import Loader from "../../../components/Loader";
import ListPage from "../../../components/list-page/ListPage";
import {Box} from "@mui/material";
import SearchFilter from "../../../components/filter/SearchFilter";

const Role = () => {
    const pageConfigs: any = rolePageConfig;
    const [isLoading, setIsLoading] = useState(false);
    const [rows, rowChange] = useState([]);
    const [page, pageChange] = useState(0);
    const [sortColumn, setSortColumn] = useState("id");
    const [sortDirection, setSortDirection] = useState<any>("desc");
    const [size, setSize] = useState(10);
    const [totalElements, setTotalElements] = useState(1);
    const [searchQuery, setSearchQuery] = useState("");
    const navigate = useNavigate();

    const handelSearchFilter = (query: any) => {
        setSearchQuery(query);
    }

    useEffect(() => {
        loadRoles();
    }, [sortColumn, sortDirection, page, size, searchQuery]);

    const handleSort = (sortModel: any) => {
        setSortColumn(sortModel.field);
        setSortDirection(sortModel?.sort);
        pageChange(0);
    }

    const handlePagination = (newPagination: any) => {
        setSize(newPagination.pageSize);
        pageChange(newPagination.page);
    }

    const loadRoles = () => {
        setIsLoading(true);
        api.get("/authentication/role", {
            params: {
                pageNo: page,
                pageSize: size,
                sort: sortDirection?.toUpperCase(),
                sortByColumn: sortColumn,
                searchQuery
            }
        }).then((response) => {
            return response.data;
        }).then((response: any) => {
            rowChange(response.content);
            setTotalElements(response.totalElements);
            setIsLoading(false);
        }).catch(ex => {
            console.log(ex);
            setIsLoading(false);
        });
    }

    const handleHeaderButtonClick = (data: any) => {
        if (data == 'create') {
            navigate("/users/roles/create")
        }
    }

    const handleCallBack = (rowData: any, buttonData: any) => {
        if (buttonData.type == 'edit') {
            navigate(`/users/roles/edit/${rowData.id}`);
        }
    }

    if (isLoading) {
        return <Loader/>
    }
    return (
        <Box className={'common-space-container'}>
            <PageHeading
                title={"Roles"}
                showButtons={true}
                enableBackButton={true}
                buttons={[{id: 'create', label: 'Create Role'}]}
                handleHeaderButtonClick={handleHeaderButtonClick}
            />

            <Box sx={{pb: 2}} className={'right-align-content'}>
                <SearchFilter
                    showFilters={false}
                    showSearch={true}
                    query={searchQuery}
                    handleSearchFilter={handelSearchFilter}
                />
            </Box>
            <Box>
                <ListPage
                    rows={rows}
                    columns={pageConfigs.columns}
                    page={page}
                    size={size}
                    totalElements={totalElements}
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                    handlePagination={handlePagination}
                    handleSort={handleSort}
                    handleCallBack={handleCallBack}
                />
            </Box>
        </Box>
    );
}
export default Role;
import FormTabMain from "./FormTabMain";
import ParameterFormTab from "./ParameterFormTab";

const IbmsTab = (props: any) => {
    const {formState, setFormState } = props;
    const handleNextButton = () => {
        props.handleNext(props.categoryName)
    }

    let buttons = [
        {
            type: 'submit',
            color: 'primary.main',
            variant: 'contained',
            title: 'Save',
            permissions: ['edit-projects', "edit-estimation"],
        },
    ];

    return (
        <ParameterFormTab project={props.project} formState={formState} setFormState={setFormState} formFields={formFields} categoryName={props.categoryName} allParameters={props.allParameters} buttons={buttons} handleNextButton={handleNextButton} updateFieldChange={props.updateFieldChange} buttonRef={props.buttonRef}/>
    );
};

const formFields = [
    {
        groupName: 'Access Control System',
        fields: [
            {
                element: 'select',
                label: 'Type of ACS',
                name: 'typeOfAcs',
                required: true,
                width: 4,
                mainContainerWidth: 12,
                value: "Lenel",
                options: [
                    {id: 'Lenel', label: 'Lenel'},
                    {id: 'Pro Watch', label: 'Pro Watch'},
                    {id: 'Software house', label: 'Software house'},
                    {id: 'HID', label: 'HID'},
                    {id: 'Kantech/Pro 3000', label: 'Kantech/Pro 3000'},
                    {id: 'Spectra/Essl', label: 'Spectra/Essl'},
                    {id: 'Matrix', label: 'Matrix'},
                    {id: 'Spintly', label: 'Spintly'},
                ],
            },
            {
                element: 'input',
                label: 'Single door ACS',
                name: 'singleDoorAcs',
                required: true,
                type: 'number',
                width: 4,
                mainContainerWidth: 12,
            },
            {
                element: 'input',
                label: 'Double doors ACS',
                name: 'doubleDoorsAcs',
                required: true,
                type: 'number',
                width: 4,
                mainContainerWidth: 12,
            },
            {
                element: 'input',
                label: 'No. of ACS Doors',
                name: 'noOfAcsDoors',
                sumOf: ["singleDoorAcs", "doubleDoorsAcs"],
                errorMessage: "No of ACS doors should be equal to [Single door ACS + Double doors ACS]. Please provide correct value.",
                required: true,
                type: 'number',
                width: 4,
                mainContainerWidth: 12,
            },
            {
                element: 'input',
                label: 'No. of Fire Exit Doors',
                name: 'noOfFireExitDoors',
                required: true,
                type: 'number',
                width: 4,
                mainContainerWidth: 12,
            },
            {
                element: 'radio',
                label: 'Electric Strick Lock requirement',
                name: 'electricStrickLockRequirement',
                width: 4,
                mainContainerWidth: 12,
                value: 'Yes',
                options: [
                    {id: 'Yes', label: 'Yes'},
                    {id: 'No', label: 'No'},
                ],
            },
            {
                element: 'radio',
                label: 'Scope of Access Card',
                subLabel: 'If not specified select - No',
                name: 'scopeOfAccessCard',
                width: 4,
                mainContainerWidth: 12,
                value: 'Yes',
                options: [
                    {id: 'Yes', label: 'Yes'},
                    {id: 'No', label: 'No'},
                ],
            },
            {
                element: 'radio',
                label: 'Scope of Access Main Server',
                subLabel: 'If not specified select - No',
                name: 'scopeOfAccessMainServer',
                width: 4,
                mainContainerWidth: 12,
                value: 'Yes',
                options: [
                    {id: 'Yes', label: 'Yes'},
                    {id: 'No', label: 'No'},
                ],
            },
            {
                element: 'radio',
                label: 'Scope of Access Recording PC',
                subLabel: 'If not specified select - No',
                name: 'scopeOfAccessRecordingPc',
                width: 4,
                mainContainerWidth: 12,
                value: 'Yes',
                options: [
                    {id: 'Yes', label: 'Yes'},
                    {id: 'No', label: 'No'},
                ],
            },
            {
                element: 'radio',
                label: 'Type of  Reader for Critical Room',
                subLabel: 'If not specified select - Card',
                name: 'typeOfReaderForCriticalRoom',
                width: 4,
                mainContainerWidth: 12,
                value: 'Biometric',
                options: [
                    {id: 'Card', label: 'Card'},
                    {id: 'Biometric', label: 'Biometric'},
                ],
            },
        ],
    },
    {
        groupName: 'CCTV',
        fields: [
            {
                element: 'input',
                label: 'No. of CCTV',
                name: 'noOfCctv',
                required: true,
                type: 'number',
                width: 4,
                mainContainerWidth: 12,
            },
            {
                element: 'select',
                label: 'Type of CCTV',
                name: 'typeOfCctv',
                required: true,
                width: 4,
                mainContainerWidth: 12,
                value: 'HIGH',
                options: [
                    {id: 'HIGH', label: 'HIGH'},
                    {id: 'ECO', label: 'ECO'},
                    {id: 'MID', label: 'MID'},
                ],
            },
            {
                element: 'select',
                label: 'CCTV Mega Pix',
                name: 'CCTVMegaPix',
                required: true,
                width: 4,
                mainContainerWidth: 12,
                value: '5MP',
                options: [
                    {id: '2MP', label: '2MP'},
                    {id: '4MP', label: '4MP'},
                    {id: '5MP', label: '5MP'},
                ],
            },
            {
                element: 'select',
                label: 'Open office area/Corridor - CCTV',
                name: 'openOfficeAreaCorridorCctv',
                required: true,
                width: 4,
                mainContainerWidth: 12,
                value: '360°',
                options: [
                    {id: 'NA', label: 'NA'},
                    {id: 'Corridor', label: 'Corridor'},
                    {id: '360°', label: '360°'},
                ],
            },
            {
                element: 'select',
                label: 'Storage',
                name: 'storage',
                required: true,
                width: 4,
                mainContainerWidth: 12,
                value: '60 days Storage',
                options: [
                    {id: '30 days Storage', label: '30 days Storage'},
                    {id: '60 days Storage', label: '60 days Storage'},
                    {id: '90 days Storage', label: '90 days Storage'},
                ],
            },
            {
                element: 'select',
                label: 'Recording',
                name: 'recording',
                required: true,
                width: 4,
                mainContainerWidth: 12,
                value: 'Server Recording',
                options: [
                    {id: 'NVR Recording', label: 'NVR Recording'},
                    {id: 'Server Recording', label: 'Server Recording'},
                    {id: 'Cloud Recording (Client scope)', label: 'Cloud Recording (Client scope)'},
                    {id: 'Milestone Recording', label: 'Milestone Recording'},
                    {id: 'Genetec Recording', label: 'Genetec Recording'},
                ],
            },
            {
                element: 'select',
                label: 'Camera POE switch',
                name: 'CameraPOESwitch',
                required: true,
                width: 4,
                mainContainerWidth: 12,
                value: 'PoE Switch SM',
                options: [
                    {id: 'PoE Switch SM', label: 'PoE Switch SM'},
                    {id: 'PoE Switch Client', label: 'PoE Switch Client'},
                ],
            },
            {
                element: 'select',
                label: 'Cloud license',
                name: 'CloudLicense',
                required: true,
                width: 4,
                mainContainerWidth: 12,
                value: 'SM Scope (Cloud license)',
                options: [
                    {
                        id: 'Client scope (Cloud license)',
                        label: 'Client scope (Cloud license)',
                    },
                    {id: 'SM Scope (Cloud license)', label: 'SM Scope (Cloud license)'},
                ],
            },
            {
                element: 'select',
                label: 'CCTV Monitor Scope',
                name: 'cctvMonitorScope',
                required: true,
                width: 4,
                mainContainerWidth: 12,
                value: 'SM',
                options: [
                    {id: 'Client', label: 'Client'},
                    {id: 'SM', label: 'SM'},
                ],
            },
        ],
    },
    {
        groupName: 'BMS',
        fields: [
            {
                element: 'radio',
                label: 'Scope: BMS',
                name: 'scopeBms',
                width: 4,
                mainContainerWidth: 12,
                disableField: "bms_additoinal_info",
                disableOnValue: "NA",
                value: 'Yes',
                options: [
                    {id: 'Yes', label: 'Yes'},
                    {id: 'NA', label: 'NA'},
                ],
            },
            {
                element: 'radio',
                label: 'Scope: VMS',
                name: 'scopeVms',
                width: 4,
                mainContainerWidth: 12,
                value: 'YES',
                options: [
                    {id: 'YES', label: 'YES'},
                    {id: 'NA', label: 'NA'},
                ],
            },
            {
                element: 'select',
                label: 'BMS Additional Info',
                name: 'bms_additoinal_info',
                required: true,
                disableOn: true,
                width: 4,
                mainContainerWidth: 12,
                options: [
                    {id: 'NA', label: 'NA'},
                    {id: 'IOT Integration', label: 'IOT Integration'},
                    {id: 'EMS software', label: 'EMS software'},
                    {id: 'GSM Module', label: 'GSM Module'},
                    {id: 'IOT+EMS+GSM', label: 'IOT+EMS+GSM'},
                    {id: 'IOT+EMS', label: 'IOT+EMS'},
                ],
            },
        ],
    },
    {
        groupName: 'FA',
        fields: [
            {
                element: 'select',
                label: 'FA Type',
                name: 'faType',
                width: 4,
                mainContainerWidth: 12,
                value: 'HIGH FA',
                options: [
                    {id: 'ECO FA', label: 'ECO FA'},
                    {id: 'MID FA', label: 'MID FA'},
                    {id: 'HIGH FA', label: 'HIGH FA'},
                    {id: 'HIGH FAPA integrated', label: 'HIGH FAPA integrated'},
                    {id: 'Conventional FA', label: 'Conventional FA'}
                ],
            },
            {
                element: 'select',
                label: 'Repeater Panel',
                name: 'repeaterPanel',
                width: 4,
                mainContainerWidth: 12,
                value: 'Passive',
                options: [
                    {id: 'Passive', label: 'Passive'},
                    {id: 'Active', label: 'Active'}
                ],
            },
            {
                element: 'select',
                label: 'Fire Graphics requirement',
                name: 'fireGraphicsRequirement',
                width: 4,
                mainContainerWidth: 12,
                value: 'Fire Graphic',
                options: [
                    {id: 'Fire Graphic', label: 'Fire Graphic'},
                    {id: 'NA', label: 'NA'}
                ],
            },
        ],
    },
    {
        groupName: 'PA System',
        fields: [
            {
                element: 'select',
                label: 'PA System Dx Type',
                name: 'pa_system_dx_type',
                width: 4,
                mainContainerWidth: 12,
                value: 'HIGH',
                options: [
                    {id: 'ECO/MID', label: 'ECO/MID'},
                    {id: 'HIGH', label: 'HIGH'},
                    {id: 'NA', label: 'NA'}
                ],
            }
        ],
    },
    {
        groupName: 'WLD',
        fields: [
            {
                element: 'select',
                label: 'Type of WLD system',
                name: 'typeOfWldSystem',
                width: 4,
                mainContainerWidth: 12,
                value: 'Analogue',
                options: [
                    {id: 'Analogue', label: 'Analogue'},
                    {id: 'Addressable', label: 'Addressable'},
                    {id: 'NA', label: 'NA'}
                ],
            },
        ],
    },
    {
        groupName: 'Rodent Control system',
        fields: [
            {
                element: 'select',
                label: 'Scope of Rodent',
                name: 'scopeOfRodent',
                width: 4,
                mainContainerWidth: 12,
                value: 'All critical+cafe+shaft',
                options: [
                    {id: 'All Critical', label: 'All Critical'},
                    {id: 'All critical+cafe+shaft', label: 'All Critical+cafe+shaft'},
                    {id: 'NA', label: 'NA'}
                ],
            },
        ],
    },
    {
        groupName: 'Vesda',
        fields: [
            {
                element: 'select',
                label: 'Scope of Vesda (aspiration system)',
                name: 'scopeOfVesda',
                width: 4,
                mainContainerWidth: 12,
                disableField: "vesdaScopeValue",
                disableOnValue: "NA",
                value: 'Yes',
                options: [
                    {id: 'Yes', label: 'Yes'},
                    {id: 'NA', label: 'NA'}
                ],
            },
            {
                element: 'select',
                label: 'Vesda Scope',
                name: 'vesdaScopeValue',
                width: 4,
                mainContainerWidth: 12,
                disableOn: true,
                value: 'Server',
                options: [
                    {id: 'Server', label: 'Server'},
                    {id: 'Server/Hub', label: 'Server/Hub'},
                    {id: 'Server/Hub/Lab', label: 'Server/Hub/Lab'}
                ],
            },
        ],
    },
    {
        groupName: 'Flap Barrier /Turnstile',
        fields: [
            {
                element: 'select',
                label: 'Flap Barrier /Turnstile',
                name: 'flapBarrierTurnstileSelect',
                width: 4,
                mainContainerWidth: 12,
                disableField: ["normal_lane_dx_details", "no_of_normal_lane", "no_of_wider_lane"],
                disableOnValue: "NA",
                value: 'Turnstile',
                options: [
                    {id: 'Flap Barrier', label: 'Flap Barrier'},
                    {id: 'Turnstile', label: 'Turnstile'},
                    {id: 'NA', label: 'NA'}
                ],
            },
            {
                element: 'select',
                label: 'Type of Lane',
                name: 'normal_lane_dx_details',
                width: 4,
                mainContainerWidth: 12,
                disableOn: true,
                value: 'HIGH',
                options: [
                    {id: 'ECO', label: 'ECO'},
                    {id: 'MID', label: 'MID'},
                    {id: 'HIGH', label: 'HIGH'}
                ],
            },
            {
                element: 'input',
                label: 'No of normal lane (500/550mm)',
                name: 'no_of_normal_lane',
                required: true,
                type: 'number',
                width: 4,
                mainContainerWidth: 12,
                disableOn: true,
            },
            {
                element: 'input',
                label: 'No of wider lane (900mm)',
                name: 'no_of_wider_lane',
                required: true,
                type: 'number',
                width: 4,
                mainContainerWidth: 12,
                disableOn: true,
            },
        ],
    },
];

export default IbmsTab;

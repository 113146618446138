import ParameterFormTab from "./ParameterFormTab";

const LtCable = (props: any) => {
    const {formState, setFormState } = props;
    console.log(props);

    const handleNextButton = () => {
        //props.proceedToNextTab()
        props.updateToHeadCount();
    } 
    let buttons = [
        {
            type: 'submit',
            color: 'primary.main',
            variant: 'contained',
            title: 'Save',
            permissions: ['edit-projects', "edit-estimation"],
        },
    ];
    return (
        <ParameterFormTab project={props.project} formState={formState} setFormState={setFormState} formFields={formFields} categoryName={props.categoryName} allParameters={props.allParameters} buttons={buttons} handleNextButton={handleNextButton} updateFieldChange={props.updateFieldChange} buttonRef={props.buttonRef}/>
    )
}


const formFields = [
    {
        groupName: "LT Cable",
        fields: [
            {
                element: 'input',
                label: 'UPS Panel Each Cable Length in mtr',
                name: 'ups_cable_length_mtr',
                required: true,
                type: 'text',
                width: 4,
                mainContainerWidth: 12
            },
            {
                element: 'input',
                label: 'SPN DBs  Each Cable Length in mtr',
                name: 'spn_db_cable_length_mtr',
                required: true,
                type: 'text',
                width: 4,
                mainContainerWidth: 12
            },
            {
                element: 'select',
                label: 'SPN DBs  UPS Cable Size',
                name: 'spn_db_cable_size',
                required: true,
                width: 4,
                value: '3cx10',
                mainContainerWidth: 12,
                options: [
                    {id: '3cx10', label: '3cx10'}
                ],
            },
            {
                element: 'input',
                label: 'MELDB Cable Length in mtr',
                name: 'meldb_cable_length_mtr',
                required: true,
                type: 'text',
                width: 4,
                mainContainerWidth: 12
            },
            {
                element: 'select',
                label: 'MELDB Cable Size',
                name: 'meldb_cable_size',
                required: true,
                width: 4,
                value: '3cx6',
                mainContainerWidth: 12,
                options: [
                    {id: '3cx6', label: '3cx6'}
                ],
            },
            {
                element: 'input',
                label: 'HVAC Units Cable Length in mtr',
                name: 'hvac_cable_length_mtr',
                required: true,
                type: 'text',
                width: 4,
                mainContainerWidth: 12
            },
        ]
    },
];


export default LtCable


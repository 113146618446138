import {useEffect, useState} from "react";
import BuildForm from "../input-form/BuildForm";
import {Box} from "@mui/material";
import {submitForm} from "../../modules/project/plan/features/actions";
import {useAppDispatch} from "../../app/hooks";

const deepClone = (obj: any) => {
    return JSON.parse(JSON.stringify(obj));
};

const ParameterFormTab = (props: any) => {
    const dispatch = useAppDispatch();
    const [updatedFormFields, setUpdatedFormFields] = useState<any>([]);
    const {project, categoryName} = props;
    const [currentIndex, setCurrentIndex] = useState<any>(0);
    const [saveDisabled, setSaveDisabled] = useState(false);
    const [valueCondition, setValueCondition] = useState<any>({});

    const [buttons, setButtons] = useState<any[]>([
        {
            type: 'submit',
            color: 'primary.main',
            variant: 'contained',
            title: 'Save',
            permissions: ['edit-projects', "edit-estimation"],
        }
    ])


    const onSubmitHandler = async (form: any, callback: () => void) => {
        try {
            //console.log("Saving with", project);
            const response = await dispatch(submitForm({
                form: form,
                callback,
                categoryName,
                projectId: project.id,
                revisionId: project?.revision?.id
            })).unwrap();

            setSaveDisabled(true);
            props.updateFieldChange(false);

            props.allParameters[currentIndex] = response;

            props.handleNextButton();
        } catch (error) {
            console.error("Error submitting form:", error);
        }
    };

    const handleClickButton = (type: string) => {
        props.handleNextButton();
    }

    const enableSaveButton = () => {
        setSaveDisabled(false);
    }

    useEffect(() => {
        setUpdatedFormFields(deepClone(props.formFields));
    }, [props.formFields, props.categoryName]);

    useEffect(() => {
        let showContinueButton = false;
        //setUpdatedFormFields(deepClone(props.formFields));
        //let newFormFields = [...props.formFields];
        
        console.log("Project data", props.project);

        //let builtUpArea = props.project.projectDetails.builtUpArea;
        let headCount = props.project?.projectDetails?.totalHeadCount;
        let headCountFromClient = props.project?.projectDetails?.headCountFromClient;
        let carpetArea = props.project?.carpetArea ? props.project?.carpetArea : 0;
        let projectLocation = props.project?.location;
        let projectStudio = props.project?.projectStudio;
        
        if(headCountFromClient == "NO" || !headCount || headCount == 0) {
            headCount = carpetArea/100;
            console.log("headCount", headCount);
            console.log("carpetArea", carpetArea);
        }
        console.log(headCount + " "+ headCountFromClient + "  "+ carpetArea);
        let currentParamSaved = false;
        if (props.allParameters.length > 0) {
            let newFormFields = deepClone(props.formFields);
            let newValueCondition: { [k: string]: any } = {};


            console.log("Before Loop parameter values == ", props.allParameters);
            props.allParameters.forEach((parameter: any, index: number) => {
                if(parameter.categoryName === "c-and-i-common") {
                    Object.keys(parameter.data).forEach((key: string) => {
                        if(key === "type-of-dx-details-to-be-considered") {
                            newValueCondition[key] = parameter.data[key];
                        }
                        if(key === "toiletDetailsScope") {
                            newValueCondition[key] = parameter.data[key];
                        }
                    });
                }

                if(parameter.categoryName === "ibms") {
                    Object.keys(parameter.data).forEach((key: string) => {
                        if(key === "singleDoorAcs") {
                            newValueCondition[key] = parameter.data[key];
                        }
                        if(key === "doubleDoorsAcs") {
                            newValueCondition[key] = parameter.data[key];
                        }
                    });
                }

                if (parameter.categoryName === props.categoryName) {
                    setCurrentIndex(index);
                    //if(props.categoryName == 'ltCable') {
                    //    showContinueButton = true;
                    //}
                    
                    Object.keys(parameter.data).forEach((key: string) => {
                        newFormFields.forEach((formField: any) => {

                            formField.fields.forEach((field: any) => {
                                if (field.name === key) {
                                    if (field.name === "repeaterPanel" 
                                        && newValueCondition.hasOwnProperty("type-of-dx-details-to-be-considered") 
                                            && newValueCondition["type-of-dx-details-to-be-considered"] == "Design Strong Option") {
                                        field.value = "Active";
                                        currentParamSaved = true;
                                    } else if ((field.name === "ewc" || field.name === "wash-basin" || field.name === "urinal") 
                                            && newValueCondition.hasOwnProperty("toiletDetailsScope")) {     
                                        if(newValueCondition["toiletDetailsScope"] == "Toilet not in Scope") {
                                            field.value = "";
                                            field.readonly = true;
                                            field.disableOn = true;
                                            currentParamSaved = true;
                                        } else {
                                            if(parameter.data[key] != undefined && parameter.data[key] != "")  {
                                                console.log("Key = "+ parameter.data[key]);
                                                currentParamSaved = true;
                                                field.value = parameter.data[key];
                                                return;
                                            }

                                            let valCount = ((headCount * 60)/100)/25 + ((headCount * 40)/100)/15;

                                            if(headCountFromClient == "NO") {
                                                valCount = (((carpetArea/100)/25) + ((((carpetArea * 40)/100)/100)/15) * 60)/100;
                                            }

                                            if(field.name === "wash-basin") {
                                                valCount = ((headCount * 60)/100)/25 + ((headCount * 40)/100)/25;
                                                if(headCountFromClient == "NO") {
                                                    valCount = (((carpetArea/100)/25) + ((((carpetArea * 40)/100)/100)/25) * 60)/100;
                                                }
                                            }

                                            if(field.name === "urinal") {
                                                if(headCount <= 10) {
                                                    valCount = 1;
                                                } else if(headCount <= 45) {
                                                    valCount = 2;
                                                }  else if(headCount <= 70) {
                                                    valCount = 3;
                                                }  else if(headCount <= 100) {
                                                    valCount = 4;
                                                } else if(headCount <= 200) {
                                                    valCount = (headCount * 3)/100;
                                                } else if(headCount > 200) {
                                                    valCount = (headCount * 2.5)/100;
                                                }
                                            }

                                            field.value = Math.round(valCount);
                                            currentParamSaved = true;
                                        }                                        
                                    } else if (field.name === "landlordAhuCfm") {
                                        if(parameter.data[key] != undefined && parameter.data[key] != "")  {
                                            currentParamSaved = true;
                                            field.value = parameter.data[key];
                                            return;
                                        }
                                        let ahuCfmVal = 0;
                                        if(projectLocation.toLowerCase() == "west") {
                                            ahuCfmVal = carpetArea  * 1.7;
                                        } else  if(projectLocation.toLowerCase() == "north" || projectLocation.toLowerCase() == "south-chennai")  {
                                            ahuCfmVal = carpetArea  * 2;
                                        } else  if(projectLocation.toLowerCase() == "south-others")  {
                                            ahuCfmVal = carpetArea  * 1.6;
                                        }

                                        field.value = ahuCfmVal == 0 ? '' : ahuCfmVal;
                                        //field.readonly = true;
                                        //field.disableOn = true;
                                        currentParamSaved = true;
                                    } else if(field.name === "noOfAcsDoors") {
                                        let filedNames = field["sumOf"];
                                        let sumVal = 0;
                                        filedNames.map((fld:any) => {
                                            if(newValueCondition[fld] && newValueCondition[fld] != "") {
                                                sumVal += parseInt(newValueCondition[fld]);  
                                            } 
                                        });
                                        field.value = sumVal;
                                        field.readonly = true;
                                        field.disableOn = true;
                                        currentParamSaved = true;
                                    } else if ((field.name === "toilets-provided-by-the-landlord") 
                                            && newValueCondition.hasOwnProperty("toiletDetailsScope")) {     
                                        if(newValueCondition["toiletDetailsScope"] == "Toilet not in Scope") {
                                            field.value = "YES";
                                            currentParamSaved = true;
                                        } else {
                                            field.value = "NO";
                                        }
                                    } else {
                                        field.value = parameter.data[key];
                                    }

                                }
                            });
                        });
                    });
                    setSaveDisabled(true);
                    props.updateFieldChange(false);
                }
            });
            setValueCondition(newValueCondition);

            if(!currentParamSaved) {
                newFormFields.forEach((formField: any) => {
                    formField.fields.forEach((field: any) => {
                        if (field.name === "repeaterPanel" 
                            && newValueCondition.hasOwnProperty("type-of-dx-details-to-be-considered") 
                                && newValueCondition["type-of-dx-details-to-be-considered"] == "Design Strong Option") {
                            field.value = "Active";
                        } else if ((field.name === "ewc" || field.name === "wash-basin" || field.name === "urinal" || field.name === "urinal") 
                                && newValueCondition.hasOwnProperty("toiletDetailsScope")) {
                            if(newValueCondition["toiletDetailsScope"] == "Toilet not in Scope") {
                                field.value = "";
                                field.readonly = true;
                                field.disableOn = true;
                                currentParamSaved = true;

                            } else {
                                let valCount = ((headCount * 60)/100)/25 + ((headCount * 40)/100)/15;

                                if(headCountFromClient == "NO") {
                                    valCount = (((carpetArea/100)/25) + ((((carpetArea * 40)/100)/100)/15) * 60)/100;
                                }

                                if(field.name === "wash-basin") {
                                    valCount = ((headCount * 60)/100)/25 + ((headCount * 40)/100)/25;
                                    if(headCountFromClient == "NO") {
                                        valCount = (((carpetArea/100)/25) + ((((carpetArea * 40)/100)/100)/25) * 60)/100;
                                    }
                                }

                                if(field.name === "urinal") {
                                    if(headCount <= 10) {
                                        valCount = 1;
                                    } else if(headCount <= 45) {
                                        valCount = 2;
                                    }  else if(headCount <= 70) {
                                        valCount = 3;
                                    }  else if(headCount <= 100) {
                                        valCount = 4;
                                    } else if(headCount <= 200) {
                                        valCount = (headCount * 3)/100;
                                    } else if(headCount > 200) {
                                        valCount = (headCount * 2.5)/100;
                                    }
                                }

                                field.value = Math.round(valCount);
                                currentParamSaved = true;
                            }
                        } else if (field.name === "landlordAhuCfm") {
                            let ahuCfmVal = 0;
                            if(projectLocation.toLowerCase() == "west") {
                                ahuCfmVal = carpetArea  * 1.7;
                            } else  if(projectLocation.toLowerCase() == "north" || projectLocation.toLowerCase() == "south-chennai")  {
                                ahuCfmVal = carpetArea  * 2;
                            } else  if(projectLocation.toLowerCase() == "south-others")  {
                                ahuCfmVal = carpetArea  * 1.6;
                            }

                            field.value = ahuCfmVal == 0 ? '' : ahuCfmVal;
                            //field.readonly = true;
                            //field.disableOn = true;
                        } else if(field.name === "noOfAcsDoors") {
                            let filedNames = field["sumOf"];
                            let sumVal = 0;
                            filedNames.map((fld:any) => {
                                if(newValueCondition[fld] && newValueCondition[fld] != "") {
                                    sumVal += parseInt(newValueCondition[fld]);  
                                } 
                            });
                            field.value = sumVal;
                            field.readonly = true;
                            field.disableOn = true;
                        } else if ((field.name === "toilets-provided-by-the-landlord") 
                                            && newValueCondition.hasOwnProperty("toiletDetailsScope")) {     
                            if(newValueCondition["toiletDetailsScope"] == "Toilet not in Scope") {
                                field.value = "YES";
                                currentParamSaved = true;
                            } else {
                                field.value = "NO";
                            }
                            field.readonly = true;
                            field.disableOn = true;
                        } 
                    });
                });
            }

            console.log("newFormFields", newFormFields);
            setUpdatedFormFields(newFormFields);

            console.log("setUpdatedFormFields", updatedFormFields);
        }

        // Update the buttons state
        const newButtons: any = [
            {
                type: 'submit',
                disabledButton: true,
                color: 'primary.main',
                variant: 'contained',
                title: 'Save',
                permissions: ['edit-projects', "edit-estimation"],
            }
        ];

        if (showContinueButton) {
            newButtons.push({
                type: 'continue',
                disabledButton: false,
                color: 'common.main',
                variant: 'outlined',
                title: 'Submit & Continue',
                permissions: ['edit-projects', "edit-estimation"],
            });
        }

        setButtons(newButtons);
        
        props.updateFieldChange(false);


    }, [props.allParameters]);

    return (
        <Box id="parameter-container">
            {updatedFormFields &&
                <BuildForm
                    formArr={updatedFormFields}
                    buttons={buttons}
                    group={true}
                    onSubmit={onSubmitHandler}
                    onButtonClick={handleClickButton}
                    handleFormChange={(form: any) => enableSaveButton()}
                    saveDisabled={saveDisabled}
                    updateFieldChange={props.updateFieldChange}
                    buttonRef={props.buttonRef}
                    valueCondition={valueCondition}
                />
            }
        </Box>
    );
}

export default ParameterFormTab;

import ParameterFormTab from "./ParameterFormTab";

const MEPTab = (props: any) => {
    const {formState, setFormState } = props;
    const handleNextButton = () => {
        props.handleNext(props.categoryName)
    }
    let buttons = [
        {
            type: 'submit',
            color: 'primary.main',
            variant: 'contained',
            title: 'Save',
            permissions: ['edit-projects', "edit-estimation"],
        },
    ];
    return (
        <ParameterFormTab project={props.project} formState={formState} setFormState={setFormState} formFields={formFields} categoryName={props.categoryName} allParameters={props.allParameters} buttons={buttons} handleNextButton={handleNextButton} updateFieldChange={props.updateFieldChange} buttonRef={props.buttonRef}/>
    )
}


const formFields = [
    {
        groupName: "MEP - IT Node Count",
        fields: [
            {
                element: 'input',
                label: 'Length in mtr. (Avg.)- Copper.',
                name: 'lengthInMtrCopper',
                required: true,
                type: 'text',
                width: 4,
                mainContainerWidth: 12
            },
            {
                element: 'input',
                label: 'Length in mtr. (Avg.) - Fiber',
                name: 'lengthInMtrFiber',
                required: true,
                type: 'text',
                width: 4,
                mainContainerWidth: 12
            },
        ]
    },
    {
        groupName: "MEP - FF Calculation",
        fields: [
            /*{
                element: 'input',
                label: 'MEP - IT Node Count',
                name: 'mepInNodeCount',
                required: true,
                type: 'text',
                width: 4,
                mainContainerWidth: 12
            },*/
            {
                element: 'input',
                label: 'Cylinder Weight for HUB / IDF Room',
                name: 'cylinder_weight_hub_idf_room',
                required: true,
                type: 'text',
                width: 4,
                mainContainerWidth: 12
            },
        ]
    },
   /* {
        groupName: "MEP - Elec Load Analysis",
        fields: [
            {
                element: 'input',
                label: 'UPS Each Circuit Length mtr.',
                name: 'upsEachCircuit',
                required: true,
                type: 'text',
                width: 4,
                mainContainerWidth: 12
            },
            {
                element: 'input',
                label: 'RP Each Circuit Length mtr.',
                name: 'rpCircuitLengthMtr',
                required: true,
                type: 'text',
                width: 4,
                mainContainerWidth: 12
            },
            {
                element: 'input',
                label: 'Ltg Each Circuit Length mtr.',
                name: 'ltgEachCircuit',
                required: true,
                type: 'text',
                width: 4,
                mainContainerWidth: 12
            },
            {
                element: 'input',
                label: 'Emg. Ltg Each Circuit Length mtr.',
                name: 'emgLtgEachCircuit',
                required: true,
                type: 'text',
                width: 4,
                mainContainerWidth: 12
            },
        ]
    },*/
];


export default MEPTab


import PageHeading from "../../../../components/page-heading/PageHeading";
import BuildForm from "../../../../components/input-form/BuildForm";
import {Box} from "@mui/material";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {api} from "../../../../utils/Axios";
import Loader from "../../../../components/Loader";
import { useCheckboxValues } from "../../../../hooks/useCheckboxValues";

const CreateRole = () => {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const { resetSelectedValues } = useCheckboxValues()

    const onSubmitHandler = (form: any, callback: any) => {
        setIsLoading(true);
        api.post("/authentication/role/create", form).then((response) => {
            setIsLoading(false);
            navigate('/users/roles')
        }).catch(ex => {
            console.log(ex);
            setIsLoading(false);
        });
        callback();
    };

    const getAllPermissions = () => {
        setIsLoading(true);
        api.get("/authentication/role/list-permissions").then((response) => {
            return response.data;
        }).then((response: any) => {
            const options: any[] = [];
            response.forEach((item: any) => options.push({
                id: item.name,
                label: item.name
            }));
            formFields.map((field: any) => {
                if (field.name == 'permissions') {
                    field.options = options;
                }
            })
            setIsLoading(false);
        }).catch(ex => {
            console.log(ex);
            setIsLoading(false);
        });
    }

    useEffect(() => {
        getAllPermissions();
        resetSelectedValues()
    }, []);

    if (isLoading) {
        return <Loader/>
    }
    return (
        <Box className={'common-space-container'}>
            <PageHeading title={"Create Role"} enableBackButton={true}/>
            <Box>
                <Box className={"center-aligned"}>
                    <Box sx={{width: '50%'}}>
                        <BuildForm
                            buttons={[
                                {
                                    type: 'submit',
                                    title: 'Save',
                                    permissions: ['edit-users']
                                },
                            ]}
                            formArr={formFields}
                            onSubmit={onSubmitHandler}
                            handleFormChange={(form: any) => console.log(form)}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

const formFields = [
    {
        element: 'input',
        label: "Name",
        placeholder: 'Name',
        name: "name",
        type: "text",
        required: true
    },
    {
        element: 'input',
        label: "Slug",
        placeholder: 'Role Slug',
        name: "slug",
        type: "text",
        format: 'no-space'
    },
    {
        element: 'select',
        label: "Role For",
        placeholder: 'Role For',
        name: "entityName",
        options: [
            {id: 'vendor', label: 'Vendor'}
        ]
    },
    {
        element: 'checkbox',
        label: "Permissions",
        placeholder: 'Select Permissions',
        name: "permissions",
        fullWidth: true,
        options: []
    }
];

export default CreateRole;
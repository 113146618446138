import PageHeading from "../../../components/page-heading/PageHeading";
import BuildForm from "../../../components/input-form/BuildForm";
import {Box, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {api} from "../../../utils/Axios";
import {Link, useNavigate} from "react-router-dom";
import Loader from "../../../components/Loader";
import CloneProject from "../plan/tabs/project-details-tab/CloneProject";

const CreateProject = () => {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const [cloneProjectSelected, setCloneProjectSelected] = useState(false);
    const [cloneProjectId, setCloneProjectId] = useState("");
    const [cloneProjectName, setCloneProjectName] = useState("");
    const [openPopup, setOpenPopup] = useState(false);

    const studios: any = [
        {
            "name": "Bangalore",
            "country": "India",
            "currency": "INR"
        },
        {
            "name": "Chennai",
            "country": "India",
            "currency": "INR"
        },
        {
            "name": "Gurgaon",
            "country": "India",
            "currency": "INR"
        },
        {
            "name": "Hyderabad",
            "country": "India",
            "currency": "INR"
        },
        {
            "name": "Mumbai",
            "country": "India",
            "currency": "INR"
        },
        {
            "name": "Pune",
            "country": "India",
            "currency": "INR"
        }
    ]

    const handleCloneCreation = (data: any) => {
        if (data.event == 'select') {
            setCloneProjectSelected(true);
            setCloneProjectId(data.projectId);
            setCloneProjectName(data.projectName);
            // setCloneBoqBufferPrice(data.bufferPrice)
        }
        setOpenPopup(false);
    }

    const handleFormChange = (form: any) => {
        if (!cloneProjectSelected && form.estimationType == 'clone') {
            setOpenPopup(true);
        } else {
            setCloneProjectName("");
            setCloneProjectId("");
            setOpenPopup(false);
        }
    }

    const onSubmitHandler = (form: any, callback: any) => {
        if (!cloneProjectSelected && form.estimationType == 'clone') {
            setOpenPopup(true);
            return;
        } else {
            form.cloneProjectId = cloneProjectId;
        }

        studios.map((studio: any) => {
            if (form?.projectStudio == studio.name) {
                form.country = studio.country;
                form.currencyCode = studio.currency;
            }
        });

        setIsLoading(true);
        api.post("/procurement/project/create", form).then((response) => {
            return response.data;
        }).then(res => {
            setIsLoading(false);
            navigate(`/projects/plan/${res.id}`);
        }).catch(ex => {
            console.log(ex);
            setIsLoading(false);
        });
        callback();
    }

    useEffect(() => {
        formFields.map((field: any) => {
            if (field.name == 'projectStudio') {
                let values: any = [];
                studios.map((studio: any) => {
                    values.push({id: studio.name, label: studio.name});
                });
                field.options = values;
            }
        });
    }, []);

    if (isLoading) {
        return <Loader/>
    }
    return (
        <Box className={"common-space-container"}>
            <PageHeading
                title={"Create New Project"}
                showButtons={false}
            />
            <Box>
                <Box className={"center-aligned"}>
                    <Box sx={{width: '30%'}}>
                        <BuildForm
                            buttons={[
                                {
                                    type: 'submit',
                                    title: 'Create Project',
                                    permissions: ['edit-projects', "edit-estimation"]
                                },
                            ]}
                            formArr={formFields}
                            onSubmit={onSubmitHandler}
                            handleFormChange={handleFormChange}
                        />
                        {cloneProjectName != "" &&
                            <Typography sx={{pt: 2}}>Selected Project For Clone is <Link target={"_blank"}
                                                                                         to={`/projects/plan/${cloneProjectId}`}>{cloneProjectName}</Link> &nbsp;
                            </Typography>
                        }
                    </Box>
                </Box>
            </Box>
            {openPopup && (
                <CloneProject
                    openPopup={openPopup}
                    handleCloneCreation={handleCloneCreation}
                />
            )}
        </Box>
    )
}


const formFields: any[] = [
    {
        element: 'input',
        label: "Project Name",
        placeholder: 'Project Name',
        name: "projectName",
        type: "text",
        required: true,
        fullWidth: true
    },
    {
        element: 'input',
        label: "Carpet area (sq ft)",
        placeholder: 'Carpet area (sq ft)',
        name: "carpetArea",
        required: true,
        type: "number"
    },
    /*{
        element: 'input',
        label: "Area under Terrace area",
        placeholder: 'Area under Terrace area',
        name: "areaUnderTerrace",
        required: true,
        type: "number",
    },*/
    {
        element: 'select',
        label: "Project Studio",
        placeholder: 'Project Studio',
        name: "projectStudio",
        required: true,
        // fullWidth: true,
        value: "",
        options: []
    },
    {
        element: 'select',
        label: "Project Location",
        placeholder: 'Project Location',
        name: "projectLocation",
        required: true,
        // fullWidth: true,
        value: "",
        options: [
            {id: 'north', label: 'North'},
            {id: 'south', label: 'South'},
            //{id: 'east', label: 'East'},
            {id: 'west', label: 'West'},
            {id: 'South-Chennai', label: 'South Chennai'},
            {id: 'Non Office location', label: 'Non Office Location'},
            {id: 'South-Others', label: 'South Others'},
        ]
    },
    {
        element: 'select',
        label: "Method",
        name: "estimationType",
        required: true,
        // mainContainerWidth: 6,
        // width: 8,
        options: [
            {id: 'scratch', label: 'Create From Scratch'},
            {id: 'engine', label: 'Generate Estimate'},
            {id: 'clone', label: 'Clone Project'},
        ]
    }
];


export default CreateProject;
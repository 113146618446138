import dayjs, {Dayjs} from "dayjs";
import * as React from "react";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {DemoContainer} from "@mui/x-date-pickers/internals/demo";
import {DateTimePicker} from "@mui/x-date-pickers";

interface ResponsiveDateTimePickersProps {
    label: string;
    value: Dayjs | null;
    onChange: (newValue: Dayjs | null) => void;
}
const locales = ['en-us', 'en-gb', 'zh-cn', 'de', "en-in"];
type LocaleKey = (typeof locales)[number];



const CustomDateTimePicker = (props: any) => {
    const [locale, setLocale] = React.useState<LocaleKey>('en-in');
    const minDateTime= props.hasOwnProperty("minDate") ? dayjs(props.minDate) : dayjs().subtract(90, 'day');;
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
            <DemoContainer components={['DateTimePicker']}>
                <DateTimePicker
                    label={props.label}
                    value={props.value}
                    minDateTime={minDateTime}
                    onChange={props.onChange}
                    slotProps={{ textField: { fullWidth: true } }}
                />
            </DemoContainer>
        </LocalizationProvider>
    )
}
export default CustomDateTimePicker;